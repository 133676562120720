// @flow

/* eslint-disable */
/* prettier-ignore */
// $FlowIgnore[unclear-type]
export type Json = any;
export type DateTimeLocal = {
  year: number,
  month: number,
  date: number,
  hours: number,
  minutes: number,
  seconds: number,
};
export type TemplatePrediction = { [key: string]: string[] | TemplatePrediction };

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {|
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  ContentSequence: Json,
  Date: Date,
  DateTime: Date,
  /**
   * Represents a local date time value in an unknown timezone.
   *
   * Useful when representing date time values that we don't have a timezone for (e.g. SeriesDate and SeriesTime).
   * In these cases we don't want to use a Date which may give the impression the value is in the user's local timezone.
   *
   * Instead we use this structure which the client can format as necessary.
   *
   * The values match the ones used in JS Date instantiation.
   */
  DateTimeLocal: DateTimeLocal,
  FrameScalar: FrameDataFragment,
  JSON: Json,
  JSONObject: Json,
  Matrix3x3: [number, number, number, number, number, number, number, number, number],
  ModalityLutDescriptor: [number, number, number],
  StringOrBooleanOrDate: string | boolean | Date,
  TemplatePrediction: TemplatePrediction,
  TransformMatrix: [
    [number, number, number, number],
    [number, number, number, number],
    [number, number, number, number],
    [number, number, number, number],
  ],
  Vector2: [number, number],
  Vector3: [number, number, number],
  Vector4: [number, number, number, number],
  Vector6: [number, number, number, number, number, number],
  VoiLutDescriptor: [number, number, number],
|};

export type AiModeInput = {|
  findingsMapperEnabled: $ElementType<Scalars, 'Boolean'>,
  formatImpressionGenerationAsList: $ElementType<Scalars, 'Boolean'>,
  onDemandImpressionGeneration: $ElementType<Scalars, 'Boolean'>,
|};

export type AiModeSettings = {|
  +__typename?: 'AIModeSettings',
  +findingsMapperEnabled: $ElementType<Scalars, 'Boolean'>,
  +formatImpressionGenerationAsList: $ElementType<Scalars, 'Boolean'>,
  +onDemandImpressionGeneration: $ElementType<Scalars, 'Boolean'>,
|};

export type AbsoluteScrollingResponse = {|
  +__typename?: 'AbsoluteScrollingResponse',
  +absoluteScroll: $ElementType<Scalars, 'Boolean'>,
|};

export type AcceptAiAnnotation = {|
  annotations: $ReadOnlyArray<UserAnnotation>,
  id: $ElementType<Scalars, 'String'>,
|};

export type AcceptAiAnnotationResponse = {|
  +__typename?: 'AcceptAiAnnotationResponse',
  +annotations: $ReadOnlyArray<AnnotationLogResponse>,
  +logIds: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type Addendum = {|
  +__typename?: 'Addendum',
  +content: AddendumContent,
  +created: $ElementType<Scalars, 'DateTime'>,
  +owner?: ?Owner,
  +sentAt?: ?$ElementType<Scalars, 'DateTime'>,
  +smid: $ElementType<Scalars, 'ID'>,
  +submittedAt?: ?$ElementType<Scalars, 'DateTime'>,
  +updated: $ElementType<Scalars, 'DateTime'>,
  +workstationID?: ?$ElementType<Scalars, 'String'>,
|};

export type AddendumContent = AddendumSironaSlate | AddendumTextBlob;

export type AddendumPayload = {|
  content: $ReadOnlyArray<$ElementType<Scalars, 'JSON'>>,
  workstationID?: ?$ElementType<Scalars, 'String'>,
|};

export type AddendumSironaSlate = {|
  +__typename?: 'AddendumSironaSlate',
  +sironaSlate: $ReadOnlyArray<$ElementType<Scalars, 'JSON'>>,
|};

export type AddendumTextBlob = {|
  +__typename?: 'AddendumTextBlob',
  +textBlob: $ElementType<Scalars, 'String'>,
|};

export type AiAnnotation = {|
  +__typename?: 'AiAnnotation',
  +annotations: $ReadOnlyArray<$ElementType<Scalars, 'JSON'>>,
  +data?: ?$ElementType<Scalars, 'JSON'>,
  +frameOfReferenceUID?: ?$ElementType<Scalars, 'String'>,
  +id: $ElementType<Scalars, 'ID'>,
  +modelName: $ElementType<Scalars, 'String'>,
  +seriesSmid: $ElementType<Scalars, 'ID'>,
  +status: AiAnnotationGroupStatus,
  +type: AiAnnotationTypes,
|};

export const AiAnnotationGroupStatusValues = Object.freeze({
  Accepted: 'ACCEPTED',
  Pending: 'PENDING',
  Rejected: 'REJECTED',
});

export type AiAnnotationGroupStatus = $Values<typeof AiAnnotationGroupStatusValues>;

export const AiAnnotationTypesValues = Object.freeze({
  SegmentationMask: 'SEGMENTATION_MASK',
  SegMaskLabel: 'SEG_MASK_LABEL',
  VertebraeLabel: 'VERTEBRAE_LABEL',
});

export type AiAnnotationTypes = $Values<typeof AiAnnotationTypesValues>;

export type AnatomicNavigatorResponse = {|
  +__typename?: 'AnatomicNavigatorResponse',
  +anatomicNavigatorEnabled: $ElementType<Scalars, 'Boolean'>,
|};

export type Annotation = {|
  +aiAnnotationSmid?: ?$ElementType<Scalars, 'ID'>,
  +customLogic?: ?CustomAnnotationLogic,
  +data: $ElementType<Scalars, 'JSON'>,
  +pixel_space_representation?: ?$ElementType<Scalars, 'JSON'>,
  +seriesSmid: $ElementType<Scalars, 'ID'>,
  +smid: $ElementType<Scalars, 'ID'>,
  +source: AnnotationSource,
  +type: AnnotationTypes,
|};

export type AnnotationEditableOptions = {|
  +__typename?: 'AnnotationEditableOptions',
  +move?: ?$ElementType<Scalars, 'Boolean'>,
  +transform?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type AnnotationInstance = {|
  groupId?: ?$ElementType<Scalars, 'ID'>,
  id: $ElementType<Scalars, 'ID'>,
  label?: ?$ElementType<Scalars, 'String'>,
  segments: $ReadOnlyArray<IAnnotationSegment>,
  type: AnnotationTypes,
|};

export type AnnotationLog = {|
  aiAnnotationSmid: $ElementType<Scalars, 'ID'>,
  smid: $ElementType<Scalars, 'String'>,
|};

export type AnnotationLogResponse = {|
  +__typename?: 'AnnotationLogResponse',
  +annotationSmid: $ElementType<Scalars, 'String'>,
|};

export type AnnotationSegment = {|
  +__typename?: 'AnnotationSegment',
  +end: ExtendedCoordinates,
  +start: ExtendedCoordinates,
|};

export const AnnotationSourceValues = Object.freeze({
  Gsps: 'GSPS',
  Ugc: 'UGC',
});

export type AnnotationSource = $Values<typeof AnnotationSourceValues>;

export type AnnotationStyle = {|
  +__typename?: 'AnnotationStyle',
  +color?: ?$ElementType<Scalars, 'String'>,
  +opacity?: ?$ElementType<Scalars, 'Float'>,
|};

export const AnnotationTypesValues = Object.freeze({
  Angle: 'ANGLE',
  Arrow: 'ARROW',
  Circle: 'CIRCLE',
  CobbAngle: 'COBB_ANGLE',
  Contour: 'CONTOUR',
  Custom: 'CUSTOM',
  Ellipse: 'ELLIPSE',
  Landmark: 'LANDMARK',
  Length: 'LENGTH',
  Rect: 'RECT',
  SegmentationMask: 'SEGMENTATION_MASK',
  SegMaskLabel: 'SEG_MASK_LABEL',
  Text: 'TEXT',
  VertebraeLabel: 'VERTEBRAE_LABEL',
});

export type AnnotationTypes = $Values<typeof AnnotationTypesValues>;

export type AssignDefaultAutoloadTemplateInput = {|
  id?: ?$ElementType<Scalars, 'ID'>,
|};

export type AssignDefaultAutoloadTemplatePayload = {|
  +__typename?: 'AssignDefaultAutoloadTemplatePayload',
  +me: User,
|};

export const AuthRoleValues = Object.freeze({
  Unknown: 'UNKNOWN',
  User: 'USER',
});

export type AuthRole = $Values<typeof AuthRoleValues>;

export type AuthenticateResponse = MfaRequired | User;

export type AutoCorrectEntry = {|
  +__typename?: 'AutoCorrectEntry',
  +id: $ElementType<Scalars, 'ID'>,
  +key: $ElementType<Scalars, 'String'>,
  +value: $ElementType<Scalars, 'String'>,
|};

export type AutoFillComparison = {|
  +__typename?: 'AutoFillComparison',
  +enabled: $ElementType<Scalars, 'Boolean'>,
|};

export type AutoFillComparisonInput = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
|};

export type BarPreferences = {|
  +__typename?: 'BarPreferences',
  +toolIds: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type BaseStack = {|
  +smid: $ElementType<Scalars, 'ID'>,
  +study: Study,
  +type: $ElementType<Scalars, 'String'>,
|};

export type BodyPart = {|
  +__typename?: 'BodyPart',
  +name: $ElementType<Scalars, 'String'>,
|};

export type CineModule = {|
  +__typename?: 'CineModule',
  +frameTimeVector: $ReadOnlyArray<$ElementType<Scalars, 'Int'>>,
  +preferredPlaybackSequencing: $ElementType<Scalars, 'Int'>,
|};

export type ClassificationItem = {|
  +__typename?: 'ClassificationItem',
  +category: $ElementType<Scalars, 'String'>,
  +classifications: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  +codeVersion?: ?$ElementType<Scalars, 'String'>,
  +createdAt: $ElementType<Scalars, 'Date'>,
  +modelName?: ?$ElementType<Scalars, 'String'>,
  +modelVersion?: ?$ElementType<Scalars, 'String'>,
  +smid: $ElementType<Scalars, 'ID'>,
|};

export type Classifications = {|
  +__typename?: 'Classifications',
  +cursor?: ?$ElementType<Scalars, 'ID'>,
  +items: $ReadOnlyArray<ClassificationItem>,
  +next?: ?$ElementType<Scalars, 'ID'>,
  +previous?: ?$ElementType<Scalars, 'ID'>,
  +totalCount: $ElementType<Scalars, 'Int'>,
|};

export type Clinic = {|
  +__typename?: 'Clinic',
  +name: $ElementType<Scalars, 'String'>,
  +smid: $ElementType<Scalars, 'ID'>,
  +worklistLockDuration: $ElementType<Scalars, 'Int'>,
|};

export const CompletionFlagValues = Object.freeze({
  Complete: 'COMPLETE',
  Partial: 'PARTIAL',
});

export type CompletionFlag = $Values<typeof CompletionFlagValues>;

export type ConfirmDeleteWorkspacePreset = {|
  +__typename?: 'ConfirmDeleteWorkspacePreset',
  +smid: $ElementType<Scalars, 'ID'>,
|};

export type ConfirmResponse = {|
  +__typename?: 'ConfirmResponse',
  +confirmed: $ElementType<Scalars, 'Boolean'>,
|};

export type Counter = {|
  +__typename?: 'Counter',
  +count: $ElementType<Scalars, 'Int'>,
  +countStr?: ?$ElementType<Scalars, 'String'>,
|};

export type CreateAutoCorrectEntryInput = {|
  key: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
|};

export type CreateAutoCorrectEntryResponse = {|
  +__typename?: 'CreateAutoCorrectEntryResponse',
  +entry?: ?AutoCorrectEntry,
|};

export type CreateVocabEntryInput = {|
  phrase: $ElementType<Scalars, 'String'>,
|};

export type CreateVocabEntryResponse = {|
  +__typename?: 'CreateVocabEntryResponse',
  +entry?: ?VocabEntry,
|};

export type CursorStyle = {|
  +__typename?: 'CursorStyle',
  +color: $ElementType<Scalars, 'String'>,
|};

export type CursorStyleInput = {|
  color: $ElementType<Scalars, 'String'>,
|};

export type CustomAnnotationLogic = {|
  +__typename?: 'CustomAnnotationLogic',
  +id: $ElementType<Scalars, 'ID'>,
  +logic: $ElementType<Scalars, 'String'>,
  +toolId: $ElementType<Scalars, 'ID'>,
|};

export type CustomDicomTagsResponse = {|
  +__typename?: 'CustomDicomTagsResponse',
  +modality: $ElementType<Scalars, 'String'>,
  +modalityDicomTags: $ReadOnlyArray<DicomTagRenderInfo>,
|};

export type CustomMergeField = {|
  +__typename?: 'CustomMergeField',
  +key: $ElementType<Scalars, 'String'>,
  +value: $ElementType<Scalars, 'String'>,
|};

export type CustomWorklist = {|
  +__typename?: 'CustomWorklist',
  +filters: $ReadOnlyArray<WorklistFilter>,
  +name: $ElementType<Scalars, 'String'>,
  +smid: $ElementType<Scalars, 'ID'>,
|};

export type CustomWorklistInput = {|
  filters: $ReadOnlyArray<WorklistFilterInput>,
  name: $ElementType<Scalars, 'String'>,
  smid: $ElementType<Scalars, 'ID'>,
|};

export type DeleteAnnotationsItem = {|
  +__typename?: 'DeleteAnnotationsItem',
  +smid: $ElementType<Scalars, 'ID'>,
  +status: DeleteAnnotationsStatus,
|};

export type DeleteAnnotationsResponse = {|
  +__typename?: 'DeleteAnnotationsResponse',
  +results: $ReadOnlyArray<DeleteAnnotationsItem>,
|};

export const DeleteAnnotationsStatusValues = Object.freeze({
  Fulfilled: 'fulfilled',
  Rejected: 'rejected',
});

export type DeleteAnnotationsStatus = $Values<typeof DeleteAnnotationsStatusValues>;

export type DeleteAutoCorrectEntryInput = {|
  key: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
|};

export type DeleteAutoCorrectEntryResponse = {|
  +__typename?: 'DeleteAutoCorrectEntryResponse',
  +id: $ElementType<Scalars, 'ID'>,
|};

export type DeleteMacroResponse = {|
  +__typename?: 'DeleteMacroResponse',
  +smid: $ElementType<Scalars, 'ID'>,
|};

export type DeleteTemplateResponse = {|
  +__typename?: 'DeleteTemplateResponse',
  +smid: $ElementType<Scalars, 'ID'>,
|};

export type DeleteVocabEntryInput = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type DeleteVocabEntryResponse = {|
  +__typename?: 'DeleteVocabEntryResponse',
  +id: $ElementType<Scalars, 'ID'>,
|};

export type DicomInstance = {|
  +__typename?: 'DicomInstance',
  +dicomTags: $ReadOnlyArray<DicomTag>,
  +smid: $ElementType<Scalars, 'ID'>,
|};

export type DicomInstanceDicomTagsArgs = {|
  ids?: ?$ReadOnlyArray<$ElementType<Scalars, 'String'>>,
|};

export type DicomTag = {|
  +__typename?: 'DicomTag',
  +id: $ElementType<Scalars, 'String'>,
  +name: $ElementType<Scalars, 'String'>,
  +values: $ReadOnlyArray<DicomTagValue>,
  +vr: $ElementType<Scalars, 'String'>,
|};

export type DicomTagRenderInfo = {|
  +__typename?: 'DicomTagRenderInfo',
  +enabled: $ElementType<Scalars, 'Boolean'>,
  +id: $ElementType<Scalars, 'ID'>,
  +label: $ElementType<Scalars, 'String'>,
  +tagId: $ElementType<Scalars, 'String'>,
|};

export type DicomTagRenderInfoInput = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
  id: $ElementType<Scalars, 'ID'>,
  label: $ElementType<Scalars, 'String'>,
  tagId: $ElementType<Scalars, 'String'>,
|};

export type DicomTagValue = {|
  +__typename?: 'DicomTagValue',
  +key?: ?$ElementType<Scalars, 'String'>,
  +value?: ?$ElementType<Scalars, 'JSON'>,
|};

export type DictaphoneKey = {|
  +__typename?: 'DictaphoneKey',
  +id: GamepadActionId,
  +key: $ElementType<Scalars, 'String'>,
|};

export type DictaphonePreferences = {|
  +__typename?: 'DictaphonePreferences',
  +deviceLabel: $ElementType<Scalars, 'String'>,
  +keyMapping: $ReadOnlyArray<DictaphoneKey>,
  +recordWhilePressed: $ElementType<Scalars, 'Boolean'>,
  +showPicklistOptionsInEditor: $ElementType<Scalars, 'Boolean'>,
|};

export type DisplayConfiguration = {|
  isHorizontallyFlipped: $ElementType<Scalars, 'Boolean'>,
  isInverted: $ElementType<Scalars, 'Boolean'>,
  isVerticallyFlipped: $ElementType<Scalars, 'Boolean'>,
  params2d?: ?DisplayConfigurationParams2d,
|};

export type DisplayConfigurationParams2d = {|
  focalPoint?: ?$ElementType<Scalars, 'Vector3'>,
  imageNumber?: ?$ElementType<Scalars, 'Int'>,
  level?: ?$ElementType<Scalars, 'Float'>,
  offsetX?: ?$ElementType<Scalars, 'Float'>,
  offsetY?: ?$ElementType<Scalars, 'Float'>,
  position?: ?$ElementType<Scalars, 'Vector3'>,
  rotate?: ?$ElementType<Scalars, 'Float'>,
  window?: ?$ElementType<Scalars, 'Float'>,
  zoom?: ?$ElementType<Scalars, 'Float'>,
|};

export const EraseAllToolScopeValues = Object.freeze({
  Study: 'study',
  Viewport: 'viewport',
});

export type EraseAllToolScope = $Values<typeof EraseAllToolScopeValues>;

export type EraseAllToolScopeResponse = {|
  +__typename?: 'EraseAllToolScopeResponse',
  +eraseAllToolScope?: ?EraseAllToolScope,
|};

export type ExtendedCoordinates = IndexCoordinates | WorldCoordinates;

export type FastScrollPreferences = {|
  +__typename?: 'FastScrollPreferences',
  +sensitivity: $ElementType<Scalars, 'Float'>,
|};

export type FineScrollPreferences = {|
  +__typename?: 'FineScrollPreferences',
  +enabled: $ElementType<Scalars, 'Boolean'>,
|};

export type Frame = {|
  +__typename?: 'Frame',
  +_groupType: $ElementType<Scalars, 'String'>,
  +colorChannels: $ElementType<Scalars, 'Int'>,
  +dicomInstance: DicomInstance,
  +direction: FrameDirection,
  +hasPixels: $ElementType<Scalars, 'Boolean'>,
  +imageNumber: $ElementType<Scalars, 'Int'>,
  +modules: Modules,
  +needsInversion: $ElementType<Scalars, 'Boolean'>,
  +origin: $ElementType<Scalars, 'Vector3'>,
  +series: Series,
  +size: $ReadOnlyArray<$ElementType<Scalars, 'Float'>>,
  +smid: $ElementType<Scalars, 'ID'>,
  +sopClassUID: $ElementType<Scalars, 'String'>,
  +sopInstanceUID: $ElementType<Scalars, 'String'>,
  +spacing: $ElementType<Scalars, 'Vector3'>,
  +supportedRenderers: SupportedRenderers,
  +validSpacing: $ElementType<Scalars, 'Boolean'>,
|};

export type FrameDicomInstanceArgs = {|
  dicomTagIds?: ?$ReadOnlyArray<$ElementType<Scalars, 'String'>>,
|};

export type FrameDirection = {|
  +__typename?: 'FrameDirection',
  +columns: $ElementType<Scalars, 'Int'>,
  +data: $ElementType<Scalars, 'Matrix3x3'>,
  +rows: $ElementType<Scalars, 'Int'>,
  +validDirection: $ElementType<Scalars, 'Boolean'>,
|};

export const FrameGroupByTypeValues = Object.freeze({
  Auto: 'AUTO',
  Disabled: 'DISABLED',
  Enabled: 'ENABLED',
});

export type FrameGroupByType = $Values<typeof FrameGroupByTypeValues>;

export type FrameStack = LayeredStack | SingleLayerStack;

export type FrameToLoad = {|
  frameSmid: $ElementType<Scalars, 'ID'>,
  seriesSmid: $ElementType<Scalars, 'ID'>,
|};

export const GamepadActionIdValues = Object.freeze({
  Back: 'back',
  Forward: 'forward',
  Record: 'record',
  SubmitReport: 'submitReport',
});

export type GamepadActionId = $Values<typeof GamepadActionIdValues>;

export type GenerateImpressionInput = {|
  caseSmid: $ElementType<Scalars, 'ID'>,
  reportSections: $ElementType<Scalars, 'JSON'>,
|};

export type GenerateImpressionResponse = {|
  +__typename?: 'GenerateImpressionResponse',
  +impression: $ElementType<Scalars, 'JSON'>,
|};

export type GspsAnnotation = {|
  ...Annotation,
  ...{|
    +__typename?: 'GspsAnnotation',
    +aiAnnotationSmid?: ?$ElementType<Scalars, 'ID'>,
    +created?: ?$ElementType<Scalars, 'DateTimeLocal'>,
    +customLogic?: ?CustomAnnotationLogic,
    +data: $ElementType<Scalars, 'JSON'>,
    +owner: GspsOwner,
    +pixel_space_representation?: ?$ElementType<Scalars, 'JSON'>,
    +presentationState: GspsPresentationState,
    +seriesInstanceUID: $ElementType<Scalars, 'String'>,
    +seriesSmid: $ElementType<Scalars, 'ID'>,
    +smid: $ElementType<Scalars, 'ID'>,
    +source: AnnotationSource,
    +study: Study,
    +studyInstanceUID: $ElementType<Scalars, 'String'>,
    +type: AnnotationTypes,
  |},
|};

export type GspsOwner = {|
  +__typename?: 'GspsOwner',
  +firstName?: ?$ElementType<Scalars, 'String'>,
  +lastName?: ?$ElementType<Scalars, 'String'>,
|};

export type GspsPresentationState = {|
  +__typename?: 'GspsPresentationState',
  +annotations: $ReadOnlyArray<GspsAnnotation>,
  +created?: ?$ElementType<Scalars, 'DateTimeLocal'>,
  +name?: ?$ElementType<Scalars, 'String'>,
  +owner?: ?GspsOwner,
  +smid: $ElementType<Scalars, 'ID'>,
  +study: Study,
|};

export type GspsPresentationStateAnnotationsArgs = {|
  query?: ?$ElementType<Scalars, 'JSON'>,
|};

export type HangingProtocol = {|
  +__typename?: 'HangingProtocol',
  +clinic: Clinic,
  +created: $ElementType<Scalars, 'DateTime'>,
  +data: HangingProtocolData,
  +name: $ElementType<Scalars, 'String'>,
  +numberOfPriors: $ElementType<Scalars, 'Int'>,
  +owner: User,
  +smid: $ElementType<Scalars, 'ID'>,
  +studyDescriptions: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  +updated: $ElementType<Scalars, 'DateTime'>,
  +viewportsConfigurations?: ?$ReadOnlyArray<ViewportConfiguration>,
|};

export type HangingProtocolData = {|
  +__typename?: 'HangingProtocolData',
  +layouts: $ReadOnlyArray<HangingProtocolLayout>,
  +viewportsConfigurations: $ElementType<Scalars, 'JSON'>,
|};

export type HangingProtocolDeleted = {|
  +__typename?: 'HangingProtocolDeleted',
  +smid: $ElementType<Scalars, 'ID'>,
|};

export type HangingProtocolLayout = {|
  +__typename?: 'HangingProtocolLayout',
  +id: $ElementType<Scalars, 'ID'>,
  +layout: $ReadOnlyArray<$ElementType<Scalars, 'Int'>>,
|};

export type HangingProtocolPayload = {|
  data: IHangingProtocolData,
  name: $ElementType<Scalars, 'String'>,
  numberOfPriors: $ElementType<Scalars, 'Int'>,
  studyDescriptions: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
|};

export const HangingProtocolSortByValues = Object.freeze({
  NumberOfPriors: 'NUMBER_OF_PRIORS',
});

export type HangingProtocolSortBy = $Values<typeof HangingProtocolSortByValues>;

export type HangingProtocols = {|
  +__typename?: 'HangingProtocols',
  +items: $ReadOnlyArray<HangingProtocol>,
  +next?: ?$ElementType<Scalars, 'ID'>,
  +previous?: ?$ElementType<Scalars, 'ID'>,
  +totalCount: $ElementType<Scalars, 'Int'>,
|};

export type HeadingKeywords = {|
  +__typename?: 'HeadingKeywords',
  +comparison: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  +exam: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  +findings: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  +history: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  +impressions: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  +technique: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
|};

export const HeadingLevelValues = Object.freeze({
  H1: 'H1',
  H2: 'H2',
});

export type HeadingLevel = $Values<typeof HeadingLevelValues>;

export type HeadingStyle = {|
  +__typename?: 'HeadingStyle',
  +level: HeadingLevel,
  +textStyle: TextStyle,
|};

export type HeadingStyleInput = {|
  level: HeadingLevel,
  textStyle: TextStyleInput,
|};

export type IAddendum = {|
  content: $ReadOnlyArray<$ElementType<Scalars, 'JSON'>>,
  created?: ?$ElementType<Scalars, 'DateTime'>,
  owner?: ?$ElementType<Scalars, 'JSON'>,
  smid: $ElementType<Scalars, 'ID'>,
  submittedAt?: ?$ElementType<Scalars, 'DateTime'>,
|};

export type IAnnotationSegment = {|
  end: IExtendedCoordinates,
  start: IExtendedCoordinates,
|};

export type IDictaphoneKey = {|
  id: GamepadActionId,
  key: $ElementType<Scalars, 'String'>,
|};

export type IExtendedCoordinates = {|
  indexSpace?: ?$ElementType<Scalars, 'Vector3'>,
  worldSpace?: ?$ElementType<Scalars, 'Vector3'>,
|};

export type IHangingProtocolData = {|
  layouts: $ReadOnlyArray<IHangingProtocolLayout>,
  viewportsConfigurations: $ReadOnlyArray<IViewportConfiguration>,
|};

export type IHangingProtocolLayout = {|
  id: $ElementType<Scalars, 'ID'>,
  layout: $ReadOnlyArray<$ElementType<Scalars, 'Int'>>,
|};

export type IProcedureReference = {|
  autoload: $ElementType<Scalars, 'Boolean'>,
  smid: $ElementType<Scalars, 'ID'>,
|};

export type IReportPicklist = {|
  id: $ElementType<Scalars, 'ID'>,
  options: $ReadOnlyArray<IReportPicklistOption>,
|};

export type IReportPicklistOption = {|
  default: $ElementType<Scalars, 'Boolean'>,
  id: $ElementType<Scalars, 'ID'>,
  name?: ?$ElementType<Scalars, 'String'>,
  text: $ReadOnlyArray<$ElementType<Scalars, 'JSON'>>,
|};

export type IReportTemplatePayload = {|
  content?: ?$ElementType<Scalars, 'JSON'>,
  name?: ?$ElementType<Scalars, 'String'>,
  picklists?: ?$ReadOnlyArray<IReportPicklist>,
  procedureReferences?: ?$ReadOnlyArray<IProcedureReference>,
  sections?: ?$ReadOnlyArray<$ElementType<Scalars, 'JSON'>>,
  triggerPhrase?: ?$ElementType<Scalars, 'String'>,
|};

export type IViewportConfiguration = {|
  comparisonIndex?: ?$ElementType<Scalars, 'Int'>,
  groupType?: ?$ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
  seriesDescriptions?: ?$ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  seriesId?: ?$ElementType<Scalars, 'ID'>,
  viewType?: ?ViewType,
|};

export type ImageParams = {|
  +__typename?: 'ImageParams',
  +colorLevel?: ?$ElementType<Scalars, 'Float'>,
  +colorWindow?: ?$ElementType<Scalars, 'Float'>,
  +dimensions: $ElementType<Scalars, 'Vector3'>,
  +direction: $ElementType<Scalars, 'Matrix3x3'>,
  +extent: $ElementType<Scalars, 'Vector6'>,
  +isMultiAxes: $ElementType<Scalars, 'Boolean'>,
  +origin: $ElementType<Scalars, 'Vector3'>,
  +spacing: $ElementType<Scalars, 'Vector3'>,
|};

export type ImageRegistration = {|
  +__typename?: 'ImageRegistration',
  +clinic: $ElementType<Scalars, 'ID'>,
  +codeVersion: $ElementType<Scalars, 'String'>,
  +created: $ElementType<Scalars, 'DateTime'>,
  +dataType: $ElementType<Scalars, 'String'>,
  +fixedFrameOfReferenceUid: $ElementType<Scalars, 'ID'>,
  +modelName: $ElementType<Scalars, 'String'>,
  +modelVersion: $ElementType<Scalars, 'String'>,
  +movingFrameOfReferenceUid: $ElementType<Scalars, 'ID'>,
  +seriesFixed: $ElementType<Scalars, 'ID'>,
  +seriesMoving: $ElementType<Scalars, 'ID'>,
  +smid: $ElementType<Scalars, 'ID'>,
  +transformMatrix: $ElementType<Scalars, 'TransformMatrix'>,
|};

export type ImpressionGenerator = {|
  +__typename?: 'ImpressionGenerator',
  +automatic: $ElementType<Scalars, 'Boolean'>,
  +enabled: $ElementType<Scalars, 'Boolean'>,
  +modalities: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
|};

export type ImpressionGeneratorInput = {|
  automatic: $ElementType<Scalars, 'Boolean'>,
  enabled: $ElementType<Scalars, 'Boolean'>,
  modalities: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
|};

export type IndexCoordinates = {|
  +__typename?: 'IndexCoordinates',
  +indexSpace: $ElementType<Scalars, 'Vector3'>,
|};

export type KeyboardPreferences = {|
  +__typename?: 'KeyboardPreferences',
  +shortcuts: $ReadOnlyArray<Shortcut>,
|};

export type Layer = {|
  +__typename?: 'Layer',
  +index: $ElementType<Scalars, 'Int'>,
  +stackSmid: $ElementType<Scalars, 'ID'>,
|};

export type LayeredStack = {|
  ...BaseStack,
  ...{|
    +__typename?: 'LayeredStack',
    +smid: $ElementType<Scalars, 'ID'>,
    +stackLayers: $ReadOnlyArray<Layer>,
    +study: Study,
    +type: $ElementType<Scalars, 'String'>,
  |},
|};

export type LimitedUser = {|
  +__typename?: 'LimitedUser',
  +email: $ElementType<Scalars, 'String'>,
  +firstName: $ElementType<Scalars, 'String'>,
  +id: $ElementType<Scalars, 'ID'>,
  +lastName: $ElementType<Scalars, 'String'>,
|};

export type LineIndicator = {|
  +__typename?: 'LineIndicator',
  +enabled: $ElementType<Scalars, 'Boolean'>,
  +placement: LineIndicatorPlacement,
  +variant: LineIndicatorVariant,
|};

export type LineIndicatorInput = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
  placement: LineIndicatorPlacement,
  variant: LineIndicatorVariant,
|};

export const LineIndicatorPlacementValues = Object.freeze({
  Left: 'left',
  Right: 'right',
});

export type LineIndicatorPlacement = $Values<typeof LineIndicatorPlacementValues>;

export const LineIndicatorVariantValues = Object.freeze({
  Arrow: 'arrow',
  Dot: 'dot',
});

export type LineIndicatorVariant = $Values<typeof LineIndicatorVariantValues>;

export type LinkAuthenticatorResponse = {|
  +__typename?: 'LinkAuthenticatorResponse',
  +linked: $ElementType<Scalars, 'Boolean'>,
|};

export type List = {|
  +cursor?: ?$ElementType<Scalars, 'ID'>,
  +totalCount: $ElementType<Scalars, 'Int'>,
|};

export type MfaRequired = {|
  +__typename?: 'MFARequired',
  +mfaSessionId: $ElementType<Scalars, 'String'>,
|};

export type Macro = {|
  +__typename?: 'Macro',
  +created: $ElementType<Scalars, 'DateTime'>,
  +name: $ElementType<Scalars, 'String'>,
  +nestedMacros: $ReadOnlyArray<Macro>,
  +owner?: ?User,
  +picklists: $ReadOnlyArray<ReportPicklist>,
  +procedures?: ?$ReadOnlyArray<Procedure>,
  +smid: $ElementType<Scalars, 'ID'>,
  +text: $ReadOnlyArray<$ElementType<Scalars, 'JSON'>>,
  +triggerPhrase: $ElementType<Scalars, 'String'>,
  +updated?: ?$ElementType<Scalars, 'DateTime'>,
|};

export type MacroPayload = {|
  name: $ElementType<Scalars, 'String'>,
  picklists: $ReadOnlyArray<IReportPicklist>,
  procedures?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  text: $ReadOnlyArray<$ElementType<Scalars, 'JSON'>>,
  triggerPhrase: $ElementType<Scalars, 'String'>,
|};

export type Macros = {|
  +__typename?: 'Macros',
  +cursor?: ?$ElementType<Scalars, 'ID'>,
  +items: $ReadOnlyArray<Macro>,
  +next?: ?$ElementType<Scalars, 'ID'>,
  +previous?: ?$ElementType<Scalars, 'ID'>,
  +totalCount: $ElementType<Scalars, 'Int'>,
|};

export type ManualLocalizerLinesResponse = {|
  +__typename?: 'ManualLocalizerLinesResponse',
  +showManualLocalizerLines: $ElementType<Scalars, 'Boolean'>,
|};

export const MatchTypeValues = Object.freeze({
  Mrn: 'MRN',
  PatientAttributes: 'PATIENT_ATTRIBUTES',
  PatientAttributesPartial: 'PATIENT_ATTRIBUTES_PARTIAL',
});

export type MatchType = $Values<typeof MatchTypeValues>;

export type MergeFieldInstance = {|
  id: $ElementType<Scalars, 'ID'>,
  resolved: $ElementType<Scalars, 'Boolean'>,
  type: $ElementType<Scalars, 'ID'>,
|};

export type MergeFieldsSettings = {|
  +__typename?: 'MergeFieldsSettings',
  +ignoreDefaultSelection: $ElementType<Scalars, 'Boolean'>,
  +ignoreNavigation: $ElementType<Scalars, 'Boolean'>,
|};

export type MergeFieldsSettingsInput = {|
  ignoreDefaultSelection: $ElementType<Scalars, 'Boolean'>,
  ignoreNavigation: $ElementType<Scalars, 'Boolean'>,
|};

export const MessageDisplayValues = Object.freeze({
  Alert: 'ALERT',
  Toast: 'TOAST',
});

export type MessageDisplay = $Values<typeof MessageDisplayValues>;

export const MessageSeverityValues = Object.freeze({
  Error: 'ERROR',
  Info: 'INFO',
  Success: 'SUCCESS',
});

export type MessageSeverity = $Values<typeof MessageSeverityValues>;

export type MipPreferences = {|
  +__typename?: 'MipPreferences',
  +defaultSlabThickness: $ElementType<Scalars, 'Int'>,
  +slabThicknessInterval: $ElementType<Scalars, 'Int'>,
|};

export type MirrorViewerLayoutsPreferences = {|
  +__typename?: 'MirrorViewerLayoutsPreferences',
  +enabled: $ElementType<Scalars, 'Boolean'>,
|};

export type Modality = {|
  +__typename?: 'Modality',
  +name: $ElementType<Scalars, 'String'>,
|};

export type ModalityDicomTags = {|
  +__typename?: 'ModalityDicomTags',
  +modality: $ElementType<Scalars, 'String'>,
  +modalityDicomTags: $ReadOnlyArray<DicomTagRenderInfo>,
|};

export type ModalityLutModule = {|
  +__typename?: 'ModalityLutModule',
  +modalityLutSequence?: ?$ReadOnlyArray<ModalityLutSequence>,
  +rescaleIntercept?: ?$ElementType<Scalars, 'String'>,
  +rescaleSlope?: ?$ElementType<Scalars, 'String'>,
  +rescaleType?: ?$ElementType<Scalars, 'String'>,
|};

export type ModalityLutSequence = {|
  +__typename?: 'ModalityLutSequence',
  +lutData: $ReadOnlyArray<$ElementType<Scalars, 'Int'>>,
  +lutDescriptor: $ElementType<Scalars, 'ModalityLutDescriptor'>,
  +lutExplanation?: ?$ElementType<Scalars, 'String'>,
  +modalityLutType: $ElementType<Scalars, 'String'>,
|};

export type Modules = {|
  +__typename?: 'Modules',
  +cine?: ?CineModule,
  +modalityLut?: ?ModalityLutModule,
  +multiFrame?: ?MultiFrameModule,
  +regionCalibration?: ?RegionCalibrationModule,
  +voiLut?: ?VoiLutModule,
|};

export const MouseActionValues = Object.freeze({
  Click: 'CLICK',
  DoubleClick: 'DOUBLE_CLICK',
});

export type MouseAction = $Values<typeof MouseActionValues>;

export const MouseButtonValues = Object.freeze({
  Left: 'LEFT',
  Middle: 'MIDDLE',
  Right: 'RIGHT',
});

export type MouseButton = $Values<typeof MouseButtonValues>;

export type MousePreferences = {|
  +__typename?: 'MousePreferences',
  +left: $ElementType<Scalars, 'ID'>,
  +middle: $ElementType<Scalars, 'ID'>,
  +right: $ElementType<Scalars, 'ID'>,
|};

export type MultiFactorAuth = {|
  +__typename?: 'MultiFactorAuth',
  +enabled: $ElementType<Scalars, 'Boolean'>,
  +linked: $ElementType<Scalars, 'Boolean'>,
|};

export type MultiFactorAuthResponse = {|
  +__typename?: 'MultiFactorAuthResponse',
  +authenticatorToken?: ?$ElementType<Scalars, 'String'>,
  +multiFactorAuthEnabled: $ElementType<Scalars, 'Boolean'>,
|};

export type MultiFactorLinkResponse = {|
  +__typename?: 'MultiFactorLinkResponse',
  +multiFactorAuthLinked: $ElementType<Scalars, 'Boolean'>,
|};

export type MultiFrameModule = {|
  +__typename?: 'MultiFrameModule',
  +frameIncrementPointer: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  +numberOfFrames: $ElementType<Scalars, 'Int'>,
|};

export type Mutation = {|
  +__typename?: 'Mutation',
  +acceptAiAnnotations: AcceptAiAnnotationResponse,
  +assignDefaultAutoloadTemplate: AssignDefaultAutoloadTemplatePayload,
  +authenticate?: ?AuthenticateResponse,
  +authenticateMFA?: ?User,
  +cancelAddendum: Addendum,
  +cancelReport: Report,
  +changeWorkListItemCustomStatus?: ?WorkListItem,
  +changeWorkListItemStatus?: ?WorkListItem,
  +confirmAccount: ConfirmResponse,
  +createAddendum: Addendum,
  +createAnnotation?: ?UgcAnnotation,
  +createAutoCorrectEntry: CreateAutoCorrectEntryResponse,
  +createHangingProtocol?: ?HangingProtocol,
  +createMacro: Macro,
  +createPresentationState?: ?PresentationState,
  +createTemplate: ReportTemplate,
  +createVocabEntry: CreateVocabEntryResponse,
  +createWorkListItemGroup: $ElementType<Scalars, 'ID'>,
  +createWorkspacePreset: WorkspacePreset,
  +createWwwcToolPreset?: ?WwwcPreset,
  +deleteAddendum: ConfirmResponse,
  +deleteAllAnnotationsInStudy?: ?Response,
  +deleteAnnotation?: ?Response,
  +deleteAnnotations: DeleteAnnotationsResponse,
  +deleteAutoCorrectEntry: DeleteAutoCorrectEntryResponse,
  +deleteHangingProtocol?: ?HangingProtocolDeleted,
  +deleteMacro: DeleteMacroResponse,
  +deleteTemplate: DeleteTemplateResponse,
  +deleteUserLinkedAiAnnotations?: ?Response,
  +deleteVocabEntry: DeleteVocabEntryResponse,
  +deleteWorkListItemGroup?: ?Response,
  +deleteWorkspacePreset: ConfirmDeleteWorkspacePreset,
  +deleteWwwcToolPreset: WwcPresetDeleteResponse,
  +empty?: ?$ElementType<Scalars, 'Boolean'>,
  +generateImpression?: ?GenerateImpressionResponse,
  +linkAuthenticator: LinkAuthenticatorResponse,
  +rejectAiAnnotations: RejectAiAnnotationLog,
  +requestPasswordReset: ConfirmResponse,
  +resetAndReleaseWorklistItem?: ?WorkListItem,
  +resetPassword: ConfirmResponse,
  +rhinoFeedback?: ?RhinoFeedbackResponse,
  +rhinoMedCheck?: ?RhinoMedCheckResponse,
  +rhinoQuery?: ?RhinoQueryResponse,
  +saveHangingProtocol?: ?User,
  +setWorkspaceAutoApply: WorkspacePreferences,
  +setWwwcDragDirection: SetWwwcDragDirectionResponse,
  +submitAddendum: Addendum,
  +submitReport: SubmitReportResult,
  +toggleAbsoluteScroll: AbsoluteScrollingResponse,
  +toggleAnatomicNavigator: AnatomicNavigatorResponse,
  +toggleEraseAllToolScope?: ?EraseAllToolScopeResponse,
  +toggleManualLocalizerLines: ManualLocalizerLinesResponse,
  +toggleMultiFactorAuth: MultiFactorAuthResponse,
  +toggleMultiFactorLink: MultiFactorLinkResponse,
  +toggleScreenShare: ScreenShareSettingResponse,
  +toggleStackedScrolling: StackedScrollingResponse,
  +toggleVirtualMonitorSplit?: ?VirtualMonitorSplitResponse,
  +triggerToolInteraction: $ReadOnlyArray<ToolInteractionResponseUnion>,
  +unauthenticate: $ElementType<Scalars, 'Boolean'>,
  +undoChangeWorkListItemCustomStatus?: ?WorkListItem,
  +updateAddendum: Addendum,
  +updateAnnotation?: ?UgcAnnotation,
  +updateAutoCorrectEntry: UpdateAutoCorrectEntryResponse,
  +updateBarPreferences: BarPreferences,
  +updateCustomDicomTags: CustomDicomTagsResponse,
  +updateDictaphonePreferences: DictaphonePreferences,
  +updateHangingProtocol?: ?HangingProtocol,
  +updateKeyboardPreferences: KeyboardPreferences,
  +updateLastViewedWorklists?: ?User,
  +updateMacro: Macro,
  +updateMe: User,
  +updateMipPreferences: MipPreferences,
  +updateMirrorViewerLayoutsPreferences: MirrorViewerLayoutsPreferences,
  +updateMousePreferences: MousePreferences,
  +updateMouseScrollDirection: ToolPreferences,
  +updateMouseSensitivityPreferences: ToolPreferences,
  +updatePassword: ConfirmResponse,
  +updateReport: Report,
  +updateReporterSettings: ReporterSettings,
  +updateResetToolOnScrollPreferences: ResetToolOnScrollPreferences,
  +updateSeriesSkipSettings: SeriesSkipSettingsResponse,
  +updateShowIconLabelPreferences: ShowIconLabelPreferences,
  +updateStackedModalities: StackedScrollingModalitiesResponse,
  +updateTemplate: ReportTemplate,
  +updateWheelPreferences: WheelPreferences,
  +updateWorkListItemGroup: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  +updateWorklistSettings: WorklistSettings,
  +updateWorkspacePreset: WorkspacePreset,
  +updateWwwcToolPreset?: ?WwwcPreset,
|};

export type MutationAcceptAiAnnotationsArgs = {|
  payload: $ReadOnlyArray<AcceptAiAnnotation>,
|};

export type MutationAssignDefaultAutoloadTemplateArgs = {|
  input: AssignDefaultAutoloadTemplateInput,
|};

export type MutationAuthenticateArgs = {|
  email: $ElementType<Scalars, 'String'>,
  password: $ElementType<Scalars, 'String'>,
|};

export type MutationAuthenticateMfaArgs = {|
  email: $ElementType<Scalars, 'String'>,
  mfaSessionId: $ElementType<Scalars, 'String'>,
  totp: $ElementType<Scalars, 'String'>,
|};

export type MutationCancelAddendumArgs = {|
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationCancelReportArgs = {|
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationChangeWorkListItemCustomStatusArgs = {|
  customStatusID: $ElementType<Scalars, 'ID'>,
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationChangeWorkListItemStatusArgs = {|
  claim?: ?$ElementType<Scalars, 'Boolean'>,
  resetReport?: ?$ElementType<Scalars, 'Boolean'>,
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationConfirmAccountArgs = {|
  password: $ElementType<Scalars, 'String'>,
  token: $ElementType<Scalars, 'ID'>,
  userId: $ElementType<Scalars, 'String'>,
|};

export type MutationCreateAddendumArgs = {|
  reportSmid: $ElementType<Scalars, 'ID'>,
|};

export type MutationCreateAnnotationArgs = {|
  aiAnnotationSmid?: ?$ElementType<Scalars, 'ID'>,
  data: $ElementType<Scalars, 'JSON'>,
  pixel_space_representation?: ?$ElementType<Scalars, 'JSON'>,
  seriesSmid: $ElementType<Scalars, 'ID'>,
  type?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationCreateAutoCorrectEntryArgs = {|
  input: CreateAutoCorrectEntryInput,
|};

export type MutationCreateHangingProtocolArgs = {|
  payload: HangingProtocolPayload,
|};

export type MutationCreateMacroArgs = {|
  payload: MacroPayload,
|};

export type MutationCreatePresentationStateArgs = {|
  annotationSmids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  name: $ElementType<Scalars, 'String'>,
|};

export type MutationCreateTemplateArgs = {|
  payload: IReportTemplatePayload,
|};

export type MutationCreateVocabEntryArgs = {|
  input: CreateVocabEntryInput,
|};

export type MutationCreateWorkListItemGroupArgs = {|
  workListItemSmids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type MutationCreateWorkspacePresetArgs = {|
  payload: WorkspacePresetPayload,
|};

export type MutationCreateWwwcToolPresetArgs = {|
  payload: WwwcPresetPayload,
|};

export type MutationDeleteAddendumArgs = {|
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationDeleteAllAnnotationsInStudyArgs = {|
  studyInstanceUID: $ElementType<Scalars, 'String'>,
|};

export type MutationDeleteAnnotationArgs = {|
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationDeleteAnnotationsArgs = {|
  smids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type MutationDeleteAutoCorrectEntryArgs = {|
  input: DeleteAutoCorrectEntryInput,
|};

export type MutationDeleteHangingProtocolArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type MutationDeleteMacroArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type MutationDeleteTemplateArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type MutationDeleteUserLinkedAiAnnotationsArgs = {|
  payload: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
|};

export type MutationDeleteVocabEntryArgs = {|
  input: DeleteVocabEntryInput,
|};

export type MutationDeleteWorkListItemGroupArgs = {|
  groupId: $ElementType<Scalars, 'ID'>,
|};

export type MutationDeleteWorkspacePresetArgs = {|
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationDeleteWwwcToolPresetArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type MutationGenerateImpressionArgs = {|
  input: GenerateImpressionInput,
|};

export type MutationLinkAuthenticatorArgs = {|
  password: $ElementType<Scalars, 'String'>,
  totp: $ElementType<Scalars, 'String'>,
  userId?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationRejectAiAnnotationsArgs = {|
  ids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type MutationRequestPasswordResetArgs = {|
  email: $ElementType<Scalars, 'String'>,
|};

export type MutationResetAndReleaseWorklistItemArgs = {|
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationResetPasswordArgs = {|
  password: $ElementType<Scalars, 'String'>,
  token: $ElementType<Scalars, 'ID'>,
  userId: $ElementType<Scalars, 'ID'>,
|};

export type MutationRhinoFeedbackArgs = {|
  input: RhinoFeedbackInput,
|};

export type MutationRhinoMedCheckArgs = {|
  input: RhinoMedCheckInput,
|};

export type MutationRhinoQueryArgs = {|
  input: RhinoQueryInput,
|};

export type MutationSaveHangingProtocolArgs = {|
  hangingProtocolId: $ElementType<Scalars, 'ID'>,
  payload: $ElementType<Scalars, 'JSON'>,
  userId: $ElementType<Scalars, 'ID'>,
|};

export type MutationSetWorkspaceAutoApplyArgs = {|
  value: $ElementType<Scalars, 'Boolean'>,
|};

export type MutationSetWwwcDragDirectionArgs = {|
  value: $ElementType<Scalars, 'Boolean'>,
|};

export type MutationSubmitAddendumArgs = {|
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationSubmitReportArgs = {|
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationToggleAbsoluteScrollArgs = {|
  absoluteScroll: $ElementType<Scalars, 'Boolean'>,
|};

export type MutationToggleAnatomicNavigatorArgs = {|
  anatomicNavigatorEnabled: $ElementType<Scalars, 'Boolean'>,
|};

export type MutationToggleEraseAllToolScopeArgs = {|
  eraseAllToolScope?: ?EraseAllToolScope,
|};

export type MutationToggleManualLocalizerLinesArgs = {|
  showManualLocalizerLines: $ElementType<Scalars, 'Boolean'>,
|};

export type MutationToggleMultiFactorAuthArgs = {|
  multiFactorAuthEnabled: $ElementType<Scalars, 'Boolean'>,
  password: $ElementType<Scalars, 'String'>,
  totp?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationToggleMultiFactorLinkArgs = {|
  multiFactorAuthLinked: $ElementType<Scalars, 'Boolean'>,
|};

export type MutationToggleScreenShareArgs = {|
  screenShareEnabled: $ElementType<Scalars, 'Boolean'>,
|};

export type MutationToggleStackedScrollingArgs = {|
  stackInstancesByType: $ElementType<Scalars, 'Boolean'>,
|};

export type MutationToggleVirtualMonitorSplitArgs = {|
  virtualMonitorSplit?: ?VirtualMonitorSplit,
|};

export type MutationTriggerToolInteractionArgs = {|
  payload: ToolInteractionPayload,
  toolId: $ElementType<Scalars, 'ID'>,
  toolInteractionId: $ElementType<Scalars, 'ID'>,
  toolInteractionType: ToolInteractionType,
|};

export type MutationUndoChangeWorkListItemCustomStatusArgs = {|
  customStatusID: $ElementType<Scalars, 'ID'>,
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationUpdateAddendumArgs = {|
  payload: AddendumPayload,
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationUpdateAnnotationArgs = {|
  data: $ElementType<Scalars, 'JSON'>,
  pixel_space_representation?: ?$ElementType<Scalars, 'JSON'>,
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationUpdateAutoCorrectEntryArgs = {|
  input: UpdateAutoCorrectEntryInput,
|};

export type MutationUpdateBarPreferencesArgs = {|
  ids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type MutationUpdateCustomDicomTagsArgs = {|
  modality: $ElementType<Scalars, 'String'>,
  modalityDicomTags: $ReadOnlyArray<DicomTagRenderInfoInput>,
|};

export type MutationUpdateDictaphonePreferencesArgs = {|
  deviceLabel?: ?$ElementType<Scalars, 'String'>,
  keyMapping?: ?$ReadOnlyArray<IDictaphoneKey>,
  recordWhilePressed?: ?$ElementType<Scalars, 'Boolean'>,
  showPicklistOptionsInEditor?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type MutationUpdateHangingProtocolArgs = {|
  id: $ElementType<Scalars, 'ID'>,
  payload: HangingProtocolPayload,
|};

export type MutationUpdateKeyboardPreferencesArgs = {|
  id: $ElementType<Scalars, 'ID'>,
  interactionId?: ?$ElementType<Scalars, 'String'>,
  key: $ElementType<Scalars, 'String'>,
|};

export type MutationUpdateLastViewedWorklistsArgs = {|
  smids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type MutationUpdateMacroArgs = {|
  id: $ElementType<Scalars, 'ID'>,
  payload: MacroPayload,
|};

export type MutationUpdateMeArgs = {|
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  physicianId?: ?$ElementType<Scalars, 'String'>,
  preferredWorkLocation?: ?PreferredWorkLocationPayload,
|};

export type MutationUpdateMipPreferencesArgs = {|
  id: $ElementType<Scalars, 'ID'>,
  value: $ElementType<Scalars, 'Int'>,
|};

export type MutationUpdateMirrorViewerLayoutsPreferencesArgs = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
|};

export type MutationUpdateMousePreferencesArgs = {|
  button: $ElementType<Scalars, 'String'>,
  id: $ElementType<Scalars, 'ID'>,
|};

export type MutationUpdateMouseScrollDirectionArgs = {|
  invertScroll: $ElementType<Scalars, 'Boolean'>,
|};

export type MutationUpdateMouseSensitivityPreferencesArgs = {|
  fastScroll?: ?$ElementType<Scalars, 'Float'>,
  fineScroll?: ?$ElementType<Scalars, 'Boolean'>,
  regularScroll?: ?$ElementType<Scalars, 'Float'>,
|};

export type MutationUpdatePasswordArgs = {|
  newPassword: $ElementType<Scalars, 'String'>,
  oldPassword: $ElementType<Scalars, 'String'>,
  totp?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationUpdateReportArgs = {|
  payload: ReportPayload,
  smid: $ElementType<Scalars, 'ID'>,
  worklistItemSmid: $ElementType<Scalars, 'ID'>,
|};

export type MutationUpdateReporterSettingsArgs = {|
  aiMode?: ?AiModeInput,
  autoFillComparison?: ?AutoFillComparisonInput,
  cursorStyle?: ?CursorStyleInput,
  impressionGenerator?: ?ImpressionGeneratorInput,
  lineIndicator?: ?LineIndicatorInput,
  mergeFieldsSettings?: ?MergeFieldsSettingsInput,
  styles?: ?StylesInput,
  triggerWord?: ?$ElementType<Scalars, 'String'>,
|};

export type MutationUpdateResetToolOnScrollPreferencesArgs = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
|};

export type MutationUpdateSeriesSkipSettingsArgs = {|
  seriesSkipSettings?: ?SeriesSkipSettingsPayload,
|};

export type MutationUpdateShowIconLabelPreferencesArgs = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
|};

export type MutationUpdateStackedModalitiesArgs = {|
  stackedModalities: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
|};

export type MutationUpdateTemplateArgs = {|
  id: $ElementType<Scalars, 'ID'>,
  payload: IReportTemplatePayload,
|};

export type MutationUpdateWheelPreferencesArgs = {|
  ids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type MutationUpdateWorkListItemGroupArgs = {|
  groupId: $ElementType<Scalars, 'ID'>,
  workListItemSmids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type MutationUpdateWorklistSettingsArgs = {|
  columns?: ?$ReadOnlyArray<WorklistColumnInput>,
  customWorklists?: ?$ReadOnlyArray<?CustomWorklistInput>,
  lastUsedCustomWorklist?: ?CustomWorklistInput,
  openPatientJacketOnRead?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type MutationUpdateWorkspacePresetArgs = {|
  payload: WorkspacePresetPayload,
  smid: $ElementType<Scalars, 'ID'>,
|};

export type MutationUpdateWwwcToolPresetArgs = {|
  id: $ElementType<Scalars, 'ID'>,
  payload: WwwcPresetPayload,
|};

export type NvoqCredentials = {|
  +__typename?: 'NvoqCredentials',
  +password: $ElementType<Scalars, 'String'>,
  +username: $ElementType<Scalars, 'String'>,
|};

export type Owner = {|
  +__typename?: 'Owner',
  +email: $ElementType<Scalars, 'String'>,
  +firstName: $ElementType<Scalars, 'String'>,
  +id: $ElementType<Scalars, 'String'>,
  +lastName: $ElementType<Scalars, 'String'>,
|};

export type PixelUrl = {|
  +__typename?: 'PixelURL',
  +fallbackUrl: $ElementType<Scalars, 'String'>,
  +frameSmid: $ElementType<Scalars, 'ID'>,
  +signedUrl: SignedUrl,
|};

export type PredictTemplateResponse = {|
  +__typename?: 'PredictTemplateResponse',
  +findings: $ElementType<Scalars, 'TemplatePrediction'>,
|};

export type PreferredWorkLocation = {|
  +__typename?: 'PreferredWorkLocation',
  +home: $ElementType<Scalars, 'Boolean'>,
  +hospital: $ElementType<Scalars, 'Boolean'>,
|};

export type PreferredWorkLocationPayload = {|
  home: $ElementType<Scalars, 'Boolean'>,
  hospital: $ElementType<Scalars, 'Boolean'>,
|};

export const PreliminaryFlagValues = Object.freeze({
  Final: 'FINAL',
  Preliminary: 'PRELIMINARY',
});

export type PreliminaryFlag = $Values<typeof PreliminaryFlagValues>;

export type PresentationState = {|
  +__typename?: 'PresentationState',
  +annotationSmids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  +name: $ElementType<Scalars, 'String'>,
|};

export type Procedure = {|
  +__typename?: 'Procedure',
  +autoload?: ?$ElementType<Scalars, 'Boolean'>,
  +autoloadTemplate?: ?ReportTemplate,
  +code: $ElementType<Scalars, 'String'>,
  +description: $ElementType<Scalars, 'String'>,
  +smid: $ElementType<Scalars, 'ID'>,
|};

export type ProcedureReference = {|
  +__typename?: 'ProcedureReference',
  +autoload: $ElementType<Scalars, 'Boolean'>,
  +code?: ?$ElementType<Scalars, 'String'>,
  +description?: ?$ElementType<Scalars, 'String'>,
  +smid: $ElementType<Scalars, 'ID'>,
|};

export type Procedures = {|
  +__typename?: 'Procedures',
  +cursor?: ?$ElementType<Scalars, 'ID'>,
  +items: $ReadOnlyArray<Procedure>,
  +next?: ?$ElementType<Scalars, 'ID'>,
  +previous?: ?$ElementType<Scalars, 'ID'>,
  +totalCount: $ElementType<Scalars, 'Int'>,
|};

export type ProvenanceData = {|
  +__typename?: 'ProvenanceData',
  +matchType?: ?MatchType,
|};

export type Query = {|
  +__typename?: 'Query',
  +aiAnnotations?: ?$ReadOnlyArray<AiAnnotation>,
  +annotations?: ?$ReadOnlyArray<Annotation>,
  +annotationsByStudyInstanceUIDs?: ?$ReadOnlyArray<Annotation>,
  +authenticatorToken: $ElementType<Scalars, 'String'>,
  +bodyParts: $ReadOnlyArray<BodyPart>,
  +clinic?: ?Clinic,
  +customAnnotationLogic?: ?CustomAnnotationLogic,
  +customAnnotationsLogic: $ReadOnlyArray<CustomAnnotationLogic>,
  +dicomInstance?: ?DicomInstance,
  +empty?: ?$ElementType<Scalars, 'Boolean'>,
  +groupableWorkListItems: $ReadOnlyArray<WorkListItem>,
  +groupedWorkListItems: $ReadOnlyArray<WorkListItem>,
  +hangingProtocol?: ?HangingProtocol,
  +hangingProtocols: HangingProtocols,
  +headingKeywords: HeadingKeywords,
  +macro: Macro,
  +macros: Macros,
  +matchHangingProtocol?: ?HangingProtocol,
  +matchHangingProtocolByWorkListsAndStudies?: ?HangingProtocol,
  +me: User,
  +modalities: $ReadOnlyArray<Modality>,
  +pixelURLs: $ReadOnlyArray<PixelUrl>,
  +predictTemplate: PredictTemplateResponse,
  +presentationStates: $ReadOnlyArray<GspsPresentationState>,
  +procedure: Procedure,
  +procedures: Procedures,
  +proceduresBySmid: $ReadOnlyArray<Procedure>,
  +productBoardUrl: $ElementType<Scalars, 'String'>,
  +report: Report,
  +reports: $ReadOnlyArray<Report>,
  +rhinoModels: $ReadOnlyArray<RhinoModel>,
  +rhinoPrompt?: ?RhinoPrompt,
  +rhinoPrompts: $ReadOnlyArray<RhinoPrompt>,
  +seriesBySmid: $ReadOnlyArray<Series>,
  +structuredReport: StructuredReport,
  +studies: StudyItems,
  +studiesBySmid: $ReadOnlyArray<Study>,
  +study: Study,
  +studyDescriptions: StudyDescriptions,
  +template?: ?ReportTemplate,
  +templates: ReportTemplates,
  +toolPreferences: ToolPreferences,
  +tools: $ReadOnlyArray<Tool>,
  +user?: ?User,
  +users: Users,
  +vocabEntries: $ReadOnlyArray<VocabEntry>,
  +workListItem?: ?WorkListItem,
  +workListItemAuditLogs: $ReadOnlyArray<WorkListItemAuditLogItem>,
  +workListItems: WorkListItems,
  +workListViews: $ReadOnlyArray<WorkListView>,
  +worklistCustomStatuses: $ReadOnlyArray<WorklistCustomStatus>,
|};

export type QueryAiAnnotationsArgs = {|
  seriesSmid?: ?$ElementType<Scalars, 'ID'>,
  studySmids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type QueryAnnotationsArgs = {|
  query?: ?$ElementType<Scalars, 'JSON'>,
  studyIds?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  workListIds?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type QueryAnnotationsByStudyInstanceUiDsArgs = {|
  studyInstanceUIDs: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  studySmids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type QueryAuthenticatorTokenArgs = {|
  password: $ElementType<Scalars, 'String'>,
  userId?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryClinicArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryCustomAnnotationLogicArgs = {|
  toolId: $ElementType<Scalars, 'ID'>,
  type: $ElementType<Scalars, 'String'>,
|};

export type QueryCustomAnnotationsLogicArgs = {|
  toolId: $ElementType<Scalars, 'ID'>,
|};

export type QueryDicomInstanceArgs = {|
  instanceSmid: $ElementType<Scalars, 'ID'>,
  seriesSmid: $ElementType<Scalars, 'ID'>,
|};

export type QueryGroupableWorkListItemsArgs = {|
  mrn: $ElementType<Scalars, 'String'>,
  patientName: $ElementType<Scalars, 'String'>,
|};

export type QueryGroupedWorkListItemsArgs = {|
  groupId?: ?$ElementType<Scalars, 'ID'>,
|};

export type QueryHangingProtocolArgs = {|
  cases?: ?$ReadOnlyArray<$ReadOnlyArray<$ElementType<Scalars, 'ID'>>>,
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryHangingProtocolsArgs = {|
  cases?: ?$ReadOnlyArray<$ReadOnlyArray<$ElementType<Scalars, 'ID'>>>,
  cursor?: ?$ElementType<Scalars, 'ID'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  minimumNumberOfPriors?: ?$ElementType<Scalars, 'Int'>,
  sortBy?: ?HangingProtocolSortBy,
  studyDescriptions?: ?$ReadOnlyArray<$ElementType<Scalars, 'String'>>,
|};

export type QueryMacroArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryMacrosArgs = {|
  cursor?: ?$ElementType<Scalars, 'ID'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  owners?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  procedures?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  search?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryMatchHangingProtocolArgs = {|
  cases: $ReadOnlyArray<$ReadOnlyArray<$ElementType<Scalars, 'ID'>>>,
|};

export type QueryMatchHangingProtocolByWorkListsAndStudiesArgs = {|
  studyIds?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  workListIds: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type QueryPixelUrLsArgs = {|
  frames: $ReadOnlyArray<FrameToLoad>,
  isMultiFrame: $ElementType<Scalars, 'Boolean'>,
  stackSmid: $ElementType<Scalars, 'ID'>,
|};

export type QueryPredictTemplateArgs = {|
  headers: $ReadOnlyArray<$ReadOnlyArray<$ElementType<Scalars, 'String'>>>,
  report: $ElementType<Scalars, 'String'>,
  reportSmid: $ElementType<Scalars, 'String'>,
  storeLog?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type QueryPresentationStatesArgs = {|
  studyIds?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  workListIds: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type QueryProcedureArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryProceduresArgs = {|
  cursor?: ?$ElementType<Scalars, 'ID'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  search?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryProceduresBySmidArgs = {|
  smids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type QueryReportArgs = {|
  smid: $ElementType<Scalars, 'ID'>,
|};

export type QueryReportsArgs = {|
  accessionNumbers: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type QueryRhinoModelsArgs = {|
  modelIdentifier?: ?$ElementType<Scalars, 'String'>,
  vendor?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryRhinoPromptArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryRhinoPromptsArgs = {|
  featureName?: ?$ElementType<Scalars, 'String'>,
  modelIdentifier?: ?$ElementType<Scalars, 'String'>,
  role?: ?$ElementType<Scalars, 'String'>,
|};

export type QuerySeriesBySmidArgs = {|
  smids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type QueryStructuredReportArgs = {|
  frameSmid: $ElementType<Scalars, 'ID'>,
  seriesSmid: $ElementType<Scalars, 'ID'>,
|};

export type QueryStudiesArgs = {|
  bodyPart?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
  cursor?: ?$ElementType<Scalars, 'ID'>,
  dateRange?: ?$ReadOnlyArray<?$ElementType<Scalars, 'DateTime'>>,
  filter?: ?$ElementType<Scalars, 'String'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  matchTypes?: ?$ReadOnlyArray<MatchType>,
  modality?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
  mrn?: ?$ElementType<Scalars, 'String'>,
  searchSmid?: ?$ElementType<Scalars, 'ID'>,
  sortBy?: ?$ElementType<Scalars, 'String'>,
  sortColumn?: ?SortColumn,
  sortOrder?: ?SortOrder,
|};

export type QueryStudiesBySmidArgs = {|
  smids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type QueryStudyArgs = {|
  smid: $ElementType<Scalars, 'ID'>,
|};

export type QueryStudyDescriptionsArgs = {|
  cursor?: ?$ElementType<Scalars, 'ID'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  search?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryTemplateArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryTemplatesArgs = {|
  autoload?: ?$ElementType<Scalars, 'Boolean'>,
  cursor?: ?$ElementType<Scalars, 'ID'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  owners?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  procedures?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  search?: ?$ElementType<Scalars, 'String'>,
  studyDescriptions?: ?$ReadOnlyArray<$ElementType<Scalars, 'String'>>,
|};

export type QueryUserArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
|};

export type QueryUsersArgs = {|
  cursor?: ?$ElementType<Scalars, 'ID'>,
  hasMacros?: ?$ElementType<Scalars, 'Boolean'>,
  hasTemplates?: ?$ElementType<Scalars, 'Boolean'>,
  search?: ?$ElementType<Scalars, 'String'>,
|};

export type QueryWorkListItemArgs = {|
  id: $ElementType<Scalars, 'ID'>,
|};

export type QueryWorkListItemAuditLogsArgs = {|
  id?: ?$ElementType<Scalars, 'ID'>,
|};

export type QueryWorkListItemsArgs = {|
  accessionNumber?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
  activeViews?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  claimedBy?: ?$ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'ID'>,
  customStatus?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
  dateRange?: ?$ReadOnlyArray<?$ElementType<Scalars, 'DateTime'>>,
  filter?: ?$ElementType<Scalars, 'String'>,
  hasDraftReport?: ?$ElementType<Scalars, 'Boolean'>,
  issuer?: ?$ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  modality?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
  mrn?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
  patientDob?: ?$ReadOnlyArray<?$ElementType<Scalars, 'DateTime'>>,
  patientSex?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
  patientType?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
  priority?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
  site?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
  smids?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
  sortColumn?: ?SortColumn,
  sortOrder?: ?SortOrder,
  statsOnTop?: ?$ElementType<Scalars, 'Boolean'>,
  status?: ?WorkListItemStatus,
  submittedAt?: ?$ReadOnlyArray<?$ElementType<Scalars, 'DateTime'>>,
|};

export type Region = {|
  +__typename?: 'Region',
  +dataType: $ElementType<Scalars, 'String'>,
  +flags: RegionFlags,
  +location: $ReadOnlyArray<$ElementType<Scalars, 'Int'>>,
  +physicalDelta: $ReadOnlyArray<$ElementType<Scalars, 'Float'>>,
  +physicalUnits: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  +spatialFormat: $ElementType<Scalars, 'String'>,
|};

export type RegionCalibrationModule = {|
  +__typename?: 'RegionCalibrationModule',
  +regions: $ReadOnlyArray<Region>,
|};

export type RegionFlags = {|
  +__typename?: 'RegionFlags',
  +highPriority: $ElementType<Scalars, 'Boolean'>,
  +scalingProtection: $ElementType<Scalars, 'Boolean'>,
|};

export type RegularScrollPreferences = {|
  +__typename?: 'RegularScrollPreferences',
  +sensitivity: $ElementType<Scalars, 'Float'>,
|};

export type RejectAiAnnotationLog = {|
  +__typename?: 'RejectAiAnnotationLog',
  +logIds: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export const RendererSupportValues = Object.freeze({
  BetaSupported: 'BETA_SUPPORTED',
  Supported: 'SUPPORTED',
  Unsupported: 'UNSUPPORTED',
});

export type RendererSupport = $Values<typeof RendererSupportValues>;

export type Report = {|
  +__typename?: 'Report',
  +addendums: $ReadOnlyArray<Addendum>,
  +content?: ?$ElementType<Scalars, 'JSON'>,
  +created: $ElementType<Scalars, 'DateTime'>,
  +format: ReportFormat,
  +macros: $ReadOnlyArray<Macro>,
  +owner?: ?Owner,
  +picklists?: ?$ReadOnlyArray<ReportPicklist>,
  +sections: $ElementType<Scalars, 'JSON'>,
  +sectionsLastUpdated?: ?$ElementType<Scalars, 'DateTime'>,
  +sentAt?: ?$ElementType<Scalars, 'DateTime'>,
  +smid: $ElementType<Scalars, 'ID'>,
  +status: ReportStatus,
  +submittedAt?: ?$ElementType<Scalars, 'DateTime'>,
  +template?: ?ReportTemplate,
  +updated: $ElementType<Scalars, 'DateTime'>,
  +worklistitem?: ?$ElementType<Scalars, 'String'>,
  +workstationID?: ?$ElementType<Scalars, 'String'>,
|};

export const ReportFormatValues = Object.freeze({
  SironaSlate: 'SironaSlate',
  TextBlob: 'TextBlob',
});

export type ReportFormat = $Values<typeof ReportFormatValues>;

export type ReportPayload = {|
  addendums?: ?$ReadOnlyArray<IAddendum>,
  picklists: $ReadOnlyArray<IReportPicklist>,
  plugins?: ?$ElementType<Scalars, 'JSON'>,
  sections: $ElementType<Scalars, 'JSON'>,
  templateId?: ?$ElementType<Scalars, 'ID'>,
  workstationID?: ?$ElementType<Scalars, 'String'>,
|};

export type ReportPicklist = {|
  +__typename?: 'ReportPicklist',
  +id: $ElementType<Scalars, 'ID'>,
  +options: $ReadOnlyArray<ReportPicklistOption>,
|};

export type ReportPicklistOption = {|
  +__typename?: 'ReportPicklistOption',
  +default: $ElementType<Scalars, 'Boolean'>,
  +id: $ElementType<Scalars, 'ID'>,
  +name?: ?$ElementType<Scalars, 'String'>,
  +text: $ReadOnlyArray<$ElementType<Scalars, 'JSON'>>,
|};

export const ReportStatusValues = Object.freeze({
  Addendum: 'Addendum',
  Final: 'Final',
  Preliminary: 'Preliminary',
});

export type ReportStatus = $Values<typeof ReportStatusValues>;

export type ReportTemplate = {|
  +__typename?: 'ReportTemplate',
  +content: $ElementType<Scalars, 'JSON'>,
  +created: $ElementType<Scalars, 'DateTime'>,
  +id: $ElementType<Scalars, 'ID'>,
  +macros: $ReadOnlyArray<Macro>,
  +name: $ElementType<Scalars, 'String'>,
  +owner?: ?Owner,
  +picklists: $ReadOnlyArray<ReportPicklist>,
  +procedureReferences?: ?$ReadOnlyArray<ProcedureReference>,
  +sections: $ReadOnlyArray<$ElementType<Scalars, 'JSON'>>,
  +triggerPhrase: $ElementType<Scalars, 'String'>,
  +updated: $ElementType<Scalars, 'DateTime'>,
|};

export type ReportTemplates = {|
  +__typename?: 'ReportTemplates',
  +cursor?: ?$ElementType<Scalars, 'ID'>,
  +items: $ReadOnlyArray<ReportTemplate>,
  +next?: ?$ElementType<Scalars, 'ID'>,
  +previous?: ?$ElementType<Scalars, 'ID'>,
  +totalCount: $ElementType<Scalars, 'Int'>,
|};

export type ReporterSettings = {|
  +__typename?: 'ReporterSettings',
  +aiMode: AiModeSettings,
  +autoFillComparison: AutoFillComparison,
  +cursorStyle: CursorStyle,
  +impressionGenerator: ImpressionGenerator,
  +lineIndicator: LineIndicator,
  +mergeFieldsSettings: MergeFieldsSettings,
  +styles: Styles,
  +triggerWord: $ElementType<Scalars, 'String'>,
|};

export type ResetToolOnScrollPreferences = {|
  +__typename?: 'ResetToolOnScrollPreferences',
  +enabled: $ElementType<Scalars, 'Boolean'>,
|};

export type Response = {|
  +__typename?: 'Response',
  +status?: ?$ElementType<Scalars, 'Int'>,
|};

export type RhinoFeedbackInput = {|
  featureName?: ?$ElementType<Scalars, 'String'>,
  modelIdentifier?: ?$ElementType<Scalars, 'String'>,
  reportMetadata: $ElementType<Scalars, 'JSON'>,
  responseId: $ElementType<Scalars, 'ID'>,
  userFeedback: RhinoUserFeedbackInput,
|};

export type RhinoFeedbackResponse = {|
  +__typename?: 'RhinoFeedbackResponse',
  +success: $ElementType<Scalars, 'Boolean'>,
|};

export type RhinoMedCheckInput = {|
  caseId: $ElementType<Scalars, 'String'>,
  reportId: $ElementType<Scalars, 'String'>,
|};

export type RhinoMedCheckResponse = {|
  +__typename?: 'RhinoMedCheckResponse',
  +content?: ?$ElementType<Scalars, 'String'>,
|};

export type RhinoModel = {|
  +__typename?: 'RhinoModel',
  +config: RhinoModelConfig,
  +id: $ElementType<Scalars, 'ID'>,
  +label: $ElementType<Scalars, 'String'>,
  +vendor: $ElementType<Scalars, 'String'>,
|};

export type RhinoModelConfig = {|
  +__typename?: 'RhinoModelConfig',
  +modelName: $ElementType<Scalars, 'String'>,
  +temperature: $ElementType<Scalars, 'Float'>,
|};

export type RhinoPrompt = {|
  +__typename?: 'RhinoPrompt',
  +featureName: $ElementType<Scalars, 'String'>,
  +id: $ElementType<Scalars, 'ID'>,
  +label: $ElementType<Scalars, 'String'>,
  +modelIdentifier: $ElementType<Scalars, 'String'>,
  +prompt: $ElementType<Scalars, 'String'>,
  +role: $ElementType<Scalars, 'String'>,
|};

export type RhinoQueryInput = {|
  featureName: $ElementType<Scalars, 'String'>,
  modelIdentifier: $ElementType<Scalars, 'String'>,
  promptId: $ElementType<Scalars, 'String'>,
  queryMessages: $ReadOnlyArray<RhinoQueryMessageInput>,
  reportId: $ElementType<Scalars, 'String'>,
  reportMetadata: $ElementType<Scalars, 'JSON'>,
|};

export type RhinoQueryMessage = {|
  +__typename?: 'RhinoQueryMessage',
  +content: $ElementType<Scalars, 'String'>,
  +role: $ElementType<Scalars, 'String'>,
|};

export type RhinoQueryMessageInput = {|
  content: $ElementType<Scalars, 'String'>,
  role: $ElementType<Scalars, 'String'>,
|};

export type RhinoQueryResponse = {|
  +__typename?: 'RhinoQueryResponse',
  +id: $ElementType<Scalars, 'ID'>,
  +modelIdentifier: $ElementType<Scalars, 'String'>,
  +queryMessages: $ReadOnlyArray<RhinoQueryMessage>,
  +rawApiResponse: RhinoRawApiResponse,
  +response: SironaCustomLlmResponseModel,
|};

export type RhinoRawApiResponse = {|
  +__typename?: 'RhinoRawAPIResponse',
  +choices: $ReadOnlyArray<RhinoRawApiResponseChoice>,
  +created: $ElementType<Scalars, 'Int'>,
  +id: $ElementType<Scalars, 'String'>,
  +model: $ElementType<Scalars, 'String'>,
  +object: $ElementType<Scalars, 'String'>,
  +usage: RhinoRawApiResponseUsage,
|};

export type RhinoRawApiResponseChoice = {|
  +__typename?: 'RhinoRawAPIResponseChoice',
  +finishReason: $ElementType<Scalars, 'String'>,
  +index: $ElementType<Scalars, 'Int'>,
  +message: SironaCustomLlmResponseMessage,
|};

export type RhinoRawApiResponseUsage = {|
  +__typename?: 'RhinoRawAPIResponseUsage',
  +completionTokens: $ElementType<Scalars, 'Int'>,
  +promptTokens: $ElementType<Scalars, 'Int'>,
  +totalTokens: $ElementType<Scalars, 'Int'>,
|};

export type RhinoUserFeedbackInput = {|
  customFeedbackText: $ElementType<Scalars, 'String'>,
  queryMessages: $ReadOnlyArray<RhinoQueryMessageInput>,
  rating: $ElementType<Scalars, 'Int'>,
  response?: ?SironaCustomLlmResponseModelInput,
|};

export type SrGeneralInfo = {|
  +__typename?: 'SRGeneralInfo',
  +completion_flag?: ?CompletionFlag,
  +completion_flag_description?: ?$ElementType<Scalars, 'String'>,
  +date?: ?$ElementType<Scalars, 'Date'>,
  +instance_number?: ?$ElementType<Scalars, 'Int'>,
  +preliminary_flag?: ?PreliminaryFlag,
  +time?: ?$ElementType<Scalars, 'String'>,
  +verification_status?: ?VerificationStatus,
|};

export type SrInstance = {|
  +__typename?: 'SRInstance',
  +number?: ?$ElementType<Scalars, 'Int'>,
  +uid?: ?$ElementType<Scalars, 'String'>,
|};

export type SrObservation = {|
  +__typename?: 'SRObservation',
  +datetime?: ?$ElementType<Scalars, 'String'>,
  +uid?: ?$ElementType<Scalars, 'String'>,
|};

export type SrPatient = {|
  +__typename?: 'SRPatient',
  +age?: ?$ElementType<Scalars, 'String'>,
  +id?: ?$ElementType<Scalars, 'String'>,
  +name?: ?$ElementType<Scalars, 'String'>,
  +sex?: ?$ElementType<Scalars, 'String'>,
|};

export type SrsopClass = {|
  +__typename?: 'SRSOPClass',
  +uid?: ?$ElementType<Scalars, 'String'>,
|};

export type SrSeries = {|
  +__typename?: 'SRSeries',
  +description?: ?$ElementType<Scalars, 'String'>,
  +number?: ?$ElementType<Scalars, 'Int'>,
  +uid?: ?$ElementType<Scalars, 'String'>,
|};

export type SrStudy = {|
  +__typename?: 'SRStudy',
  +date?: ?$ElementType<Scalars, 'Date'>,
  +description?: ?$ElementType<Scalars, 'String'>,
  +id?: ?$ElementType<Scalars, 'String'>,
  +time?: ?$ElementType<Scalars, 'String'>,
  +uid?: ?$ElementType<Scalars, 'String'>,
|};

export const ScreenNameValues = Object.freeze({
  Reporter: 'REPORTER',
  Viewer0: 'VIEWER0',
  Viewer1: 'VIEWER1',
  Worklist: 'WORKLIST',
});

export type ScreenName = $Values<typeof ScreenNameValues>;

export type ScreenShareSettingResponse = {|
  +__typename?: 'ScreenShareSettingResponse',
  +screenShareEnabled: $ElementType<Scalars, 'Boolean'>,
|};

export type SecuritySettings = {|
  +__typename?: 'SecuritySettings',
  +multiFactorAuth: MultiFactorAuth,
|};

export type Series = {|
  +__typename?: 'Series',
  +SOPClassUID?: ?$ElementType<Scalars, 'String'>,
  +classifications: Classifications,
  /**
   * 1. Assume the slice orders will be reversed
   * 2. Compare the z axis component of the dicom reported direction with the "fovia direction" calculated between each slice
   * 3. Find the difference between the points, and the distance
   * 4. Divide the difference by the distance to normalize it, and we negate it to get the fovia-Z component of the direction
   */
  +colorLevel?: ?$ElementType<Scalars, 'Float'>,
  +columns?: ?$ElementType<Scalars, 'Int'>,
  +description?: ?$ElementType<Scalars, 'String'>,
  /**
   * NOTE(@ian): I changed this to a String because the ids are not unique across series, so they were overriding each other
   * within the Apollo Cache.
   * The Apollo Client has been fixed to not cache DicomTags individually (instead tied to the series), so we could change
   * this back to an ID, but since it isn't really being used as a unique identifier, I feel like String is more appropriate here.
   */
  +dicomTags: $ReadOnlyArray<DicomTag>,
  +frameOfReferenceUID?: ?$ElementType<Scalars, 'String'>,
  +imageRegistrations: $ReadOnlyArray<ImageRegistration>,
  +instances: $ReadOnlyArray<DicomInstance>,
  +is3Dable: $ElementType<Scalars, 'Boolean'>,
  +pixelRepresentation?: ?$ElementType<Scalars, 'Int'>,
  +pixelSpacing?: ?$ReadOnlyArray<?$ElementType<Scalars, 'Float'>>,
  +rescaleIntercept?: ?$ElementType<Scalars, 'Float'>,
  +rescaleSlope?: ?$ElementType<Scalars, 'Float'>,
  +rows?: ?$ElementType<Scalars, 'Int'>,
  +seriesDateTimeLocal?: ?$ElementType<Scalars, 'DateTimeLocal'>,
  +seriesInstanceUID: $ElementType<Scalars, 'String'>,
  +seriesNumber?: ?$ElementType<Scalars, 'Int'>,
  +sliceThickness?: ?$ElementType<Scalars, 'Float'>,
  +smid: $ElementType<Scalars, 'ID'>,
  +study: Study,
  +windowLevel?: ?$ElementType<Scalars, 'Float'>,
|};

export type SeriesClassificationsArgs = {|
  cursor?: ?$ElementType<Scalars, 'ID'>,
|};

export type SeriesDicomTagsArgs = {|
  ids?: ?$ReadOnlyArray<$ElementType<Scalars, 'String'>>,
|};

export type SeriesSkipSettings = {|
  +__typename?: 'SeriesSkipSettings',
  +amount?: ?$ElementType<Scalars, 'Int'>,
  +keepPreviousWhenAvailable: $ElementType<Scalars, 'Boolean'>,
|};

export type SeriesSkipSettingsPayload = {|
  amount?: ?$ElementType<Scalars, 'Int'>,
  keepPreviousWhenAvailable: $ElementType<Scalars, 'Boolean'>,
|};

export type SeriesSkipSettingsResponse = {|
  +__typename?: 'SeriesSkipSettingsResponse',
  +seriesSkipSettings?: ?SeriesSkipSettings,
|};

export type SetWwwcDragDirectionResponse = {|
  +__typename?: 'SetWwwcDragDirectionResponse',
  +invertDragDirection: $ElementType<Scalars, 'Boolean'>,
|};

export type Shortcut = {|
  +__typename?: 'Shortcut',
  +id: $ElementType<Scalars, 'ID'>,
  +interactionId?: ?$ElementType<Scalars, 'String'>,
  +key?: ?$ElementType<Scalars, 'String'>,
|};

export type ShowIconLabelPreferences = {|
  +__typename?: 'ShowIconLabelPreferences',
  +enabled: $ElementType<Scalars, 'Boolean'>,
|};

export type SignedUrl = {|
  +__typename?: 'SignedUrl',
  +expirationTime: $ElementType<Scalars, 'Int'>,
  +url: $ElementType<Scalars, 'String'>,
|};

export type SingleLayerStack = {|
  ...BaseStack,
  ...{|
    +__typename?: 'SingleLayerStack',
    +frames: $ReadOnlyArray<$ElementType<Scalars, 'FrameScalar'>>,
    +image: $ElementType<Scalars, 'String'>,
    +imageParams: ImageParams,
    +is3Dable: $ElementType<Scalars, 'Boolean'>,
    +series: Series,
    +seriesNumber?: ?$ElementType<Scalars, 'Int'>,
    +smid: $ElementType<Scalars, 'ID'>,
    +study: Study,
    +supportedRenderers: SupportedRenderers,
    +type: $ElementType<Scalars, 'String'>,
  |},
|};

export type SironaCustomLlmResponseMessage = {|
  +__typename?: 'SironaCustomLLMResponseMessage',
  +content: $ElementType<Scalars, 'String'>,
  +role: $ElementType<Scalars, 'String'>,
|};

export type SironaCustomLlmResponseMessageInput = {|
  content: $ElementType<Scalars, 'String'>,
  role: $ElementType<Scalars, 'String'>,
|};

export type SironaCustomLlmResponseModel = {|
  +__typename?: 'SironaCustomLLMResponseModel',
  +message: SironaCustomLlmResponseMessage,
  +usage: SironaCustomLlmResponseUsage,
|};

export type SironaCustomLlmResponseModelInput = {|
  message: SironaCustomLlmResponseMessageInput,
  usage: SironaCustomLlmResponseUsageInput,
|};

export type SironaCustomLlmResponseUsage = {|
  +__typename?: 'SironaCustomLLMResponseUsage',
  +completionTokens: $ElementType<Scalars, 'Int'>,
  +promptTokens: $ElementType<Scalars, 'Int'>,
  +totalTokens: $ElementType<Scalars, 'Int'>,
|};

export type SironaCustomLlmResponseUsageInput = {|
  completionTokens: $ElementType<Scalars, 'Int'>,
  promptTokens: $ElementType<Scalars, 'Int'>,
  totalTokens: $ElementType<Scalars, 'Int'>,
|};

export type Slice = {|
  active: $ElementType<Scalars, 'Boolean'>,
  index: $ElementType<Scalars, 'Int'>,
  instanceId?: ?$ElementType<Scalars, 'ID'>,
  seriesId?: ?$ElementType<Scalars, 'ID'>,
  studyId?: ?$ElementType<Scalars, 'ID'>,
|};

export const SortColumnValues = Object.freeze({
  AccessionNumber: 'ACCESSION_NUMBER',
  BodyPart: 'BODY_PART',
  Created: 'CREATED',
  CustomStatusName: 'CUSTOM_STATUS_NAME',
  Date: 'DATE',
  HasDraftReport: 'HAS_DRAFT_REPORT',
  Modality: 'MODALITY',
  Mrn: 'MRN',
  PatientDob: 'PATIENT_DOB',
  PatientName: 'PATIENT_NAME',
  PatientSex: 'PATIENT_SEX',
  PatientType: 'PATIENT_TYPE',
  Priority: 'PRIORITY',
  ReferringPhysician: 'REFERRING_PHYSICIAN',
  Site: 'SITE',
  Status: 'STATUS',
  StudyDatetime: 'STUDY_DATETIME',
  StudyDescription: 'STUDY_DESCRIPTION',
  SubmittedAt: 'SUBMITTED_AT',
  Updated: 'UPDATED',
});

export type SortColumn = $Values<typeof SortColumnValues>;

export const SortOrderValues = Object.freeze({
  Asc: 'ASC',
  Desc: 'DESC',
});

export type SortOrder = $Values<typeof SortOrderValues>;

export type StackedScrollingModalitiesResponse = {|
  +__typename?: 'StackedScrollingModalitiesResponse',
  +stackedModalities: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
|};

export type StackedScrollingResponse = {|
  +__typename?: 'StackedScrollingResponse',
  +stackInstancesByType: $ElementType<Scalars, 'Boolean'>,
|};

export type StructuredReport = {|
  +__typename?: 'StructuredReport',
  +content: $ElementType<Scalars, 'ContentSequence'>,
  +general: SrGeneralInfo,
  +id: $ElementType<Scalars, 'ID'>,
  +instance: SrInstance,
  +modalities: $ElementType<Scalars, 'String'>,
  +observation: SrObservation,
  +patient: SrPatient,
  +series: SrSeries,
  +sop_class: SrsopClass,
  +study: SrStudy,
|};

export type Study = {|
  +__typename?: 'Study',
  +accessionNumber?: ?$ElementType<Scalars, 'String'>,
  +bodyPart?: ?$ElementType<Scalars, 'String'>,
  +classifications: Classifications,
  +created: $ElementType<Scalars, 'DateTime'>,
  +description?: ?$ElementType<Scalars, 'String'>,
  +hl7Order?: ?$ElementType<Scalars, 'ID'>,
  +instanceUID: $ElementType<Scalars, 'String'>,
  +laterality?: ?$ElementType<Scalars, 'String'>,
  +modality: $ElementType<Scalars, 'String'>,
  +mrn?: ?$ElementType<Scalars, 'String'>,
  +patientAge?: ?$ElementType<Scalars, 'String'>,
  +patientDob?: ?$ElementType<Scalars, 'Date'>,
  +patientName?: ?$ElementType<Scalars, 'String'>,
  +patientSex?: ?$ElementType<Scalars, 'String'>,
  +provenanceData?: ?ProvenanceData,
  +seriesCount: $ElementType<Scalars, 'Int'>,
  +seriesList: $ReadOnlyArray<Series>,
  +site?: ?$ElementType<Scalars, 'String'>,
  +smid: $ElementType<Scalars, 'ID'>,
  +stackedFrames: $ReadOnlyArray<FrameStack>,
  +studyDate?: ?$ElementType<Scalars, 'DateTime'>,
  +updated: $ElementType<Scalars, 'DateTime'>,
|};

export type StudyClassificationsArgs = {|
  cursor?: ?$ElementType<Scalars, 'ID'>,
|};

export type StudyStackedFramesArgs = {|
  groupByType: FrameGroupByType,
|};

export type StudyDescription = {|
  +__typename?: 'StudyDescription',
  +autoloadTemplate?: ?ReportTemplate,
  +description: $ElementType<Scalars, 'String'>,
  +smid: $ElementType<Scalars, 'ID'>,
|};

export type StudyDescriptions = {|
  +__typename?: 'StudyDescriptions',
  +cursor?: ?$ElementType<Scalars, 'ID'>,
  +items: $ReadOnlyArray<StudyDescription>,
  +next?: ?$ElementType<Scalars, 'ID'>,
  +previous?: ?$ElementType<Scalars, 'ID'>,
  +totalCount: $ElementType<Scalars, 'Int'>,
|};

export type StudyItems = {|
  ...List,
  ...{|
    +__typename?: 'StudyItems',
    +cursor?: ?$ElementType<Scalars, 'ID'>,
    +items: $ReadOnlyArray<Study>,
    +next?: ?$ElementType<Scalars, 'ID'>,
    +previous?: ?$ElementType<Scalars, 'ID'>,
    +totalCount: $ElementType<Scalars, 'Int'>,
  |},
|};

export type Styles = {|
  +__typename?: 'Styles',
  +bodyStyle: TextStyle,
  +headingStyles: $ReadOnlyArray<HeadingStyle>,
|};

export type StylesInput = {|
  bodyStyle: TextStyleInput,
  headingStyles: $ReadOnlyArray<HeadingStyleInput>,
|};

export type SubmitReportInvalidInput = {|
  +__typename?: 'SubmitReportInvalidInput',
  +field: SubmitReportInvalidInputField,
  +message: $ElementType<Scalars, 'String'>,
|};

export const SubmitReportInvalidInputFieldValues = Object.freeze({
  Impressions: 'IMPRESSIONS',
});

export type SubmitReportInvalidInputField = $Values<typeof SubmitReportInvalidInputFieldValues>;

export type SubmitReportPayload = {|
  +__typename?: 'SubmitReportPayload',
  +report: Report,
|};

export type SubmitReportProblem = {|
  +__typename?: 'SubmitReportProblem',
  +invalidInputs: $ReadOnlyArray<?SubmitReportInvalidInput>,
  +unknownProblem?: ?$ElementType<Scalars, 'String'>,
|};

export type SubmitReportResult = SubmitReportPayload | SubmitReportProblem;

export type Subscription = {|
  +__typename?: 'Subscription',
  +counter: Counter,
|};

export type SupportedRenderers = {|
  +__typename?: 'SupportedRenderers',
  +vtk: RendererSupport,
|};

export type TextDecoration = {|
  +__typename?: 'TextDecoration',
  +isBold?: ?$ElementType<Scalars, 'Boolean'>,
  +isItalic?: ?$ElementType<Scalars, 'Boolean'>,
  +isUnderline?: ?$ElementType<Scalars, 'Boolean'>,
|};

export type TextDecorationInput = {|
  isBold: $ElementType<Scalars, 'Boolean'>,
  isItalic: $ElementType<Scalars, 'Boolean'>,
  isUnderline: $ElementType<Scalars, 'Boolean'>,
|};

export type TextStyle = {|
  +__typename?: 'TextStyle',
  +fontFamily?: ?$ElementType<Scalars, 'String'>,
  +fontSize?: ?$ElementType<Scalars, 'Int'>,
  +textDecoration?: ?TextDecoration,
  +textTransform?: ?TextTransform,
|};

export type TextStyleInput = {|
  fontFamily?: ?$ElementType<Scalars, 'String'>,
  fontSize?: ?$ElementType<Scalars, 'Int'>,
  textDecoration?: ?TextDecorationInput,
  textTransform?: ?TextTransform,
|};

export const TextTransformValues = Object.freeze({
  Capitalize: 'capitalize',
  None: 'none',
  Uppercase: 'uppercase',
});

export type TextTransform = $Values<typeof TextTransformValues>;

export type Tool = {|
  +__typename?: 'Tool',
  +category: ToolCategory,
  +customAnnotationsLogic: $ReadOnlyArray<CustomAnnotationLogic>,
  +description: $ElementType<Scalars, 'String'>,
  +icon: $ElementType<Scalars, 'String'>,
  +id: $ElementType<Scalars, 'ID'>,
  +interactions: $ReadOnlyArray<ToolInteractionUnion>,
  +name: $ElementType<Scalars, 'String'>,
  +payloadCapabilities: $ReadOnlyArray<ToolPayloadCapability>,
  +splitFlag?: ?$ElementType<Scalars, 'String'>,
|};

export type ToolAnnotationInteraction = {|
  ...ToolInteraction,
  ...{|
    +__typename?: 'ToolAnnotationInteraction',
    +action: MouseAction,
    +button: MouseButton,
    +id: $ElementType<Scalars, 'ID'>,
    +maxConcurrentRequests?: ?$ElementType<Scalars, 'Int'>,
    +request: ToolInteractionRequest,
    +splitFlag?: ?$ElementType<Scalars, 'String'>,
    +targetAnnotationGroupId?: ?$ElementType<Scalars, 'ID'>,
    +targetAnnotationId?: ?$ElementType<Scalars, 'ID'>,
    +type: ToolInteractionType,
  |},
|};

export const ToolCategoryValues = Object.freeze({
  Annotations: 'ANNOTATIONS',
  ImageAdjustment: 'IMAGE_ADJUSTMENT',
  ImageNavigation: 'IMAGE_NAVIGATION',
  Reporter: 'REPORTER',
});

export type ToolCategory = $Values<typeof ToolCategoryValues>;

export type ToolContextMenuOptionInteraction = {|
  ...ToolInteraction,
  ...{|
    +__typename?: 'ToolContextMenuOptionInteraction',
    +id: $ElementType<Scalars, 'ID'>,
    +label: $ElementType<Scalars, 'String'>,
    +maxConcurrentRequests?: ?$ElementType<Scalars, 'Int'>,
    +request: ToolInteractionRequest,
    +splitFlag?: ?$ElementType<Scalars, 'String'>,
    +type: ToolInteractionType,
  |},
|};

export type ToolIconButtonClickInteraction = {|
  ...ToolInteraction,
  ...{|
    +__typename?: 'ToolIconButtonClickInteraction',
    +id: $ElementType<Scalars, 'ID'>,
    +maxConcurrentRequests?: ?$ElementType<Scalars, 'Int'>,
    +request: ToolInteractionRequest,
    +splitFlag?: ?$ElementType<Scalars, 'String'>,
    +type: ToolInteractionType,
  |},
|};

export type ToolInteraction = {|
  +id: $ElementType<Scalars, 'ID'>,
  +maxConcurrentRequests?: ?$ElementType<Scalars, 'Int'>,
  +request: ToolInteractionRequest,
  +splitFlag?: ?$ElementType<Scalars, 'String'>,
  +type: ToolInteractionType,
|};

export type ToolInteractionAddInteractionResponse = {|
  ...ToolInteractionResponse,
  ...{|
    +__typename?: 'ToolInteractionAddInteractionResponse',
    +interactions: $ReadOnlyArray<ToolInteraction>,
    +type: ToolInteractionResponseType,
  |},
|};

export type ToolInteractionAnnotationData = {|
  +__typename?: 'ToolInteractionAnnotationData',
  +allowedTransformations?: ?AnnotationEditableOptions,
  +customLogic?: ?CustomAnnotationLogic,
  +groupId?: ?$ElementType<Scalars, 'ID'>,
  +label?: ?$ElementType<Scalars, 'String'>,
  +segments: $ReadOnlyArray<AnnotationSegment>,
  +style?: ?AnnotationStyle,
  +type: AnnotationTypes,
|};

export type ToolInteractionAnnotationResponse = {|
  ...ToolInteractionResponse,
  ...{|
    +__typename?: 'ToolInteractionAnnotationResponse',
    +annotation: ToolInteractionAnnotationData,
    +type: ToolInteractionResponseType,
  |},
|};

export type ToolInteractionInsertTextResponse = {|
  ...ToolInteractionResponse,
  ...{|
    +__typename?: 'ToolInteractionInsertTextResponse',
    +content: $ElementType<Scalars, 'JSON'>,
    +type: ToolInteractionResponseType,
  |},
|};

export type ToolInteractionKeyboardShortcutResponse = {|
  ...ToolInteractionResponse,
  ...{|
    +__typename?: 'ToolInteractionKeyboardShortcutResponse',
    +type: ToolInteractionResponseType,
  |},
|};

export type ToolInteractionMessageResponse = {|
  ...ToolInteractionResponse,
  ...{|
    +__typename?: 'ToolInteractionMessageResponse',
    +display: MessageDisplay,
    +message: $ElementType<Scalars, 'String'>,
    +severity: MessageSeverity,
    +type: ToolInteractionResponseType,
  |},
|};

export type ToolInteractionPayload = {|
  annotations?: ?$ReadOnlyArray<AnnotationInstance>,
  coordinates?: ?$ReadOnlyArray<IExtendedCoordinates>,
  mergeFields?: ?$ReadOnlyArray<MergeFieldInstance>,
  reporterTextFragment?: ?$ElementType<Scalars, 'String'>,
  viewports?: ?$ReadOnlyArray<ViewportState>,
  widgets?: ?$ReadOnlyArray<WidgetState>,
|};

export type ToolInteractionRequest = {|
  +__typename?: 'ToolInteractionRequest',
  +method: ToolInteractionRequestMethod,
  +url: $ElementType<Scalars, 'String'>,
|};

export const ToolInteractionRequestMethodValues = Object.freeze({
  Get: 'GET',
  Post: 'POST',
});

export type ToolInteractionRequestMethod = $Values<typeof ToolInteractionRequestMethodValues>;

export type ToolInteractionResponse = {|
  +type: ToolInteractionResponseType,
|};

export const ToolInteractionResponseTypeValues = Object.freeze({
  AddInteraction: 'ADD_INTERACTION',
  Annotation: 'ANNOTATION',
  InsertText: 'INSERT_TEXT',
  KeyboardShortcut: 'KEYBOARD_SHORTCUT',
  Message: 'MESSAGE',
  StartAnnotation: 'START_ANNOTATION',
  UpdateToolbarIcon: 'UPDATE_TOOLBAR_ICON',
  UpdateToolActiveState: 'UPDATE_TOOL_ACTIVE_STATE',
  UpdateViewportPresentationState: 'UPDATE_VIEWPORT_PRESENTATION_STATE',
  UpdateViewportSlice: 'UPDATE_VIEWPORT_SLICE',
  Widget: 'WIDGET',
});

export type ToolInteractionResponseType = $Values<typeof ToolInteractionResponseTypeValues>;

export type ToolInteractionResponseUnion =
  | ToolInteractionAddInteractionResponse
  | ToolInteractionAnnotationResponse
  | ToolInteractionInsertTextResponse
  | ToolInteractionKeyboardShortcutResponse
  | ToolInteractionMessageResponse
  | ToolInteractionStartAnnotationResponse
  | ToolInteractionUpdateToolActiveStateResponse
  | ToolInteractionUpdateToolbarButtonIconResponse
  | ToolInteractionUpdateViewportPresentationStateResponse
  | ToolInteractionUpdateViewportSliceResponse
  | ToolInteractionWidgetResponse;

export type ToolInteractionStartAnnotationResponse = {|
  ...ToolInteractionResponse,
  ...{|
    +__typename?: 'ToolInteractionStartAnnotationResponse',
    +annotationType: $ElementType<Scalars, 'String'>,
    +type: ToolInteractionResponseType,
  |},
|};

export const ToolInteractionTypeValues = Object.freeze({
  Annotation: 'ANNOTATION',
  ContextMenuOption: 'CONTEXT_MENU_OPTION',
  IconButtonClick: 'ICON_BUTTON_CLICK',
  KeyboardShortcut: 'KEYBOARD_SHORTCUT',
  MergeField: 'MERGE_FIELD',
  ViewportClick: 'VIEWPORT_CLICK',
  VoiceCommand: 'VOICE_COMMAND',
});

export type ToolInteractionType = $Values<typeof ToolInteractionTypeValues>;

export type ToolInteractionUnion =
  | ToolAnnotationInteraction
  | ToolContextMenuOptionInteraction
  | ToolIconButtonClickInteraction
  | ToolKeyboardShortcutInteraction
  | ToolMergeFieldInteraction
  | ToolViewportClickInteraction
  | ToolVoiceCommandInteraction;

export type ToolInteractionUpdateToolActiveStateResponse = {|
  ...ToolInteractionResponse,
  ...{|
    +__typename?: 'ToolInteractionUpdateToolActiveStateResponse',
    +active: $ElementType<Scalars, 'Boolean'>,
    +toolId?: ?$ElementType<Scalars, 'ID'>,
    +type: ToolInteractionResponseType,
  |},
|};

export type ToolInteractionUpdateToolbarButtonIconResponse = {|
  ...ToolInteractionResponse,
  ...{|
    +__typename?: 'ToolInteractionUpdateToolbarButtonIconResponse',
    +icon: $ElementType<Scalars, 'String'>,
    +type: ToolInteractionResponseType,
  |},
|};

export type ToolInteractionUpdateViewportPresentationStateResponse = {|
  ...ToolInteractionResponse,
  ...{|
    +__typename?: 'ToolInteractionUpdateViewportPresentationStateResponse',
    +level?: ?$ElementType<Scalars, 'Float'>,
    +rotate?: ?$ElementType<Scalars, 'Float'>,
    +type: ToolInteractionResponseType,
    +viewportId?: ?$ElementType<Scalars, 'ID'>,
    +window?: ?$ElementType<Scalars, 'Float'>,
    +zoom?: ?$ElementType<Scalars, 'Float'>,
  |},
|};

export type ToolInteractionUpdateViewportSliceResponse = {|
  ...ToolInteractionResponse,
  ...{|
    +__typename?: 'ToolInteractionUpdateViewportSliceResponse',
    +slice: $ElementType<Scalars, 'Int'>,
    +type: ToolInteractionResponseType,
    +viewportId?: ?$ElementType<Scalars, 'ID'>,
  |},
|};

export type ToolInteractionWidgetResponse = {|
  ...ToolInteractionResponse,
  ...{|
    +__typename?: 'ToolInteractionWidgetResponse',
    +type: ToolInteractionResponseType,
    +widget: WidgetConfiguration,
  |},
|};

export type ToolKeyboardShortcutInteraction = {|
  ...ToolInteraction,
  ...{|
    +__typename?: 'ToolKeyboardShortcutInteraction',
    +id: $ElementType<Scalars, 'ID'>,
    +keyboardShortcut: $ElementType<Scalars, 'String'>,
    +maxConcurrentRequests?: ?$ElementType<Scalars, 'Int'>,
    +request: ToolInteractionRequest,
    +splitFlag?: ?$ElementType<Scalars, 'String'>,
    +type: ToolInteractionType,
  |},
|};

export type ToolMergeFieldInteraction = {|
  ...ToolInteraction,
  ...{|
    +__typename?: 'ToolMergeFieldInteraction',
    +id: $ElementType<Scalars, 'ID'>,
    +label: $ElementType<Scalars, 'String'>,
    +maxConcurrentRequests?: ?$ElementType<Scalars, 'Int'>,
    +request: ToolInteractionRequest,
    +splitFlag?: ?$ElementType<Scalars, 'String'>,
    +type: ToolInteractionType,
  |},
|};

export const ToolPayloadCapabilityValues = Object.freeze({
  ReporterMergeFields: 'REPORTER__MERGE_FIELDS',
  ReporterTextFragment: 'REPORTER__TEXT_FRAGMENT',
  ViewerAnnotations: 'VIEWER__ANNOTATIONS',
  ViewerCoordinates: 'VIEWER__COORDINATES',
  ViewerViewports: 'VIEWER__VIEWPORTS',
  ViewerViewportsDisplayConfig: 'VIEWER__VIEWPORTS__DISPLAY_CONFIG',
  ViewerViewportsSlices: 'VIEWER__VIEWPORTS__SLICES',
  Widgets: 'WIDGETS',
});

export type ToolPayloadCapability = $Values<typeof ToolPayloadCapabilityValues>;

export type ToolPreferences = {|
  +__typename?: 'ToolPreferences',
  +bar: BarPreferences,
  +dictaphone: DictaphonePreferences,
  +fastScroll: FastScrollPreferences,
  +fineScroll: FineScrollPreferences,
  +invertScroll: $ElementType<Scalars, 'Boolean'>,
  +keyboard: KeyboardPreferences,
  +mip: MipPreferences,
  +mirrorViewerLayouts: MirrorViewerLayoutsPreferences,
  +mouse: MousePreferences,
  +regularScroll: RegularScrollPreferences,
  +resetToolOnScroll: ResetToolOnScrollPreferences,
  +showIconLabel: ShowIconLabelPreferences,
  +wheel: WheelPreferences,
  +workspaces: WorkspacePreferences,
  +wwwc: WwwcToolPreferences,
|};

export type ToolViewportClickInteraction = {|
  ...ToolInteraction,
  ...{|
    +__typename?: 'ToolViewportClickInteraction',
    +button: MouseButton,
    +cursorIcon: $ElementType<Scalars, 'String'>,
    +id: $ElementType<Scalars, 'ID'>,
    +maxConcurrentRequests?: ?$ElementType<Scalars, 'Int'>,
    +request: ToolInteractionRequest,
    +splitFlag?: ?$ElementType<Scalars, 'String'>,
    +type: ToolInteractionType,
  |},
|};

export type ToolVoiceCommandInteraction = {|
  ...ToolInteraction,
  ...{|
    +__typename?: 'ToolVoiceCommandInteraction',
    +id: $ElementType<Scalars, 'ID'>,
    +maxConcurrentRequests?: ?$ElementType<Scalars, 'Int'>,
    +request: ToolInteractionRequest,
    +splitFlag?: ?$ElementType<Scalars, 'String'>,
    +triggerPhrase: $ElementType<Scalars, 'String'>,
    +type: ToolInteractionType,
  |},
|};

export type UgcAnnotation = {|
  ...Annotation,
  ...{|
    +__typename?: 'UgcAnnotation',
    +aiAnnotationSmid?: ?$ElementType<Scalars, 'ID'>,
    +created?: ?$ElementType<Scalars, 'DateTime'>,
    +customLogic?: ?CustomAnnotationLogic,
    +data: $ElementType<Scalars, 'JSON'>,
    +owner?: ?User,
    +pixel_space_representation?: ?$ElementType<Scalars, 'JSON'>,
    +seriesSmid: $ElementType<Scalars, 'ID'>,
    +smid: $ElementType<Scalars, 'ID'>,
    +source: AnnotationSource,
    +type: AnnotationTypes,
    +updated?: ?$ElementType<Scalars, 'DateTime'>,
  |},
|};

export type UpdateAutoCorrectEntryInput = {|
  key: $ElementType<Scalars, 'String'>,
  value: $ElementType<Scalars, 'String'>,
|};

export type UpdateAutoCorrectEntryResponse = {|
  +__typename?: 'UpdateAutoCorrectEntryResponse',
  +entry?: ?AutoCorrectEntry,
|};

export type User = {|
  +__typename?: 'User',
  +anatomicNavigatorEnabled: $ElementType<Scalars, 'Boolean'>,
  +autoCorrect: $ReadOnlyArray<AutoCorrectEntry>,
  +clinic?: ?Clinic,
  +defaultAutoloadTemplate?: ?ReportTemplate,
  +email: $ElementType<Scalars, 'String'>,
  +firstName: $ElementType<Scalars, 'String'>,
  +id: $ElementType<Scalars, 'ID'>,
  +intercomHash?: ?$ElementType<Scalars, 'String'>,
  +lastName: $ElementType<Scalars, 'String'>,
  +lastViewedWorklists: $ReadOnlyArray<WorkListItem>,
  +nvoqCredentials: NvoqCredentials,
  +physicianId?: ?$ElementType<Scalars, 'String'>,
  +preferredWorkLocation: PreferredWorkLocation,
  +reporterSettings: ReporterSettings,
  +screenShareEnabled: $ElementType<Scalars, 'Boolean'>,
  +securitySettings: SecuritySettings,
  +stackInstancesByType: $ElementType<Scalars, 'Boolean'>,
  +stackedModalities: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  +toolPreferences: ToolPreferences,
  +viewerSettings: ViewerSettings,
  +worklistSettings: WorklistSettings,
|};

export type UserAnnotation = {|
  aiAnnotationSmid: $ElementType<Scalars, 'ID'>,
  data?: ?$ElementType<Scalars, 'JSON'>,
  pixel_space_representation?: ?$ElementType<Scalars, 'JSON'>,
  seriesSmid: $ElementType<Scalars, 'ID'>,
  type: $ElementType<Scalars, 'String'>,
  userId: $ElementType<Scalars, 'ID'>,
|};

export type Users = {|
  +__typename?: 'Users',
  +items: $ReadOnlyArray<LimitedUser>,
  +next?: ?$ElementType<Scalars, 'ID'>,
|};

export const VerificationStatusValues = Object.freeze({
  Unverified: 'UNVERIFIED',
  Verified: 'VERIFIED',
});

export type VerificationStatus = $Values<typeof VerificationStatusValues>;

export const ViewTypeValues = Object.freeze({
  AxialDre: 'AXIAL_DRE',
  CoronalDre: 'CORONAL_DRE',
  Pdf: 'PDF',
  SagittalDre: 'SAGITTAL_DRE',
  ThreeDDre: 'THREE_D_DRE',
  ThreeDMip: 'THREE_D_MIP',
  TwoDDre: 'TWO_D_DRE',
});

export type ViewType = $Values<typeof ViewTypeValues>;

export const ViewerRendererValues = Object.freeze({
  Vtk: 'VTK',
});

export type ViewerRenderer = $Values<typeof ViewerRendererValues>;

export type ViewerSettings = {|
  +__typename?: 'ViewerSettings',
  +absoluteScroll: $ElementType<Scalars, 'Boolean'>,
  +customDicomTags: $ReadOnlyArray<ModalityDicomTags>,
  +eraseAllToolScope?: ?EraseAllToolScope,
  +seriesSkipSettings?: ?SeriesSkipSettings,
  +showManualLocalizerLines: $ElementType<Scalars, 'Boolean'>,
  +virtualMonitorSplit?: ?VirtualMonitorSplit,
|};

export type ViewportConfiguration = {|
  +__typename?: 'ViewportConfiguration',
  +configuration: ViewportConfigurationData,
  +id: $ElementType<Scalars, 'ID'>,
|};

export type ViewportConfigurationData = {|
  +__typename?: 'ViewportConfigurationData',
  +comparisonIndex?: ?$ElementType<Scalars, 'Int'>,
  +series?: ?Series,
  +seriesDescriptions: $ReadOnlyArray<$ElementType<Scalars, 'String'>>,
  +seriesId?: ?$ElementType<Scalars, 'ID'>,
  +stack?: ?FrameStack,
  +study?: ?Study,
  +viewType?: ?ViewType,
|};

export type ViewportState = {|
  active: $ElementType<Scalars, 'Boolean'>,
  description: $ElementType<Scalars, 'String'>,
  displayConfiguration?: ?DisplayConfiguration,
  id: $ElementType<Scalars, 'ID'>,
  slices?: ?$ReadOnlyArray<Slice>,
|};

export const VirtualMonitorSplitValues = Object.freeze({
  Horizontal: 'horizontal',
  Vertical: 'vertical',
});

export type VirtualMonitorSplit = $Values<typeof VirtualMonitorSplitValues>;

export type VirtualMonitorSplitResponse = {|
  +__typename?: 'VirtualMonitorSplitResponse',
  +virtualMonitorSplit?: ?VirtualMonitorSplit,
|};

export type VocabEntry = {|
  +__typename?: 'VocabEntry',
  +id: $ElementType<Scalars, 'ID'>,
  +phrase: $ElementType<Scalars, 'String'>,
|};

export type VoiLutModule = {|
  +__typename?: 'VoiLutModule',
  +voiLutFunction?: ?$ElementType<Scalars, 'String'>,
  +voiLutSequence?: ?$ReadOnlyArray<?VoiLutSequence>,
  +windowCenter?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
  +windowCenterWidthExplanation?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
  +windowWidth?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>,
|};

export type VoiLutSequence = {|
  +__typename?: 'VoiLutSequence',
  +lutData: $ReadOnlyArray<$ElementType<Scalars, 'Int'>>,
  +lutDescriptor: $ElementType<Scalars, 'VoiLutDescriptor'>,
  +lutExplanation?: ?$ElementType<Scalars, 'String'>,
|};

export type WheelPreferences = {|
  +__typename?: 'WheelPreferences',
  +toolIds: $ReadOnlyArray<$ElementType<Scalars, 'ID'>>,
|};

export type WidgetConfiguration = {|
  +__typename?: 'WidgetConfiguration',
  +data?: ?$ElementType<Scalars, 'JSON'>,
  +dismissActions?: ?$ReadOnlyArray<WidgetDismissAction>,
  +id: $ElementType<Scalars, 'ID'>,
  +placement?: ?WidgetPlacement,
  +position?: ?ExtendedCoordinates,
  +title: $ElementType<Scalars, 'String'>,
  +type: WidgetType,
  +urlPath?: ?$ElementType<Scalars, 'String'>,
|};

export const WidgetDismissActionValues = Object.freeze({
  ClickOutside: 'CLICK_OUTSIDE',
  ToolButtonClick: 'TOOL_BUTTON_CLICK',
  ToolSwitch: 'TOOL_SWITCH',
});

export type WidgetDismissAction = $Values<typeof WidgetDismissActionValues>;

export type WidgetInstance = {|
  id: $ElementType<Scalars, 'ID'>,
  open: $ElementType<Scalars, 'Boolean'>,
|};

export const WidgetPlacementValues = Object.freeze({
  Bottom: 'BOTTOM',
  BottomEnd: 'BOTTOM_END',
  BottomStart: 'BOTTOM_START',
  Left: 'LEFT',
  LeftEnd: 'LEFT_END',
  LeftStart: 'LEFT_START',
  Right: 'RIGHT',
  RightEnd: 'RIGHT_END',
  RightStart: 'RIGHT_START',
  Top: 'TOP',
  TopEnd: 'TOP_END',
  TopStart: 'TOP_START',
});

export type WidgetPlacement = $Values<typeof WidgetPlacementValues>;

export type WidgetState = {|
  id: $ElementType<Scalars, 'ID'>,
  open: $ElementType<Scalars, 'Boolean'>,
  position: $ElementType<Scalars, 'Vector3'>,
  url: $ElementType<Scalars, 'String'>,
|};

export const WidgetTypeValues = Object.freeze({
  BackgroundScript: 'BACKGROUND_SCRIPT',
  Popover: 'POPOVER',
});

export type WidgetType = $Values<typeof WidgetTypeValues>;

export type Window = {|
  +__typename?: 'Window',
  +height: $ElementType<Scalars, 'Int'>,
  +id: $ElementType<Scalars, 'ID'>,
  +left: $ElementType<Scalars, 'Int'>,
  +screen: ScreenName,
  +state: WindowState,
  +top: $ElementType<Scalars, 'Int'>,
  +type: WindowType,
  +width: $ElementType<Scalars, 'Int'>,
|};

export type WindowPayload = {|
  height: $ElementType<Scalars, 'Int'>,
  id: $ElementType<Scalars, 'ID'>,
  left: $ElementType<Scalars, 'Int'>,
  screen: ScreenName,
  state: WindowState,
  top: $ElementType<Scalars, 'Int'>,
  type: WindowType,
  width: $ElementType<Scalars, 'Int'>,
|};

export const WindowStateValues = Object.freeze({
  Fullscreen: 'fullscreen',
  Maximized: 'maximized',
  Minimized: 'minimized',
  Normal: 'normal',
});

export type WindowState = $Values<typeof WindowStateValues>;

export const WindowTypeValues = Object.freeze({
  App: 'app',
  Normal: 'normal',
  Popup: 'popup',
});

export type WindowType = $Values<typeof WindowTypeValues>;

export type WorkListItem = {|
  +__typename?: 'WorkListItem',
  +accessionNumber?: ?$ElementType<Scalars, 'String'>,
  +bodyPart?: ?$ElementType<Scalars, 'String'>,
  +claimExpiration?: ?$ElementType<Scalars, 'DateTime'>,
  +claimedBy?: ?User,
  +created?: ?$ElementType<Scalars, 'DateTime'>,
  +customMergeFields: $ReadOnlyArray<CustomMergeField>,
  +customStatus?: ?WorklistCustomStatus,
  +date?: ?$ElementType<Scalars, 'DateTime'>,
  +dosage?: ?$ElementType<Scalars, 'String'>,
  +groupId?: ?$ElementType<Scalars, 'ID'>,
  +hasDraftReport: $ElementType<Scalars, 'Boolean'>,
  +isStat: $ElementType<Scalars, 'Boolean'>,
  +modality?: ?$ElementType<Scalars, 'String'>,
  +mrn?: ?$ElementType<Scalars, 'String'>,
  +patientAge?: ?$ElementType<Scalars, 'String'>,
  +patientDob?: ?$ElementType<Scalars, 'DateTime'>,
  +patientName?: ?$ElementType<Scalars, 'String'>,
  +patientSex?: ?$ElementType<Scalars, 'String'>,
  +patientType?: ?$ElementType<Scalars, 'String'>,
  +priority?: ?$ElementType<Scalars, 'String'>,
  +procedure?: ?Procedure,
  +referringPhysician?: ?$ElementType<Scalars, 'String'>,
  +report?: ?Report,
  +site?: ?$ElementType<Scalars, 'String'>,
  +smid: $ElementType<Scalars, 'ID'>,
  +status: WorkListItemStatus,
  +studies: $ReadOnlyArray<Study>,
  +studyDate?: ?$ElementType<Scalars, 'DateTime'>,
  +studyDescription?: ?$ElementType<Scalars, 'String'>,
  +studyReason?: ?$ElementType<Scalars, 'String'>,
  +submittedAt?: ?$ElementType<Scalars, 'DateTime'>,
  +summary?: ?WorkListItemSummary,
  +techNotes?: ?$ElementType<Scalars, 'String'>,
  +updated?: ?$ElementType<Scalars, 'DateTime'>,
|};

export type WorkListItemAuditLogItem = {|
  +__typename?: 'WorkListItemAuditLogItem',
  +actor?: ?WorkListItemAuditLogUser,
  +claimedBy?: ?WorkListItemAuditLogUser,
  +createdAt: $ElementType<Scalars, 'DateTime'>,
  +customStatus?: ?$ElementType<Scalars, 'String'>,
  +groupId?: ?$ElementType<Scalars, 'ID'>,
  +status?: ?$ElementType<Scalars, 'String'>,
|};

export type WorkListItemAuditLogUser = {|
  +__typename?: 'WorkListItemAuditLogUser',
  +firstName: $ElementType<Scalars, 'String'>,
  +lastName: $ElementType<Scalars, 'String'>,
|};

export const WorkListItemStatusValues = Object.freeze({
  Archive: 'ARCHIVE',
  InProgress: 'IN_PROGRESS',
  Pending: 'PENDING',
});

export type WorkListItemStatus = $Values<typeof WorkListItemStatusValues>;

export type WorkListItemSummary = {|
  +__typename?: 'WorkListItemSummary',
  +patientHistory: $ElementType<Scalars, 'String'>,
  +relevantFindings: $ElementType<Scalars, 'JSON'>,
|};

export type WorkListItems = {|
  ...List,
  ...{|
    +__typename?: 'WorkListItems',
    +cursor?: ?$ElementType<Scalars, 'ID'>,
    +filteredCount: $ElementType<Scalars, 'Int'>,
    +items: $ReadOnlyArray<WorkListItem>,
    +next?: ?$ElementType<Scalars, 'ID'>,
    +previous?: ?$ElementType<Scalars, 'ID'>,
    +totalCount: $ElementType<Scalars, 'Int'>,
  |},
|};

export type WorkListView = {|
  +__typename?: 'WorkListView',
  +name: $ElementType<Scalars, 'String'>,
  +smid: $ElementType<Scalars, 'ID'>,
|};

export type WorklistColumn = {|
  +__typename?: 'WorklistColumn',
  +enabled: $ElementType<Scalars, 'Boolean'>,
  +id: WorklistColumnId,
  +width: $ElementType<Scalars, 'Int'>,
|};

export const WorklistColumnIdValues = Object.freeze({
  AccessionNumber: 'accessionNumber',
  Actions: 'actions',
  Age: 'age',
  Alert: 'alert',
  BodyPart: 'bodyPart',
  Created: 'created',
  CustomStatus: 'customStatus',
  HasDraftReport: 'hasDraftReport',
  MatchType: 'matchType',
  Modality: 'modality',
  Mrn: 'mrn',
  PatientDob: 'patientDob',
  PatientName: 'patientName',
  PatientSex: 'patientSex',
  PatientType: 'patientType',
  Priority: 'priority',
  Radiologist: 'radiologist',
  ReferringPhysician: 'referringPhysician',
  SelectCheckbox: 'selectCheckbox',
  Site: 'site',
  Status: 'status',
  StudyDate: 'studyDate',
  StudyDescription: 'studyDescription',
  SubmittedAt: 'submittedAt',
});

export type WorklistColumnId = $Values<typeof WorklistColumnIdValues>;

export type WorklistColumnInput = {|
  enabled: $ElementType<Scalars, 'Boolean'>,
  id: WorklistColumnId,
  width: $ElementType<Scalars, 'Int'>,
|};

export type WorklistCustomStatus = {|
  +__typename?: 'WorklistCustomStatus',
  +name: $ElementType<Scalars, 'String'>,
  +smid: $ElementType<Scalars, 'ID'>,
|};

export type WorklistFilter = {|
  +__typename?: 'WorklistFilter',
  +key: $ElementType<Scalars, 'ID'>,
  +type: WorklistFilterType,
  +values: $ReadOnlyArray<WorklistFilterValue>,
|};

export type WorklistFilterInput = {|
  key: $ElementType<Scalars, 'ID'>,
  type: WorklistFilterType,
  values: $ReadOnlyArray<WorklistFilterValueInput>,
|};

export const WorklistFilterTypeValues = Object.freeze({
  Checkboxes: 'CHECKBOXES',
  Date: 'DATE',
  Daterange: 'DATERANGE',
  Search: 'SEARCH',
});

export type WorklistFilterType = $Values<typeof WorklistFilterTypeValues>;

export type WorklistFilterValue = {|
  +__typename?: 'WorklistFilterValue',
  +key: $ElementType<Scalars, 'ID'>,
  +value: $ElementType<Scalars, 'StringOrBooleanOrDate'>,
|};

export type WorklistFilterValueInput = {|
  key: $ElementType<Scalars, 'ID'>,
  value: $ElementType<Scalars, 'StringOrBooleanOrDate'>,
|};

export type WorklistSettings = {|
  +__typename?: 'WorklistSettings',
  +columns: $ReadOnlyArray<WorklistColumn>,
  +customWorklists?: ?$ReadOnlyArray<?CustomWorklist>,
  +lastUsedCustomWorklist?: ?CustomWorklist,
  +openPatientJacketOnRead: $ElementType<Scalars, 'Boolean'>,
  +settingsVersion?: ?$ElementType<Scalars, 'String'>,
|};

export type WorklistStudies = {|
  +__typename?: 'WorklistStudies',
  +cursor?: ?$ElementType<Scalars, 'ID'>,
  +items: $ReadOnlyArray<WorklistStudy>,
  +totalCount: $ElementType<Scalars, 'Int'>,
|};

export type WorklistStudy = {|
  +__typename?: 'WorklistStudy',
  +primary: $ElementType<Scalars, 'Boolean'>,
  +study: Study,
|};

export type WorkspacePreferences = {|
  +__typename?: 'WorkspacePreferences',
  +autoApply: $ElementType<Scalars, 'Boolean'>,
  +presets: $ReadOnlyArray<WorkspacePreset>,
|};

export type WorkspacePreset = {|
  +__typename?: 'WorkspacePreset',
  +createdAt: $ElementType<Scalars, 'DateTime'>,
  +instanceId: $ElementType<Scalars, 'ID'>,
  +name: $ElementType<Scalars, 'String'>,
  +smid: $ElementType<Scalars, 'ID'>,
  +updatedAt: $ElementType<Scalars, 'DateTime'>,
  +windows: $ReadOnlyArray<Window>,
|};

export type WorkspacePresetPayload = {|
  instanceId: $ElementType<Scalars, 'ID'>,
  name: $ElementType<Scalars, 'String'>,
  windows: $ReadOnlyArray<WindowPayload>,
|};

export type WorldCoordinates = {|
  +__typename?: 'WorldCoordinates',
  +worldSpace: $ElementType<Scalars, 'Vector3'>,
|};

export type WwcPresetDeleteResponse = {|
  +__typename?: 'WwcPresetDeleteResponse',
  +id: $ElementType<Scalars, 'ID'>,
|};

export type WwwcPreset = {|
  +__typename?: 'WwwcPreset',
  +id: $ElementType<Scalars, 'ID'>,
  +level: $ElementType<Scalars, 'Int'>,
  +name: $ElementType<Scalars, 'String'>,
  +shortcut: $ElementType<Scalars, 'String'>,
  +width: $ElementType<Scalars, 'Int'>,
|};

export type WwwcPresetPayload = {|
  level: $ElementType<Scalars, 'Int'>,
  name: $ElementType<Scalars, 'String'>,
  shortcut: $ElementType<Scalars, 'String'>,
  width: $ElementType<Scalars, 'Int'>,
|};

export type WwwcToolPreferences = {|
  +__typename?: 'WwwcToolPreferences',
  +invertDragDirection: $ElementType<Scalars, 'Boolean'>,
  +presets: $ReadOnlyArray<WwwcPreset>,
|};

export type PatientFieldsFragment = {
  +__typename?: 'Study',
  +patientSex?: ?string,
  +patientAge?: ?string,
  +patientName?: ?string,
  +patientDob?: ?Date,
  +modality: string,
  +bodyPart?: ?string,
  +mrn?: ?string,
  +studyDate?: ?Date,
  +laterality?: ?string,
};

export type AddendumFieldsFragment = {
  +__typename?: 'Addendum',
  +created: Date,
  +updated: Date,
  +submittedAt?: ?Date,
  +sentAt?: ?Date,
  +smid: string,
  +content:
    | { +__typename?: 'AddendumSironaSlate', +sironaSlate: $ReadOnlyArray<Json> }
    | { +__typename?: 'AddendumTextBlob', +textBlob: string },
  +owner?: ?{ +__typename?: 'Owner', +id: string, +lastName: string },
};

export type FrameDataFragment = {
  +__typename?: 'Frame',
  +smid: string,
  +sopInstanceUID: string,
  +sopClassUID: string,
  +colorChannels: number,
  +needsInversion: boolean,
  +imageNumber: number,
  +origin: [number, number, number],
  +spacing: [number, number, number],
  +size: $ReadOnlyArray<number>,
  +hasPixels: boolean,
  +validSpacing: boolean,
  +series: { +__typename?: 'Series', +smid: string },
  +direction: {
    +__typename?: 'FrameDirection',
    +rows: number,
    +columns: number,
    +data: [number, number, number, number, number, number, number, number, number],
    +validDirection: boolean,
  },
  +modules: {
    +__typename?: 'Modules',
    +cine?: ?{
      +__typename?: 'CineModule',
      +frameTimeVector: $ReadOnlyArray<number>,
      +preferredPlaybackSequencing: number,
    },
    +multiFrame?: ?{
      +__typename?: 'MultiFrameModule',
      +numberOfFrames: number,
      +frameIncrementPointer: $ReadOnlyArray<string>,
    },
    +regionCalibration?: ?{
      +__typename?: 'RegionCalibrationModule',
      +regions: $ReadOnlyArray<{
        +__typename?: 'Region',
        +spatialFormat: string,
        +dataType: string,
        +location: $ReadOnlyArray<number>,
        +physicalDelta: $ReadOnlyArray<number>,
        +physicalUnits: $ReadOnlyArray<string>,
        +flags: {
          +__typename?: 'RegionFlags',
          +highPriority: boolean,
          +scalingProtection: boolean,
        },
      }>,
    },
    +modalityLut?: ?{
      +__typename?: 'ModalityLutModule',
      +rescaleIntercept?: ?string,
      +rescaleSlope?: ?string,
      +rescaleType?: ?string,
      +modalityLutSequence?: ?$ReadOnlyArray<{
        +__typename?: 'ModalityLutSequence',
        +lutDescriptor: [number, number, number],
        +modalityLutType: string,
        +lutData: $ReadOnlyArray<number>,
      }>,
    },
  },
};

export type SeriesDataFragment = {
  +__typename?: 'Series',
  +smid: string,
  +description?: ?string,
  +seriesInstanceUID: string,
  +seriesDateTimeLocal?: ?DateTimeLocal,
  +frameOfReferenceUID?: ?string,
  +seriesNumber?: ?number,
  +is3Dable: boolean,
  +study: { +__typename?: 'Study', +smid: string },
  +imageRegistrations: $ReadOnlyArray<{
    +__typename?: 'ImageRegistration',
    +smid: string,
    +created: Date,
    +codeVersion: string,
    +modelName: string,
    +modelVersion: string,
    +seriesFixed: string,
    +seriesMoving: string,
    +dataType: string,
    +fixedFrameOfReferenceUid: string,
    +movingFrameOfReferenceUid: string,
    +transformMatrix: [
      [number, number, number, number],
      [number, number, number, number],
      [number, number, number, number],
      [number, number, number, number],
    ],
  }>,
};

export type StudyDataWithoutSeriesFragment = {
  +__typename?: 'Study',
  +smid: string,
  +created: Date,
  +updated: Date,
  +instanceUID: string,
  +description?: ?string,
  +hl7Order?: ?string,
  +patientSex?: ?string,
  +patientAge?: ?string,
  +patientName?: ?string,
  +patientDob?: ?Date,
  +modality: string,
  +bodyPart?: ?string,
  +mrn?: ?string,
  +studyDate?: ?Date,
  +laterality?: ?string,
  +provenanceData?: ?{ +__typename?: 'ProvenanceData', +matchType?: ?MatchType },
};

export type StackedFrameFragment = {
  +__typename?: 'SingleLayerStack',
  +smid: string,
  +type: string,
  +image: string,
  +is3Dable: boolean,
  +frames: $ReadOnlyArray<FrameDataFragment>,
  +series: { +__typename?: 'Series', +smid: string },
  +study: { +__typename?: 'Study', +smid: string },
  +supportedRenderers: { +__typename?: 'SupportedRenderers', +vtk: RendererSupport },
  +imageParams: {
    +__typename?: 'ImageParams',
    +origin: [number, number, number],
    +spacing: [number, number, number],
    +dimensions: [number, number, number],
    +extent: [number, number, number, number, number, number],
    +direction: [number, number, number, number, number, number, number, number, number],
    +isMultiAxes: boolean,
    +colorWindow?: ?number,
    +colorLevel?: ?number,
  },
};

export type LayeredStackFragment = {
  +__typename?: 'LayeredStack',
  +smid: string,
  +type: string,
  +study: { +__typename?: 'Study', +smid: string },
  +stackLayers: $ReadOnlyArray<{ +__typename?: 'Layer', +stackSmid: string, +index: number }>,
};

export type StudyDataFragment = {
  +__typename?: 'Study',
  +smid: string,
  +created: Date,
  +updated: Date,
  +instanceUID: string,
  +description?: ?string,
  +hl7Order?: ?string,
  +accessionNumber?: ?string,
  +seriesCount: number,
  +patientSex?: ?string,
  +patientAge?: ?string,
  +patientName?: ?string,
  +patientDob?: ?Date,
  +modality: string,
  +bodyPart?: ?string,
  +mrn?: ?string,
  +studyDate?: ?Date,
  +laterality?: ?string,
  +seriesList: $ReadOnlyArray<{
    +__typename?: 'Series',
    +smid: string,
    +description?: ?string,
    +seriesInstanceUID: string,
    +seriesDateTimeLocal?: ?DateTimeLocal,
    +frameOfReferenceUID?: ?string,
    +seriesNumber?: ?number,
    +is3Dable: boolean,
    +study: { +__typename?: 'Study', +smid: string },
    +imageRegistrations: $ReadOnlyArray<{
      +__typename?: 'ImageRegistration',
      +smid: string,
      +created: Date,
      +codeVersion: string,
      +modelName: string,
      +modelVersion: string,
      +seriesFixed: string,
      +seriesMoving: string,
      +dataType: string,
      +fixedFrameOfReferenceUid: string,
      +movingFrameOfReferenceUid: string,
      +transformMatrix: [
        [number, number, number, number],
        [number, number, number, number],
        [number, number, number, number],
        [number, number, number, number],
      ],
    }>,
  }>,
  +stackedFrames: $ReadOnlyArray<
    | {
        +__typename?: 'LayeredStack',
        +smid: string,
        +type: string,
        +study: { +__typename?: 'Study', +smid: string },
        +stackLayers: $ReadOnlyArray<{ +__typename?: 'Layer', +stackSmid: string, +index: number }>,
      }
    | {
        +__typename?: 'SingleLayerStack',
        +smid: string,
        +type: string,
        +image: string,
        +is3Dable: boolean,
        +frames: $ReadOnlyArray<FrameDataFragment>,
        +series: { +__typename?: 'Series', +smid: string },
        +study: { +__typename?: 'Study', +smid: string },
        +supportedRenderers: { +__typename?: 'SupportedRenderers', +vtk: RendererSupport },
        +imageParams: {
          +__typename?: 'ImageParams',
          +origin: [number, number, number],
          +spacing: [number, number, number],
          +dimensions: [number, number, number],
          +extent: [number, number, number, number, number, number],
          +direction: [number, number, number, number, number, number, number, number, number],
          +isMultiAxes: boolean,
          +colorWindow?: ?number,
          +colorLevel?: ?number,
        },
      },
  >,
};

export type TableWorkListItemFieldsFragment = {
  +__typename?: 'WorkListItem',
  +smid: string,
  +created?: ?Date,
  +updated?: ?Date,
  +studyDate?: ?Date,
  +submittedAt?: ?Date,
  +patientName?: ?string,
  +patientAge?: ?string,
  +patientSex?: ?string,
  +patientDob?: ?Date,
  +patientType?: ?string,
  +accessionNumber?: ?string,
  +bodyPart?: ?string,
  +modality?: ?string,
  +referringPhysician?: ?string,
  +site?: ?string,
  +status: WorkListItemStatus,
  +mrn?: ?string,
  +priority?: ?string,
  +studyDescription?: ?string,
  +dosage?: ?string,
  +studyReason?: ?string,
  +techNotes?: ?string,
  +isStat: boolean,
  +groupId?: ?string,
  +hasDraftReport: boolean,
  +studies: $ReadOnlyArray<{ +__typename?: 'Study', +smid: string }>,
  +claimedBy?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
  +report?: ?{
    +__typename?: 'Report',
    +smid: string,
    +submittedAt?: ?Date,
    +owner?: ?{ +__typename?: 'Owner', +firstName: string, +lastName: string },
  },
  +customStatus?: ?{ +__typename?: 'WorklistCustomStatus', +smid: string, +name: string },
};

export type WorkListItemAnalyticsFieldsFragment = {
  +__typename?: 'WorkListItem',
  +smid: string,
  +modality?: ?string,
  +bodyPart?: ?string,
  +studyDescription?: ?string,
};

export type WorkListItemFieldsFragment = {
  +__typename?: 'WorkListItem',
  +smid: string,
  +mrn?: ?string,
  +status: WorkListItemStatus,
  +dosage?: ?string,
  +studyReason?: ?string,
  +studyDescription?: ?string,
  +studyDate?: ?Date,
  +referringPhysician?: ?string,
  +accessionNumber?: ?string,
  +techNotes?: ?string,
  +patientName?: ?string,
  +patientDob?: ?Date,
  +patientAge?: ?string,
  +patientSex?: ?string,
  +date?: ?Date,
  +groupId?: ?string,
  +modality?: ?string,
  +hasDraftReport: boolean,
  +procedure?: ?{ +__typename?: 'Procedure', +smid: string, +description: string, +code: string },
  +customMergeFields: $ReadOnlyArray<{
    +__typename?: 'CustomMergeField',
    +key: string,
    +value: string,
  }>,
  +claimedBy?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
  +report?: ?{
    +__typename?: 'Report',
    +smid: string,
    +submittedAt?: ?Date,
    +updated: Date,
    +owner?: ?{ +__typename?: 'Owner', +firstName: string, +lastName: string },
  },
  +studies: $ReadOnlyArray<{
    +__typename?: 'Study',
    +smid: string,
    +bodyPart?: ?string,
    +modality: string,
    +description?: ?string,
    +laterality?: ?string,
    +studyDate?: ?Date,
  }>,
  +customStatus?: ?{ +__typename?: 'WorklistCustomStatus', +smid: string, +name: string },
};

export type MacroFieldsFragment = {
  +__typename?: 'Macro',
  +smid: string,
  +name: string,
  +triggerPhrase: string,
  +text: $ReadOnlyArray<Json>,
  +created: Date,
  +updated?: ?Date,
  +procedures?: ?$ReadOnlyArray<{
    +__typename?: 'Procedure',
    +smid: string,
    +description: string,
    +code: string,
  }>,
  +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
  +picklists: $ReadOnlyArray<{
    +__typename?: 'ReportPicklist',
    +id: string,
    +options: $ReadOnlyArray<{
      +__typename?: 'ReportPicklistOption',
      +id: string,
      +name?: ?string,
      +text: $ReadOnlyArray<Json>,
      +default: boolean,
    }>,
  }>,
};

export type ReportFieldsFragment = {
  +__typename?: 'Report',
  +smid: string,
  +submittedAt?: ?Date,
  +sections: Json,
  +content?: ?Json,
  +sectionsLastUpdated?: ?Date,
  +macros: $ReadOnlyArray<{
    +__typename?: 'Macro',
    +smid: string,
    +name: string,
    +triggerPhrase: string,
    +text: $ReadOnlyArray<Json>,
    +created: Date,
    +updated?: ?Date,
    +procedures?: ?$ReadOnlyArray<{
      +__typename?: 'Procedure',
      +smid: string,
      +description: string,
      +code: string,
    }>,
    +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
    +picklists: $ReadOnlyArray<{
      +__typename?: 'ReportPicklist',
      +id: string,
      +options: $ReadOnlyArray<{
        +__typename?: 'ReportPicklistOption',
        +id: string,
        +name?: ?string,
        +text: $ReadOnlyArray<Json>,
        +default: boolean,
      }>,
    }>,
  }>,
};

export type WorkspacePresetsFieldsFragment = {
  +__typename?: 'WorkspacePreset',
  +smid: string,
  +instanceId: string,
  +name: string,
  +createdAt: Date,
  +updatedAt: Date,
  +windows: $ReadOnlyArray<{
    +__typename?: 'Window',
    +id: string,
    +width: number,
    +height: number,
    +left: number,
    +top: number,
    +screen: ScreenName,
    +type: WindowType,
    +state: WindowState,
  }>,
};

export type AutoCorrectEntryFieldsFragment = {
  +__typename?: 'AutoCorrectEntry',
  +id: string,
  +key: string,
  +value: string,
};

export type VocabEntryFieldsFragment = { +__typename?: 'VocabEntry', +id: string, +phrase: string };

export type ReportTemplateFieldsFragment = {
  +__typename?: 'ReportTemplate',
  +id: string,
  +name: string,
  +triggerPhrase: string,
  +created: Date,
  +content: Json,
  +sections: $ReadOnlyArray<Json>,
  +picklists: $ReadOnlyArray<{
    +__typename?: 'ReportPicklist',
    +id: string,
    +options: $ReadOnlyArray<{
      +__typename?: 'ReportPicklistOption',
      +id: string,
      +name?: ?string,
      +text: $ReadOnlyArray<Json>,
      +default: boolean,
    }>,
  }>,
  +macros: $ReadOnlyArray<{
    +__typename?: 'Macro',
    +smid: string,
    +name: string,
    +triggerPhrase: string,
    +text: $ReadOnlyArray<Json>,
    +created: Date,
    +updated?: ?Date,
    +procedures?: ?$ReadOnlyArray<{
      +__typename?: 'Procedure',
      +smid: string,
      +description: string,
      +code: string,
    }>,
    +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
    +picklists: $ReadOnlyArray<{
      +__typename?: 'ReportPicklist',
      +id: string,
      +options: $ReadOnlyArray<{
        +__typename?: 'ReportPicklistOption',
        +id: string,
        +name?: ?string,
        +text: $ReadOnlyArray<Json>,
        +default: boolean,
      }>,
    }>,
  }>,
  +procedureReferences?: ?$ReadOnlyArray<{
    +__typename?: 'ProcedureReference',
    +smid: string,
    +description?: ?string,
    +code?: ?string,
    +autoload: boolean,
  }>,
};

export type GetReporterSettingsFragment = {
  +__typename?: 'ReporterSettings',
  +triggerWord: string,
  +aiMode: {
    +__typename?: 'AIModeSettings',
    +findingsMapperEnabled: boolean,
    +formatImpressionGenerationAsList: boolean,
    +onDemandImpressionGeneration: boolean,
  },
  +mergeFieldsSettings: {
    +__typename?: 'MergeFieldsSettings',
    +ignoreNavigation: boolean,
    +ignoreDefaultSelection: boolean,
  },
  +styles: {
    +__typename?: 'Styles',
    +bodyStyle: {
      +__typename?: 'TextStyle',
      +fontSize?: ?number,
      +fontFamily?: ?string,
      +textTransform?: ?TextTransform,
      +textDecoration?: ?{
        +__typename?: 'TextDecoration',
        +isBold?: ?boolean,
        +isItalic?: ?boolean,
        +isUnderline?: ?boolean,
      },
    },
    +headingStyles: $ReadOnlyArray<{
      +__typename?: 'HeadingStyle',
      +level: HeadingLevel,
      +textStyle: {
        +__typename?: 'TextStyle',
        +fontSize?: ?number,
        +textTransform?: ?TextTransform,
        +textDecoration?: ?{
          +__typename?: 'TextDecoration',
          +isBold?: ?boolean,
          +isItalic?: ?boolean,
          +isUnderline?: ?boolean,
        },
      },
    }>,
  },
  +cursorStyle: { +__typename?: 'CursorStyle', +color: string },
  +lineIndicator: {
    +__typename?: 'LineIndicator',
    +enabled: boolean,
    +placement: LineIndicatorPlacement,
    +variant: LineIndicatorVariant,
  },
  +impressionGenerator: {
    +__typename?: 'ImpressionGenerator',
    +enabled: boolean,
    +automatic: boolean,
    +modalities: $ReadOnlyArray<string>,
  },
  +autoFillComparison: { +__typename?: 'AutoFillComparison', +enabled: boolean },
};

export type GetToolPreferencesQueryVariables = {};

export type GetToolPreferencesQuery = {
  +__typename?: 'Query',
  +toolPreferences: {
    +__typename?: 'ToolPreferences',
    +invertScroll: boolean,
    +mouse: { +__typename?: 'MousePreferences', +left: string, +middle: string, +right: string },
    +regularScroll: { +__typename?: 'RegularScrollPreferences', +sensitivity: number },
    +fastScroll: { +__typename?: 'FastScrollPreferences', +sensitivity: number },
    +fineScroll: { +__typename?: 'FineScrollPreferences', +enabled: boolean },
    +wheel: { +__typename?: 'WheelPreferences', +toolIds: $ReadOnlyArray<string> },
    +keyboard: {
      +__typename?: 'KeyboardPreferences',
      +shortcuts: $ReadOnlyArray<{
        +__typename?: 'Shortcut',
        +id: string,
        +key?: ?string,
        +interactionId?: ?string,
      }>,
    },
    +bar: { +__typename?: 'BarPreferences', +toolIds: $ReadOnlyArray<string> },
    +wwwc: {
      +__typename?: 'WwwcToolPreferences',
      +invertDragDirection: boolean,
      +presets: $ReadOnlyArray<{
        +__typename?: 'WwwcPreset',
        +id: string,
        +name: string,
        +width: number,
        +level: number,
        +shortcut: string,
      }>,
    },
    +dictaphone: {
      +__typename?: 'DictaphonePreferences',
      +deviceLabel: string,
      +recordWhilePressed: boolean,
      +showPicklistOptionsInEditor: boolean,
      +keyMapping: $ReadOnlyArray<{
        +__typename?: 'DictaphoneKey',
        +id: GamepadActionId,
        +key: string,
      }>,
    },
    +workspaces: {
      +__typename?: 'WorkspacePreferences',
      +autoApply: boolean,
      +presets: $ReadOnlyArray<{
        +__typename?: 'WorkspacePreset',
        +smid: string,
        +instanceId: string,
        +name: string,
        +createdAt: Date,
        +updatedAt: Date,
        +windows: $ReadOnlyArray<{
          +__typename?: 'Window',
          +id: string,
          +width: number,
          +height: number,
          +left: number,
          +top: number,
          +screen: ScreenName,
          +type: WindowType,
          +state: WindowState,
        }>,
      }>,
    },
    +resetToolOnScroll: { +__typename?: 'ResetToolOnScrollPreferences', +enabled: boolean },
    +mirrorViewerLayouts: { +__typename?: 'MirrorViewerLayoutsPreferences', +enabled: boolean },
    +showIconLabel: { +__typename?: 'ShowIconLabelPreferences', +enabled: boolean },
  },
};

export type GetDefaultAutoloadTemplateFragmentFragment = {
  +__typename?: 'User',
  +id: string,
  +defaultAutoloadTemplate?: ?{
    +__typename?: 'ReportTemplate',
    +id: string,
    +name: string,
    +triggerPhrase: string,
    +created: Date,
    +content: Json,
    +sections: $ReadOnlyArray<Json>,
    +picklists: $ReadOnlyArray<{
      +__typename?: 'ReportPicklist',
      +id: string,
      +options: $ReadOnlyArray<{
        +__typename?: 'ReportPicklistOption',
        +id: string,
        +name?: ?string,
        +text: $ReadOnlyArray<Json>,
        +default: boolean,
      }>,
    }>,
    +macros: $ReadOnlyArray<{
      +__typename?: 'Macro',
      +smid: string,
      +name: string,
      +triggerPhrase: string,
      +text: $ReadOnlyArray<Json>,
      +created: Date,
      +updated?: ?Date,
      +procedures?: ?$ReadOnlyArray<{
        +__typename?: 'Procedure',
        +smid: string,
        +description: string,
        +code: string,
      }>,
      +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
      +picklists: $ReadOnlyArray<{
        +__typename?: 'ReportPicklist',
        +id: string,
        +options: $ReadOnlyArray<{
          +__typename?: 'ReportPicklistOption',
          +id: string,
          +name?: ?string,
          +text: $ReadOnlyArray<Json>,
          +default: boolean,
        }>,
      }>,
    }>,
    +procedureReferences?: ?$ReadOnlyArray<{
      +__typename?: 'ProcedureReference',
      +smid: string,
      +description?: ?string,
      +code?: ?string,
      +autoload: boolean,
    }>,
  },
};

export type GetMeFragmentFragment = {
  +__typename?: 'User',
  +id: string,
  +firstName: string,
  +lastName: string,
  +email: string,
  +physicianId?: ?string,
  +anatomicNavigatorEnabled: boolean,
  +stackInstancesByType: boolean,
  +stackedModalities: $ReadOnlyArray<string>,
  +screenShareEnabled: boolean,
  +nvoqCredentials: { +__typename?: 'NvoqCredentials', +username: string, +password: string },
  +preferredWorkLocation: {
    +__typename?: 'PreferredWorkLocation',
    +hospital: boolean,
    +home: boolean,
  },
  +viewerSettings: {
    +__typename?: 'ViewerSettings',
    +showManualLocalizerLines: boolean,
    +virtualMonitorSplit?: ?VirtualMonitorSplit,
    +eraseAllToolScope?: ?EraseAllToolScope,
    +customDicomTags: $ReadOnlyArray<{
      +__typename?: 'ModalityDicomTags',
      +modality: string,
      +modalityDicomTags: $ReadOnlyArray<{
        +__typename?: 'DicomTagRenderInfo',
        +id: string,
        +tagId: string,
        +enabled: boolean,
        +label: string,
      }>,
    }>,
    +seriesSkipSettings?: ?{
      +__typename?: 'SeriesSkipSettings',
      +amount?: ?number,
      +keepPreviousWhenAvailable: boolean,
    },
  },
  +securitySettings: {
    +__typename?: 'SecuritySettings',
    +multiFactorAuth: { +__typename?: 'MultiFactorAuth', +enabled: boolean, +linked: boolean },
  },
  +worklistSettings: {
    +__typename?: 'WorklistSettings',
    +openPatientJacketOnRead: boolean,
    +columns: $ReadOnlyArray<{
      +__typename?: 'WorklistColumn',
      +id: WorklistColumnId,
      +width: number,
      +enabled: boolean,
    }>,
  },
  +reporterSettings: {
    +__typename?: 'ReporterSettings',
    +triggerWord: string,
    +aiMode: {
      +__typename?: 'AIModeSettings',
      +findingsMapperEnabled: boolean,
      +formatImpressionGenerationAsList: boolean,
      +onDemandImpressionGeneration: boolean,
    },
    +mergeFieldsSettings: {
      +__typename?: 'MergeFieldsSettings',
      +ignoreNavigation: boolean,
      +ignoreDefaultSelection: boolean,
    },
    +styles: {
      +__typename?: 'Styles',
      +bodyStyle: {
        +__typename?: 'TextStyle',
        +fontSize?: ?number,
        +fontFamily?: ?string,
        +textTransform?: ?TextTransform,
        +textDecoration?: ?{
          +__typename?: 'TextDecoration',
          +isBold?: ?boolean,
          +isItalic?: ?boolean,
          +isUnderline?: ?boolean,
        },
      },
      +headingStyles: $ReadOnlyArray<{
        +__typename?: 'HeadingStyle',
        +level: HeadingLevel,
        +textStyle: {
          +__typename?: 'TextStyle',
          +fontSize?: ?number,
          +textTransform?: ?TextTransform,
          +textDecoration?: ?{
            +__typename?: 'TextDecoration',
            +isBold?: ?boolean,
            +isItalic?: ?boolean,
            +isUnderline?: ?boolean,
          },
        },
      }>,
    },
    +cursorStyle: { +__typename?: 'CursorStyle', +color: string },
    +lineIndicator: {
      +__typename?: 'LineIndicator',
      +enabled: boolean,
      +placement: LineIndicatorPlacement,
      +variant: LineIndicatorVariant,
    },
    +impressionGenerator: {
      +__typename?: 'ImpressionGenerator',
      +enabled: boolean,
      +automatic: boolean,
      +modalities: $ReadOnlyArray<string>,
    },
    +autoFillComparison: { +__typename?: 'AutoFillComparison', +enabled: boolean },
  },
  +clinic?: ?{ +__typename?: 'Clinic', +smid: string, +name: string },
  +autoCorrect: $ReadOnlyArray<{
    +__typename?: 'AutoCorrectEntry',
    +id: string,
    +key: string,
    +value: string,
  }>,
};

export type GetUsersQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'ID'>,
  search?: ?$ElementType<Scalars, 'String'>,
  hasMacros?: ?$ElementType<Scalars, 'Boolean'>,
  hasTemplates?: ?$ElementType<Scalars, 'Boolean'>,
};

export type GetUsersQuery = {
  +__typename?: 'Query',
  +users: {
    +__typename?: 'Users',
    +next?: ?string,
    +items: $ReadOnlyArray<{
      +__typename?: 'LimitedUser',
      +firstName: string,
      +lastName: string,
      +email: string,
      +id: string,
    }>,
  },
};

export type GetMeQueryVariables = {};

export type GetMeQuery = {
  +__typename?: 'Query',
  +me: {
    +__typename?: 'User',
    +id: string,
    +firstName: string,
    +lastName: string,
    +email: string,
    +physicianId?: ?string,
    +anatomicNavigatorEnabled: boolean,
    +stackInstancesByType: boolean,
    +stackedModalities: $ReadOnlyArray<string>,
    +screenShareEnabled: boolean,
    +nvoqCredentials: { +__typename?: 'NvoqCredentials', +username: string, +password: string },
    +preferredWorkLocation: {
      +__typename?: 'PreferredWorkLocation',
      +hospital: boolean,
      +home: boolean,
    },
    +viewerSettings: {
      +__typename?: 'ViewerSettings',
      +showManualLocalizerLines: boolean,
      +virtualMonitorSplit?: ?VirtualMonitorSplit,
      +eraseAllToolScope?: ?EraseAllToolScope,
      +absoluteScroll: boolean,
      +customDicomTags: $ReadOnlyArray<{
        +__typename?: 'ModalityDicomTags',
        +modality: string,
        +modalityDicomTags: $ReadOnlyArray<{
          +__typename?: 'DicomTagRenderInfo',
          +id: string,
          +tagId: string,
          +enabled: boolean,
          +label: string,
        }>,
      }>,
      +seriesSkipSettings?: ?{
        +__typename?: 'SeriesSkipSettings',
        +amount?: ?number,
        +keepPreviousWhenAvailable: boolean,
      },
    },
    +securitySettings: {
      +__typename?: 'SecuritySettings',
      +multiFactorAuth: { +__typename?: 'MultiFactorAuth', +enabled: boolean, +linked: boolean },
    },
    +worklistSettings: {
      +__typename?: 'WorklistSettings',
      +openPatientJacketOnRead: boolean,
      +columns: $ReadOnlyArray<{
        +__typename?: 'WorklistColumn',
        +id: WorklistColumnId,
        +width: number,
        +enabled: boolean,
      }>,
    },
    +reporterSettings: {
      +__typename?: 'ReporterSettings',
      +triggerWord: string,
      +aiMode: {
        +__typename?: 'AIModeSettings',
        +findingsMapperEnabled: boolean,
        +formatImpressionGenerationAsList: boolean,
        +onDemandImpressionGeneration: boolean,
      },
      +mergeFieldsSettings: {
        +__typename?: 'MergeFieldsSettings',
        +ignoreNavigation: boolean,
        +ignoreDefaultSelection: boolean,
      },
      +styles: {
        +__typename?: 'Styles',
        +bodyStyle: {
          +__typename?: 'TextStyle',
          +fontSize?: ?number,
          +fontFamily?: ?string,
          +textTransform?: ?TextTransform,
          +textDecoration?: ?{
            +__typename?: 'TextDecoration',
            +isBold?: ?boolean,
            +isItalic?: ?boolean,
            +isUnderline?: ?boolean,
          },
        },
        +headingStyles: $ReadOnlyArray<{
          +__typename?: 'HeadingStyle',
          +level: HeadingLevel,
          +textStyle: {
            +__typename?: 'TextStyle',
            +fontSize?: ?number,
            +textTransform?: ?TextTransform,
            +textDecoration?: ?{
              +__typename?: 'TextDecoration',
              +isBold?: ?boolean,
              +isItalic?: ?boolean,
              +isUnderline?: ?boolean,
            },
          },
        }>,
      },
      +cursorStyle: { +__typename?: 'CursorStyle', +color: string },
      +lineIndicator: {
        +__typename?: 'LineIndicator',
        +enabled: boolean,
        +placement: LineIndicatorPlacement,
        +variant: LineIndicatorVariant,
      },
      +impressionGenerator: {
        +__typename?: 'ImpressionGenerator',
        +enabled: boolean,
        +automatic: boolean,
        +modalities: $ReadOnlyArray<string>,
      },
      +autoFillComparison: { +__typename?: 'AutoFillComparison', +enabled: boolean },
    },
    +clinic?: ?{ +__typename?: 'Clinic', +smid: string, +name: string },
    +autoCorrect: $ReadOnlyArray<{
      +__typename?: 'AutoCorrectEntry',
      +id: string,
      +key: string,
      +value: string,
    }>,
    +defaultAutoloadTemplate?: ?{
      +__typename?: 'ReportTemplate',
      +id: string,
      +name: string,
      +triggerPhrase: string,
      +created: Date,
      +content: Json,
      +sections: $ReadOnlyArray<Json>,
      +picklists: $ReadOnlyArray<{
        +__typename?: 'ReportPicklist',
        +id: string,
        +options: $ReadOnlyArray<{
          +__typename?: 'ReportPicklistOption',
          +id: string,
          +name?: ?string,
          +text: $ReadOnlyArray<Json>,
          +default: boolean,
        }>,
      }>,
      +macros: $ReadOnlyArray<{
        +__typename?: 'Macro',
        +smid: string,
        +name: string,
        +triggerPhrase: string,
        +text: $ReadOnlyArray<Json>,
        +created: Date,
        +updated?: ?Date,
        +procedures?: ?$ReadOnlyArray<{
          +__typename?: 'Procedure',
          +smid: string,
          +description: string,
          +code: string,
        }>,
        +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
        +picklists: $ReadOnlyArray<{
          +__typename?: 'ReportPicklist',
          +id: string,
          +options: $ReadOnlyArray<{
            +__typename?: 'ReportPicklistOption',
            +id: string,
            +name?: ?string,
            +text: $ReadOnlyArray<Json>,
            +default: boolean,
          }>,
        }>,
      }>,
      +procedureReferences?: ?$ReadOnlyArray<{
        +__typename?: 'ProcedureReference',
        +smid: string,
        +description?: ?string,
        +code?: ?string,
        +autoload: boolean,
      }>,
    },
  },
};

export type AuthenticateMutationVariables = {
  email: $ElementType<Scalars, 'String'>,
  password: $ElementType<Scalars, 'String'>,
};

export type AuthenticateMutation = {
  +__typename?: 'Mutation',
  +authenticate?:
    | ?{ +__typename?: 'MFARequired', +mfaSessionId: string }
    | {
        +__typename?: 'User',
        +id: string,
        +firstName: string,
        +lastName: string,
        +email: string,
        +physicianId?: ?string,
        +anatomicNavigatorEnabled: boolean,
        +stackInstancesByType: boolean,
        +stackedModalities: $ReadOnlyArray<string>,
        +screenShareEnabled: boolean,
        +nvoqCredentials: { +__typename?: 'NvoqCredentials', +username: string, +password: string },
        +preferredWorkLocation: {
          +__typename?: 'PreferredWorkLocation',
          +hospital: boolean,
          +home: boolean,
        },
        +viewerSettings: {
          +__typename?: 'ViewerSettings',
          +showManualLocalizerLines: boolean,
          +virtualMonitorSplit?: ?VirtualMonitorSplit,
          +eraseAllToolScope?: ?EraseAllToolScope,
          +customDicomTags: $ReadOnlyArray<{
            +__typename?: 'ModalityDicomTags',
            +modality: string,
            +modalityDicomTags: $ReadOnlyArray<{
              +__typename?: 'DicomTagRenderInfo',
              +id: string,
              +tagId: string,
              +enabled: boolean,
              +label: string,
            }>,
          }>,
          +seriesSkipSettings?: ?{
            +__typename?: 'SeriesSkipSettings',
            +amount?: ?number,
            +keepPreviousWhenAvailable: boolean,
          },
        },
        +securitySettings: {
          +__typename?: 'SecuritySettings',
          +multiFactorAuth: {
            +__typename?: 'MultiFactorAuth',
            +enabled: boolean,
            +linked: boolean,
          },
        },
        +worklistSettings: {
          +__typename?: 'WorklistSettings',
          +openPatientJacketOnRead: boolean,
          +columns: $ReadOnlyArray<{
            +__typename?: 'WorklistColumn',
            +id: WorklistColumnId,
            +width: number,
            +enabled: boolean,
          }>,
        },
        +reporterSettings: {
          +__typename?: 'ReporterSettings',
          +triggerWord: string,
          +aiMode: {
            +__typename?: 'AIModeSettings',
            +findingsMapperEnabled: boolean,
            +formatImpressionGenerationAsList: boolean,
            +onDemandImpressionGeneration: boolean,
          },
          +mergeFieldsSettings: {
            +__typename?: 'MergeFieldsSettings',
            +ignoreNavigation: boolean,
            +ignoreDefaultSelection: boolean,
          },
          +styles: {
            +__typename?: 'Styles',
            +bodyStyle: {
              +__typename?: 'TextStyle',
              +fontSize?: ?number,
              +fontFamily?: ?string,
              +textTransform?: ?TextTransform,
              +textDecoration?: ?{
                +__typename?: 'TextDecoration',
                +isBold?: ?boolean,
                +isItalic?: ?boolean,
                +isUnderline?: ?boolean,
              },
            },
            +headingStyles: $ReadOnlyArray<{
              +__typename?: 'HeadingStyle',
              +level: HeadingLevel,
              +textStyle: {
                +__typename?: 'TextStyle',
                +fontSize?: ?number,
                +textTransform?: ?TextTransform,
                +textDecoration?: ?{
                  +__typename?: 'TextDecoration',
                  +isBold?: ?boolean,
                  +isItalic?: ?boolean,
                  +isUnderline?: ?boolean,
                },
              },
            }>,
          },
          +cursorStyle: { +__typename?: 'CursorStyle', +color: string },
          +lineIndicator: {
            +__typename?: 'LineIndicator',
            +enabled: boolean,
            +placement: LineIndicatorPlacement,
            +variant: LineIndicatorVariant,
          },
          +impressionGenerator: {
            +__typename?: 'ImpressionGenerator',
            +enabled: boolean,
            +automatic: boolean,
            +modalities: $ReadOnlyArray<string>,
          },
          +autoFillComparison: { +__typename?: 'AutoFillComparison', +enabled: boolean },
        },
        +clinic?: ?{ +__typename?: 'Clinic', +smid: string, +name: string },
        +autoCorrect: $ReadOnlyArray<{
          +__typename?: 'AutoCorrectEntry',
          +id: string,
          +key: string,
          +value: string,
        }>,
      },
};

export type AuthenticateMfaMutationVariables = {
  email: $ElementType<Scalars, 'String'>,
  totp: $ElementType<Scalars, 'String'>,
  mfaSessionId: $ElementType<Scalars, 'String'>,
};

export type AuthenticateMfaMutation = {
  +__typename?: 'Mutation',
  +authenticateMFA?: ?{
    +__typename?: 'User',
    +id: string,
    +firstName: string,
    +lastName: string,
    +email: string,
    +physicianId?: ?string,
    +anatomicNavigatorEnabled: boolean,
    +stackInstancesByType: boolean,
    +stackedModalities: $ReadOnlyArray<string>,
    +screenShareEnabled: boolean,
    +nvoqCredentials: { +__typename?: 'NvoqCredentials', +username: string, +password: string },
    +preferredWorkLocation: {
      +__typename?: 'PreferredWorkLocation',
      +hospital: boolean,
      +home: boolean,
    },
    +viewerSettings: {
      +__typename?: 'ViewerSettings',
      +showManualLocalizerLines: boolean,
      +virtualMonitorSplit?: ?VirtualMonitorSplit,
      +eraseAllToolScope?: ?EraseAllToolScope,
      +customDicomTags: $ReadOnlyArray<{
        +__typename?: 'ModalityDicomTags',
        +modality: string,
        +modalityDicomTags: $ReadOnlyArray<{
          +__typename?: 'DicomTagRenderInfo',
          +id: string,
          +tagId: string,
          +enabled: boolean,
          +label: string,
        }>,
      }>,
      +seriesSkipSettings?: ?{
        +__typename?: 'SeriesSkipSettings',
        +amount?: ?number,
        +keepPreviousWhenAvailable: boolean,
      },
    },
    +securitySettings: {
      +__typename?: 'SecuritySettings',
      +multiFactorAuth: { +__typename?: 'MultiFactorAuth', +enabled: boolean, +linked: boolean },
    },
    +worklistSettings: {
      +__typename?: 'WorklistSettings',
      +openPatientJacketOnRead: boolean,
      +columns: $ReadOnlyArray<{
        +__typename?: 'WorklistColumn',
        +id: WorklistColumnId,
        +width: number,
        +enabled: boolean,
      }>,
    },
    +reporterSettings: {
      +__typename?: 'ReporterSettings',
      +triggerWord: string,
      +aiMode: {
        +__typename?: 'AIModeSettings',
        +findingsMapperEnabled: boolean,
        +formatImpressionGenerationAsList: boolean,
        +onDemandImpressionGeneration: boolean,
      },
      +mergeFieldsSettings: {
        +__typename?: 'MergeFieldsSettings',
        +ignoreNavigation: boolean,
        +ignoreDefaultSelection: boolean,
      },
      +styles: {
        +__typename?: 'Styles',
        +bodyStyle: {
          +__typename?: 'TextStyle',
          +fontSize?: ?number,
          +fontFamily?: ?string,
          +textTransform?: ?TextTransform,
          +textDecoration?: ?{
            +__typename?: 'TextDecoration',
            +isBold?: ?boolean,
            +isItalic?: ?boolean,
            +isUnderline?: ?boolean,
          },
        },
        +headingStyles: $ReadOnlyArray<{
          +__typename?: 'HeadingStyle',
          +level: HeadingLevel,
          +textStyle: {
            +__typename?: 'TextStyle',
            +fontSize?: ?number,
            +textTransform?: ?TextTransform,
            +textDecoration?: ?{
              +__typename?: 'TextDecoration',
              +isBold?: ?boolean,
              +isItalic?: ?boolean,
              +isUnderline?: ?boolean,
            },
          },
        }>,
      },
      +cursorStyle: { +__typename?: 'CursorStyle', +color: string },
      +lineIndicator: {
        +__typename?: 'LineIndicator',
        +enabled: boolean,
        +placement: LineIndicatorPlacement,
        +variant: LineIndicatorVariant,
      },
      +impressionGenerator: {
        +__typename?: 'ImpressionGenerator',
        +enabled: boolean,
        +automatic: boolean,
        +modalities: $ReadOnlyArray<string>,
      },
      +autoFillComparison: { +__typename?: 'AutoFillComparison', +enabled: boolean },
    },
    +clinic?: ?{ +__typename?: 'Clinic', +smid: string, +name: string },
    +autoCorrect: $ReadOnlyArray<{
      +__typename?: 'AutoCorrectEntry',
      +id: string,
      +key: string,
      +value: string,
    }>,
  },
};

export type UnauthenticateMutationVariables = {};

export type UnauthenticateMutation = { +__typename?: 'Mutation', +unauthenticate: boolean };

export type AssignDefaultAutoloadTemplateMutationVariables = {
  input: AssignDefaultAutoloadTemplateInput,
};

export type AssignDefaultAutoloadTemplateMutation = {
  +__typename?: 'Mutation',
  +assignDefaultAutoloadTemplate: {
    +__typename?: 'AssignDefaultAutoloadTemplatePayload',
    +me: {
      +__typename?: 'User',
      +id: string,
      +defaultAutoloadTemplate?: ?{
        +__typename?: 'ReportTemplate',
        +id: string,
        +name: string,
        +triggerPhrase: string,
        +created: Date,
        +content: Json,
        +sections: $ReadOnlyArray<Json>,
        +picklists: $ReadOnlyArray<{
          +__typename?: 'ReportPicklist',
          +id: string,
          +options: $ReadOnlyArray<{
            +__typename?: 'ReportPicklistOption',
            +id: string,
            +name?: ?string,
            +text: $ReadOnlyArray<Json>,
            +default: boolean,
          }>,
        }>,
        +macros: $ReadOnlyArray<{
          +__typename?: 'Macro',
          +smid: string,
          +name: string,
          +triggerPhrase: string,
          +text: $ReadOnlyArray<Json>,
          +created: Date,
          +updated?: ?Date,
          +procedures?: ?$ReadOnlyArray<{
            +__typename?: 'Procedure',
            +smid: string,
            +description: string,
            +code: string,
          }>,
          +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
          +picklists: $ReadOnlyArray<{
            +__typename?: 'ReportPicklist',
            +id: string,
            +options: $ReadOnlyArray<{
              +__typename?: 'ReportPicklistOption',
              +id: string,
              +name?: ?string,
              +text: $ReadOnlyArray<Json>,
              +default: boolean,
            }>,
          }>,
        }>,
        +procedureReferences?: ?$ReadOnlyArray<{
          +__typename?: 'ProcedureReference',
          +smid: string,
          +description?: ?string,
          +code?: ?string,
          +autoload: boolean,
        }>,
      },
    },
  },
};

export type ToggleAnatomicNavigatorMutationVariables = {
  anatomicNavigatorEnabled: $ElementType<Scalars, 'Boolean'>,
};

export type ToggleAnatomicNavigatorMutation = {
  +__typename?: 'Mutation',
  +toggleAnatomicNavigator: {
    +__typename?: 'AnatomicNavigatorResponse',
    +anatomicNavigatorEnabled: boolean,
  },
};

export type ToggleStackedScrollingMutationVariables = {
  stackInstancesByType: $ElementType<Scalars, 'Boolean'>,
};

export type ToggleStackedScrollingMutation = {
  +__typename?: 'Mutation',
  +toggleStackedScrolling: {
    +__typename?: 'StackedScrollingResponse',
    +stackInstancesByType: boolean,
  },
};

export type ToggleAbsoluteScrollMutationVariables = {
  absoluteScroll: $ElementType<Scalars, 'Boolean'>,
};

export type ToggleAbsoluteScrollMutation = {
  +__typename?: 'Mutation',
  +toggleAbsoluteScroll: { +__typename?: 'AbsoluteScrollingResponse', +absoluteScroll: boolean },
};

export type UpdateStackedModalitiesMutationVariables = {
  stackedModalities:
    | $ReadOnlyArray<$ElementType<Scalars, 'String'>>
    | $ElementType<Scalars, 'String'>,
};

export type UpdateStackedModalitiesMutation = {
  +__typename?: 'Mutation',
  +updateStackedModalities: {
    +__typename?: 'StackedScrollingModalitiesResponse',
    +stackedModalities: $ReadOnlyArray<string>,
  },
};

export type ToggleManualLocalizerLinesMutationVariables = {
  showManualLocalizerLines: $ElementType<Scalars, 'Boolean'>,
};

export type ToggleManualLocalizerLinesMutation = {
  +__typename?: 'Mutation',
  +toggleManualLocalizerLines: {
    +__typename?: 'ManualLocalizerLinesResponse',
    +showManualLocalizerLines: boolean,
  },
};

export type ToggleEraseAllToolScopeMutationVariables = {
  eraseAllToolScope?: ?EraseAllToolScope,
};

export type ToggleEraseAllToolScopeMutation = {
  +__typename?: 'Mutation',
  +toggleEraseAllToolScope?: ?{
    +__typename?: 'EraseAllToolScopeResponse',
    +eraseAllToolScope?: ?EraseAllToolScope,
  },
};

export type ToggleVirtualMonitorSplitMutationVariables = {
  virtualMonitorSplit?: ?VirtualMonitorSplit,
};

export type ToggleVirtualMonitorSplitMutation = {
  +__typename?: 'Mutation',
  +toggleVirtualMonitorSplit?: ?{
    +__typename?: 'VirtualMonitorSplitResponse',
    +virtualMonitorSplit?: ?VirtualMonitorSplit,
  },
};

export type UpdateSeriesSkipSettingsMutationVariables = {
  seriesSkipSettings?: ?SeriesSkipSettingsPayload,
};

export type UpdateSeriesSkipSettingsMutation = {
  +__typename?: 'Mutation',
  +updateSeriesSkipSettings: {
    +__typename?: 'SeriesSkipSettingsResponse',
    +seriesSkipSettings?: ?{
      +__typename?: 'SeriesSkipSettings',
      +amount?: ?number,
      +keepPreviousWhenAvailable: boolean,
    },
  },
};

export type UpdateCustomDicomTagsMutationVariables = {
  modality: $ElementType<Scalars, 'String'>,
  modalityDicomTags: $ReadOnlyArray<DicomTagRenderInfoInput> | DicomTagRenderInfoInput,
};

export type UpdateCustomDicomTagsMutation = {
  +__typename?: 'Mutation',
  +updateCustomDicomTags: {
    +__typename?: 'CustomDicomTagsResponse',
    +modality: string,
    +modalityDicomTags: $ReadOnlyArray<{
      +__typename?: 'DicomTagRenderInfo',
      +id: string,
      +tagId: string,
      +enabled: boolean,
      +label: string,
    }>,
  },
};

export type GetLastViewedWorklistsQueryVariables = {};

export type GetLastViewedWorklistsQuery = {
  +__typename?: 'Query',
  +me: {
    +__typename?: 'User',
    +id: string,
    +lastViewedWorklists: $ReadOnlyArray<{
      +__typename?: 'WorkListItem',
      +smid: string,
      +mrn?: ?string,
      +status: WorkListItemStatus,
      +dosage?: ?string,
      +studyReason?: ?string,
      +studyDescription?: ?string,
      +studyDate?: ?Date,
      +referringPhysician?: ?string,
      +accessionNumber?: ?string,
      +techNotes?: ?string,
      +patientName?: ?string,
      +patientDob?: ?Date,
      +patientAge?: ?string,
      +patientSex?: ?string,
      +date?: ?Date,
      +groupId?: ?string,
      +modality?: ?string,
      +hasDraftReport: boolean,
      +procedure?: ?{
        +__typename?: 'Procedure',
        +smid: string,
        +description: string,
        +code: string,
      },
      +customMergeFields: $ReadOnlyArray<{
        +__typename?: 'CustomMergeField',
        +key: string,
        +value: string,
      }>,
      +claimedBy?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
      +report?: ?{
        +__typename?: 'Report',
        +smid: string,
        +submittedAt?: ?Date,
        +updated: Date,
        +owner?: ?{ +__typename?: 'Owner', +firstName: string, +lastName: string },
      },
      +studies: $ReadOnlyArray<{
        +__typename?: 'Study',
        +smid: string,
        +bodyPart?: ?string,
        +modality: string,
        +description?: ?string,
        +laterality?: ?string,
        +studyDate?: ?Date,
      }>,
      +customStatus?: ?{ +__typename?: 'WorklistCustomStatus', +smid: string, +name: string },
    }>,
  },
};

export type GetProductBoardUrlQueryVariables = {};

export type GetProductBoardUrlQuery = { +__typename?: 'Query', +productBoardUrl: string };

export type GetHeadingKeywordsQueryVariables = {};

export type GetHeadingKeywordsQuery = {
  +__typename?: 'Query',
  +headingKeywords: {
    +__typename?: 'HeadingKeywords',
    +exam: $ReadOnlyArray<string>,
    +history: $ReadOnlyArray<string>,
    +technique: $ReadOnlyArray<string>,
    +findings: $ReadOnlyArray<string>,
    +comparison: $ReadOnlyArray<string>,
    +impressions: $ReadOnlyArray<string>,
  },
};

export type UpdateLastViewedWorklistsMutationVariables = {
  smids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
};

export type UpdateLastViewedWorklistsMutation = {
  +__typename?: 'Mutation',
  +updateLastViewedWorklists?: ?{
    +__typename?: 'User',
    +lastViewedWorklists: $ReadOnlyArray<{ +__typename?: 'WorkListItem', +smid: string }>,
  },
};

export type UpdateWorklistSettingsMutationVariables = {
  columns?: ?$ReadOnlyArray<WorklistColumnInput> | WorklistColumnInput,
  customWorklists?: ?$ReadOnlyArray<?CustomWorklistInput> | ?CustomWorklistInput,
  lastUsedCustomWorklist?: ?CustomWorklistInput,
  openPatientJacketOnRead?: ?$ElementType<Scalars, 'Boolean'>,
};

export type UpdateWorklistSettingsMutation = {
  +__typename?: 'Mutation',
  +updateWorklistSettings: {
    +__typename?: 'WorklistSettings',
    +openPatientJacketOnRead: boolean,
    +columns: $ReadOnlyArray<{
      +__typename?: 'WorklistColumn',
      +id: WorklistColumnId,
      +width: number,
      +enabled: boolean,
    }>,
  },
};

export type GetAllAnnotationsQueryVariables = {
  workListIds?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
  studyIds?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
  query?: ?$ElementType<Scalars, 'JSON'>,
};

export type GetAllAnnotationsQuery = {
  +__typename?: 'Query',
  +annotations?: ?$ReadOnlyArray<
    | {
        +__typename?: 'GspsAnnotation',
        +created?: ?DateTimeLocal,
        +smid: string,
        +type: AnnotationTypes,
        +source: AnnotationSource,
        +seriesSmid: string,
        +aiAnnotationSmid?: ?string,
        +pixel_space_representation?: ?Json,
        +data: Json,
        +owner: { +__typename?: 'GspsOwner', +firstName?: ?string, +lastName?: ?string },
        +presentationState: { +__typename?: 'GspsPresentationState', +smid: string },
        +customLogic?: ?{ +__typename?: 'CustomAnnotationLogic', +id: string, +logic: string },
      }
    | {
        +__typename?: 'UgcAnnotation',
        +smid: string,
        +type: AnnotationTypes,
        +source: AnnotationSource,
        +seriesSmid: string,
        +aiAnnotationSmid?: ?string,
        +pixel_space_representation?: ?Json,
        +data: Json,
        +customLogic?: ?{ +__typename?: 'CustomAnnotationLogic', +id: string, +logic: string },
      },
  >,
};

export type CreateAnnotationMutationVariables = {
  seriesSmid: $ElementType<Scalars, 'ID'>,
  type: $ElementType<Scalars, 'String'>,
  aiAnnotationSmid?: ?$ElementType<Scalars, 'ID'>,
  pixel_space_representation?: ?$ElementType<Scalars, 'JSON'>,
  data: $ElementType<Scalars, 'JSON'>,
};

export type CreateAnnotationMutation = {
  +__typename?: 'Mutation',
  +createAnnotation?: ?{
    +__typename?: 'UgcAnnotation',
    +smid: string,
    +type: AnnotationTypes,
    +aiAnnotationSmid?: ?string,
    +pixel_space_representation?: ?Json,
    +data: Json,
    +customLogic?: ?{ +__typename?: 'CustomAnnotationLogic', +id: string, +logic: string },
  },
};

export type CreatePresentationStateMutationVariables = {
  name: $ElementType<Scalars, 'String'>,
  annotationSmids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
};

export type CreatePresentationStateMutation = {
  +__typename?: 'Mutation',
  +createPresentationState?: ?{
    +__typename?: 'PresentationState',
    +name: string,
    +annotationSmids: $ReadOnlyArray<string>,
  },
};

export type UpdateAnnotationMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
  pixel_space_representation?: ?$ElementType<Scalars, 'JSON'>,
  data: $ElementType<Scalars, 'JSON'>,
};

export type UpdateAnnotationMutation = {
  +__typename?: 'Mutation',
  +updateAnnotation?: ?{
    +__typename?: 'UgcAnnotation',
    +smid: string,
    +type: AnnotationTypes,
    +aiAnnotationSmid?: ?string,
    +pixel_space_representation?: ?Json,
    +data: Json,
    +customLogic?: ?{ +__typename?: 'CustomAnnotationLogic', +id: string, +logic: string },
  },
};

export type GetWorkListItemAnalyticsQueryVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type GetWorkListItemAnalyticsQuery = {
  +__typename?: 'Query',
  +workListItem?: ?{
    +__typename?: 'WorkListItem',
    +smid: string,
    +modality?: ?string,
    +bodyPart?: ?string,
    +studyDescription?: ?string,
  },
};

export type GetWorkListItemQueryVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type GetWorkListItemQuery = {
  +__typename?: 'Query',
  +workListItem?: ?{
    +__typename?: 'WorkListItem',
    +smid: string,
    +mrn?: ?string,
    +status: WorkListItemStatus,
    +dosage?: ?string,
    +studyReason?: ?string,
    +studyDescription?: ?string,
    +studyDate?: ?Date,
    +referringPhysician?: ?string,
    +accessionNumber?: ?string,
    +techNotes?: ?string,
    +patientName?: ?string,
    +patientDob?: ?Date,
    +patientAge?: ?string,
    +patientSex?: ?string,
    +date?: ?Date,
    +groupId?: ?string,
    +modality?: ?string,
    +hasDraftReport: boolean,
    +procedure?: ?{ +__typename?: 'Procedure', +smid: string, +description: string, +code: string },
    +customMergeFields: $ReadOnlyArray<{
      +__typename?: 'CustomMergeField',
      +key: string,
      +value: string,
    }>,
    +claimedBy?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
    +report?: ?{
      +__typename?: 'Report',
      +smid: string,
      +submittedAt?: ?Date,
      +updated: Date,
      +owner?: ?{ +__typename?: 'Owner', +firstName: string, +lastName: string },
    },
    +studies: $ReadOnlyArray<{
      +__typename?: 'Study',
      +smid: string,
      +bodyPart?: ?string,
      +modality: string,
      +description?: ?string,
      +laterality?: ?string,
      +studyDate?: ?Date,
    }>,
    +customStatus?: ?{ +__typename?: 'WorklistCustomStatus', +smid: string, +name: string },
  },
};

export type GetWorkListItemAuditLogsQueryVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type GetWorkListItemAuditLogsQuery = {
  +__typename?: 'Query',
  +workListItemAuditLogs: $ReadOnlyArray<{
    +__typename?: 'WorkListItemAuditLogItem',
    +createdAt: Date,
    +customStatus?: ?string,
    +groupId?: ?string,
    +status?: ?string,
    +actor?: ?{ +__typename?: 'WorkListItemAuditLogUser', +firstName: string, +lastName: string },
    +claimedBy?: ?{
      +__typename?: 'WorkListItemAuditLogUser',
      +firstName: string,
      +lastName: string,
    },
  }>,
};

export type GetWorklistCustomStatusesQueryVariables = {};

export type GetWorklistCustomStatusesQuery = {
  +__typename?: 'Query',
  +worklistCustomStatuses: $ReadOnlyArray<{
    +__typename?: 'WorklistCustomStatus',
    +smid: string,
    +name: string,
  }>,
};

export type GetPatientJacketQueryVariables = {
  smid: $ElementType<Scalars, 'ID'>,
  aiMode: $ElementType<Scalars, 'Boolean'>,
};

export type GetPatientJacketQuery = {
  +__typename?: 'Query',
  +workListItem?: ?{
    +__typename?: 'WorkListItem',
    +mrn?: ?string,
    +summary?: ?{
      +__typename?: 'WorkListItemSummary',
      +patientHistory: string,
      +relevantFindings: Json,
    },
  },
};

export type GetWorkListQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'ID'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  sortOrder?: ?SortOrder,
  sortColumn?: ?SortColumn,
  claimedBy?: ?$ElementType<Scalars, 'ID'>,
  site?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>> | ?$ElementType<Scalars, 'String'>,
  patientType?:
    | ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>
    | ?$ElementType<Scalars, 'String'>,
  patientSex?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>> | ?$ElementType<Scalars, 'String'>,
  patientDob?:
    | ?$ReadOnlyArray<?$ElementType<Scalars, 'DateTime'>>
    | ?$ElementType<Scalars, 'DateTime'>,
  modality?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>> | ?$ElementType<Scalars, 'String'>,
  priority?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>> | ?$ElementType<Scalars, 'String'>,
  status?: ?WorkListItemStatus,
  filter?: ?$ElementType<Scalars, 'String'>,
  smids?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
  dateRange?:
    | ?$ReadOnlyArray<?$ElementType<Scalars, 'DateTime'>>
    | ?$ElementType<Scalars, 'DateTime'>,
  submittedAt?:
    | ?$ReadOnlyArray<?$ElementType<Scalars, 'DateTime'>>
    | ?$ElementType<Scalars, 'DateTime'>,
  customStatus?:
    | ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>
    | ?$ElementType<Scalars, 'String'>,
  mrn?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>> | ?$ElementType<Scalars, 'String'>,
  accessionNumber?:
    | ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>>
    | ?$ElementType<Scalars, 'String'>,
  activeViews?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
  issuer?: ?$ReadOnlyArray<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
  hasDraftReport?: ?$ElementType<Scalars, 'Boolean'>,
};

export type GetWorkListQuery = {
  +__typename?: 'Query',
  +workListItems: {
    +__typename?: 'WorkListItems',
    +totalCount: number,
    +filteredCount: number,
    +next?: ?string,
    +items: $ReadOnlyArray<{
      +__typename?: 'WorkListItem',
      +smid: string,
      +created?: ?Date,
      +updated?: ?Date,
      +studyDate?: ?Date,
      +submittedAt?: ?Date,
      +patientName?: ?string,
      +patientAge?: ?string,
      +patientSex?: ?string,
      +patientDob?: ?Date,
      +patientType?: ?string,
      +accessionNumber?: ?string,
      +bodyPart?: ?string,
      +modality?: ?string,
      +referringPhysician?: ?string,
      +site?: ?string,
      +status: WorkListItemStatus,
      +mrn?: ?string,
      +priority?: ?string,
      +studyDescription?: ?string,
      +dosage?: ?string,
      +studyReason?: ?string,
      +techNotes?: ?string,
      +isStat: boolean,
      +groupId?: ?string,
      +hasDraftReport: boolean,
      +studies: $ReadOnlyArray<{ +__typename?: 'Study', +smid: string }>,
      +claimedBy?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
      +report?: ?{
        +__typename?: 'Report',
        +smid: string,
        +submittedAt?: ?Date,
        +owner?: ?{ +__typename?: 'Owner', +firstName: string, +lastName: string },
      },
      +customStatus?: ?{ +__typename?: 'WorklistCustomStatus', +smid: string, +name: string },
    }>,
  },
};

export type GetGroupableWorkListItemsQueryVariables = {
  mrn: $ElementType<Scalars, 'String'>,
  patientName: $ElementType<Scalars, 'String'>,
};

export type GetGroupableWorkListItemsQuery = {
  +__typename?: 'Query',
  +groupableWorkListItems: $ReadOnlyArray<{
    +__typename?: 'WorkListItem',
    +smid: string,
    +studyDescription?: ?string,
    +studyDate?: ?Date,
    +accessionNumber?: ?string,
    +groupId?: ?string,
    +claimedBy?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
  }>,
};

export type GetGroupedWorkListItemsQueryVariables = {
  groupId?: ?$ElementType<Scalars, 'ID'>,
};

export type GetGroupedWorkListItemsQuery = {
  +__typename?: 'Query',
  +groupedWorkListItems: $ReadOnlyArray<{
    +__typename?: 'WorkListItem',
    +smid: string,
    +accessionNumber?: ?string,
    +patientAge?: ?string,
    +patientSex?: ?string,
    +dosage?: ?string,
    +studyDescription?: ?string,
    +studyReason?: ?string,
    +studyDate?: ?Date,
    +referringPhysician?: ?string,
    +techNotes?: ?string,
    +studies: $ReadOnlyArray<{ +__typename?: 'Study', +laterality?: ?string, +studyDate?: ?Date }>,
    +customMergeFields: $ReadOnlyArray<{
      +__typename?: 'CustomMergeField',
      +key: string,
      +value: string,
    }>,
  }>,
};

export type CreateWorkListItemGroupMutationVariables = {
  workListItemSmids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
};

export type CreateWorkListItemGroupMutation = {
  +__typename?: 'Mutation',
  +createWorkListItemGroup: string,
};

export type UpdateWorkListItemGroupMutationVariables = {
  groupId: $ElementType<Scalars, 'ID'>,
  workListItemSmids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
};

export type UpdateWorkListItemGroupMutation = {
  +__typename?: 'Mutation',
  +updateWorkListItemGroup: $ReadOnlyArray<string>,
};

export type DeleteWorkListItemGroupMutationVariables = {
  groupId: $ElementType<Scalars, 'ID'>,
};

export type DeleteWorkListItemGroupMutation = {
  +__typename?: 'Mutation',
  +deleteWorkListItemGroup?: ?{ +__typename?: 'Response', +status?: ?number },
};

export type GetStudyQueryVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type GetStudyQuery = {
  +__typename?: 'Query',
  +study: {
    +__typename?: 'Study',
    +smid: string,
    +created: Date,
    +updated: Date,
    +instanceUID: string,
    +description?: ?string,
    +hl7Order?: ?string,
    +accessionNumber?: ?string,
    +seriesCount: number,
    +patientSex?: ?string,
    +patientAge?: ?string,
    +patientName?: ?string,
    +patientDob?: ?Date,
    +modality: string,
    +bodyPart?: ?string,
    +mrn?: ?string,
    +studyDate?: ?Date,
    +laterality?: ?string,
    +seriesList: $ReadOnlyArray<{
      +__typename?: 'Series',
      +smid: string,
      +description?: ?string,
      +seriesInstanceUID: string,
      +seriesDateTimeLocal?: ?DateTimeLocal,
      +frameOfReferenceUID?: ?string,
      +seriesNumber?: ?number,
      +is3Dable: boolean,
      +study: { +__typename?: 'Study', +smid: string },
      +imageRegistrations: $ReadOnlyArray<{
        +__typename?: 'ImageRegistration',
        +smid: string,
        +created: Date,
        +codeVersion: string,
        +modelName: string,
        +modelVersion: string,
        +seriesFixed: string,
        +seriesMoving: string,
        +dataType: string,
        +fixedFrameOfReferenceUid: string,
        +movingFrameOfReferenceUid: string,
        +transformMatrix: [
          [number, number, number, number],
          [number, number, number, number],
          [number, number, number, number],
          [number, number, number, number],
        ],
      }>,
    }>,
    +stackedFrames: $ReadOnlyArray<
      | {
          +__typename?: 'LayeredStack',
          +smid: string,
          +type: string,
          +study: { +__typename?: 'Study', +smid: string },
          +stackLayers: $ReadOnlyArray<{
            +__typename?: 'Layer',
            +stackSmid: string,
            +index: number,
          }>,
        }
      | {
          +__typename?: 'SingleLayerStack',
          +smid: string,
          +type: string,
          +image: string,
          +is3Dable: boolean,
          +frames: $ReadOnlyArray<FrameDataFragment>,
          +series: { +__typename?: 'Series', +smid: string },
          +study: { +__typename?: 'Study', +smid: string },
          +supportedRenderers: { +__typename?: 'SupportedRenderers', +vtk: RendererSupport },
          +imageParams: {
            +__typename?: 'ImageParams',
            +origin: [number, number, number],
            +spacing: [number, number, number],
            +dimensions: [number, number, number],
            +extent: [number, number, number, number, number, number],
            +direction: [number, number, number, number, number, number, number, number, number],
            +isMultiAxes: boolean,
            +colorWindow?: ?number,
            +colorLevel?: ?number,
          },
        },
    >,
  },
};

export type GetComparativeStudiesQueryVariables = {
  searchSmid: $ElementType<Scalars, 'ID'>,
  matchTypes?: ?$ReadOnlyArray<MatchType> | MatchType,
  bodyPart?: ?$ReadOnlyArray<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
};

export type GetComparativeStudiesQuery = {
  +__typename?: 'Query',
  +bodyPartMatchedComparativeStudies: {
    +__typename?: 'StudyItems',
    +items: $ReadOnlyArray<{
      +__typename?: 'Study',
      +accessionNumber?: ?string,
      +description?: ?string,
      +modality: string,
      +mrn?: ?string,
      +patientAge?: ?string,
      +smid: string,
      +studyDate?: ?Date,
      +seriesCount: number,
    }>,
  },
  +allComparativeStudies: { +__typename?: 'StudyItems', +totalCount: number },
};

export type GetStudiesQueryVariables = {
  limit?: ?$ElementType<Scalars, 'Int'>,
  cursor?: ?$ElementType<Scalars, 'ID'>,
  filter?: ?$ElementType<Scalars, 'String'>,
  modality?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>> | ?$ElementType<Scalars, 'String'>,
  mrn?: ?$ElementType<Scalars, 'String'>,
  bodyPart?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>> | ?$ElementType<Scalars, 'String'>,
  dateRange?:
    | ?$ReadOnlyArray<?$ElementType<Scalars, 'DateTime'>>
    | ?$ElementType<Scalars, 'DateTime'>,
  sortOrder?: ?SortOrder,
  sortColumn?: ?SortColumn,
  searchSmid?: ?$ElementType<Scalars, 'ID'>,
  matchTypes?: ?$ReadOnlyArray<MatchType> | MatchType,
};

export type GetStudiesQuery = {
  +__typename?: 'Query',
  +studies: {
    +__typename?: 'StudyItems',
    +totalCount: number,
    +next?: ?string,
    +items: $ReadOnlyArray<{
      +__typename?: 'Study',
      +smid: string,
      +created: Date,
      +updated: Date,
      +instanceUID: string,
      +description?: ?string,
      +hl7Order?: ?string,
      +accessionNumber?: ?string,
      +seriesCount: number,
      +patientSex?: ?string,
      +patientAge?: ?string,
      +patientName?: ?string,
      +patientDob?: ?Date,
      +modality: string,
      +bodyPart?: ?string,
      +mrn?: ?string,
      +studyDate?: ?Date,
      +laterality?: ?string,
      +seriesList: $ReadOnlyArray<{
        +__typename?: 'Series',
        +smid: string,
        +description?: ?string,
        +seriesInstanceUID: string,
        +seriesDateTimeLocal?: ?DateTimeLocal,
        +frameOfReferenceUID?: ?string,
        +seriesNumber?: ?number,
        +is3Dable: boolean,
        +study: { +__typename?: 'Study', +smid: string },
        +imageRegistrations: $ReadOnlyArray<{
          +__typename?: 'ImageRegistration',
          +smid: string,
          +created: Date,
          +codeVersion: string,
          +modelName: string,
          +modelVersion: string,
          +seriesFixed: string,
          +seriesMoving: string,
          +dataType: string,
          +fixedFrameOfReferenceUid: string,
          +movingFrameOfReferenceUid: string,
          +transformMatrix: [
            [number, number, number, number],
            [number, number, number, number],
            [number, number, number, number],
            [number, number, number, number],
          ],
        }>,
      }>,
      +stackedFrames: $ReadOnlyArray<
        | {
            +__typename?: 'LayeredStack',
            +smid: string,
            +type: string,
            +study: { +__typename?: 'Study', +smid: string },
            +stackLayers: $ReadOnlyArray<{
              +__typename?: 'Layer',
              +stackSmid: string,
              +index: number,
            }>,
          }
        | {
            +__typename?: 'SingleLayerStack',
            +smid: string,
            +type: string,
            +image: string,
            +is3Dable: boolean,
            +frames: $ReadOnlyArray<FrameDataFragment>,
            +series: { +__typename?: 'Series', +smid: string },
            +study: { +__typename?: 'Study', +smid: string },
            +supportedRenderers: { +__typename?: 'SupportedRenderers', +vtk: RendererSupport },
            +imageParams: {
              +__typename?: 'ImageParams',
              +origin: [number, number, number],
              +spacing: [number, number, number],
              +dimensions: [number, number, number],
              +extent: [number, number, number, number, number, number],
              +direction: [number, number, number, number, number, number, number, number, number],
              +isMultiAxes: boolean,
              +colorWindow?: ?number,
              +colorLevel?: ?number,
            },
          },
      >,
    }>,
  },
};

export type GetStudiesWithoutSeriesQueryVariables = {
  limit?: ?$ElementType<Scalars, 'Int'>,
  cursor?: ?$ElementType<Scalars, 'ID'>,
  filter?: ?$ElementType<Scalars, 'String'>,
  modality?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>> | ?$ElementType<Scalars, 'String'>,
  mrn?: ?$ElementType<Scalars, 'String'>,
  bodyPart?: ?$ReadOnlyArray<?$ElementType<Scalars, 'String'>> | ?$ElementType<Scalars, 'String'>,
  dateRange?:
    | ?$ReadOnlyArray<?$ElementType<Scalars, 'DateTime'>>
    | ?$ElementType<Scalars, 'DateTime'>,
  sortOrder?: ?SortOrder,
  sortColumn?: ?SortColumn,
  searchSmid?: ?$ElementType<Scalars, 'ID'>,
  matchTypes?: ?$ReadOnlyArray<MatchType> | MatchType,
};

export type GetStudiesWithoutSeriesQuery = {
  +__typename?: 'Query',
  +studies: {
    +__typename?: 'StudyItems',
    +totalCount: number,
    +next?: ?string,
    +items: $ReadOnlyArray<{
      +__typename?: 'Study',
      +smid: string,
      +created: Date,
      +updated: Date,
      +instanceUID: string,
      +description?: ?string,
      +hl7Order?: ?string,
      +patientSex?: ?string,
      +patientAge?: ?string,
      +patientName?: ?string,
      +patientDob?: ?Date,
      +modality: string,
      +bodyPart?: ?string,
      +mrn?: ?string,
      +studyDate?: ?Date,
      +laterality?: ?string,
      +provenanceData?: ?{ +__typename?: 'ProvenanceData', +matchType?: ?MatchType },
    }>,
  },
};

export type GetSeriesFromStudiesQueryVariables = {
  smids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
};

export type GetSeriesFromStudiesQuery = {
  +__typename?: 'Query',
  +studiesBySmid: $ReadOnlyArray<{
    +__typename?: 'Study',
    +seriesList: $ReadOnlyArray<{
      +__typename?: 'Series',
      +smid: string,
      +seriesInstanceUID: string,
      +description?: ?string,
      +SOPClassUID?: ?string,
      +seriesDateTimeLocal?: ?DateTimeLocal,
      +frameOfReferenceUID?: ?string,
      +seriesNumber?: ?number,
    }>,
  }>,
};

export type GetClaimedItemsQueryVariables = {
  claimedBy: $ElementType<Scalars, 'ID'>,
};

export type GetClaimedItemsQuery = {
  +__typename?: 'Query',
  +workListItems: {
    +__typename?: 'WorkListItems',
    +totalCount: number,
    +items: $ReadOnlyArray<{ +__typename?: 'WorkListItem', +smid: string }>,
  },
};

export type GetMyQueueQueryVariables = {
  claimedBy: $ElementType<Scalars, 'ID'>,
};

export type GetMyQueueQuery = {
  +__typename?: 'Query',
  +workListItems: {
    +__typename?: 'WorkListItems',
    +totalCount: number,
    +items: $ReadOnlyArray<{
      +__typename?: 'WorkListItem',
      +smid: string,
      +groupId?: ?string,
      +report?: ?{ +__typename?: 'Report', +smid: string, +submittedAt?: ?Date },
    }>,
  },
};

export type GetStudyDescriptionsQueryVariables = {
  search?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'ID'>,
};

export type GetStudyDescriptionsQuery = {
  +__typename?: 'Query',
  +studyDescriptions: {
    +__typename?: 'StudyDescriptions',
    +totalCount: number,
    +cursor?: ?string,
    +next?: ?string,
    +items: $ReadOnlyArray<{
      +__typename?: 'StudyDescription',
      +smid: string,
      +description: string,
      +autoloadTemplate?: ?{ +__typename?: 'ReportTemplate', +id: string, +name: string },
    }>,
  },
};

export type GetProcedureQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};

export type GetProcedureQuery = {
  +__typename?: 'Query',
  +procedure: {
    +__typename?: 'Procedure',
    +smid: string,
    +code: string,
    +description: string,
    +autoloadTemplate?: ?{ +__typename?: 'ReportTemplate', +id: string, +name: string },
  },
};

export type GetProceduresQueryVariables = {
  search?: ?$ElementType<Scalars, 'String'>,
  cursor?: ?$ElementType<Scalars, 'ID'>,
};

export type GetProceduresQuery = {
  +__typename?: 'Query',
  +procedures: {
    +__typename?: 'Procedures',
    +totalCount: number,
    +cursor?: ?string,
    +next?: ?string,
    +items: $ReadOnlyArray<{
      +__typename?: 'Procedure',
      +smid: string,
      +code: string,
      +description: string,
      +autoloadTemplate?: ?{ +__typename?: 'ReportTemplate', +id: string, +name: string },
    }>,
  },
};

export type GetProceduresBySmidQueryVariables = {
  smids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
};

export type GetProceduresBySmidQuery = {
  +__typename?: 'Query',
  +proceduresBySmid: $ReadOnlyArray<{
    +__typename?: 'Procedure',
    +smid: string,
    +code: string,
    +description: string,
    +autoloadTemplate?: ?{ +__typename?: 'ReportTemplate', +id: string, +name: string },
  }>,
};

export type HangingProtocolFragment = {
  +__typename?: 'HangingProtocol',
  +smid: string,
  +name: string,
  +numberOfPriors: number,
  +created: Date,
  +updated: Date,
  +studyDescriptions: $ReadOnlyArray<string>,
  +data: {
    +__typename?: 'HangingProtocolData',
    +layouts: $ReadOnlyArray<{
      +__typename?: 'HangingProtocolLayout',
      +id: string,
      +layout: $ReadOnlyArray<number>,
    }>,
  },
  +viewportsConfigurations?: ?$ReadOnlyArray<{
    +__typename?: 'ViewportConfiguration',
    +id: string,
    +configuration: {
      +__typename?: 'ViewportConfigurationData',
      +viewType?: ?ViewType,
      +comparisonIndex?: ?number,
      +seriesDescriptions: $ReadOnlyArray<string>,
      +study?: ?{
        +__typename?: 'Study',
        +smid: string,
        +created: Date,
        +updated: Date,
        +instanceUID: string,
        +description?: ?string,
        +hl7Order?: ?string,
        +accessionNumber?: ?string,
        +seriesCount: number,
        +patientSex?: ?string,
        +patientAge?: ?string,
        +patientName?: ?string,
        +patientDob?: ?Date,
        +modality: string,
        +bodyPart?: ?string,
        +mrn?: ?string,
        +studyDate?: ?Date,
        +laterality?: ?string,
        +seriesList: $ReadOnlyArray<{
          +__typename?: 'Series',
          +smid: string,
          +description?: ?string,
          +seriesInstanceUID: string,
          +seriesDateTimeLocal?: ?DateTimeLocal,
          +frameOfReferenceUID?: ?string,
          +seriesNumber?: ?number,
          +is3Dable: boolean,
          +study: { +__typename?: 'Study', +smid: string },
          +imageRegistrations: $ReadOnlyArray<{
            +__typename?: 'ImageRegistration',
            +smid: string,
            +created: Date,
            +codeVersion: string,
            +modelName: string,
            +modelVersion: string,
            +seriesFixed: string,
            +seriesMoving: string,
            +dataType: string,
            +fixedFrameOfReferenceUid: string,
            +movingFrameOfReferenceUid: string,
            +transformMatrix: [
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
            ],
          }>,
        }>,
        +stackedFrames: $ReadOnlyArray<
          | {
              +__typename?: 'LayeredStack',
              +smid: string,
              +type: string,
              +study: { +__typename?: 'Study', +smid: string },
              +stackLayers: $ReadOnlyArray<{
                +__typename?: 'Layer',
                +stackSmid: string,
                +index: number,
              }>,
            }
          | {
              +__typename?: 'SingleLayerStack',
              +smid: string,
              +type: string,
              +image: string,
              +is3Dable: boolean,
              +frames: $ReadOnlyArray<FrameDataFragment>,
              +series: { +__typename?: 'Series', +smid: string },
              +study: { +__typename?: 'Study', +smid: string },
              +supportedRenderers: { +__typename?: 'SupportedRenderers', +vtk: RendererSupport },
              +imageParams: {
                +__typename?: 'ImageParams',
                +origin: [number, number, number],
                +spacing: [number, number, number],
                +dimensions: [number, number, number],
                +extent: [number, number, number, number, number, number],
                +direction: [
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                ],
                +isMultiAxes: boolean,
                +colorWindow?: ?number,
                +colorLevel?: ?number,
              },
            },
        >,
      },
      +series?: ?{
        +__typename?: 'Series',
        +smid: string,
        +description?: ?string,
        +seriesInstanceUID: string,
        +seriesDateTimeLocal?: ?DateTimeLocal,
        +frameOfReferenceUID?: ?string,
        +seriesNumber?: ?number,
        +is3Dable: boolean,
        +study: { +__typename?: 'Study', +smid: string },
        +imageRegistrations: $ReadOnlyArray<{
          +__typename?: 'ImageRegistration',
          +smid: string,
          +created: Date,
          +codeVersion: string,
          +modelName: string,
          +modelVersion: string,
          +seriesFixed: string,
          +seriesMoving: string,
          +dataType: string,
          +fixedFrameOfReferenceUid: string,
          +movingFrameOfReferenceUid: string,
          +transformMatrix: [
            [number, number, number, number],
            [number, number, number, number],
            [number, number, number, number],
            [number, number, number, number],
          ],
        }>,
      },
      +stack?:
        | ?{ +__typename?: 'LayeredStack', +smid: string }
        | { +__typename?: 'SingleLayerStack', +smid: string },
    },
  }>,
};

export type GetHangingProtocolsQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'ID'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  studyDescriptions?:
    | ?$ReadOnlyArray<$ElementType<Scalars, 'String'>>
    | $ElementType<Scalars, 'String'>,
  minimumNumberOfPriors: $ElementType<Scalars, 'Int'>,
  cases?:
    | ?$ReadOnlyArray<$ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>>
    | $ReadOnlyArray<$ElementType<Scalars, 'ID'>>
    | $ElementType<Scalars, 'ID'>,
};

export type GetHangingProtocolsQuery = {
  +__typename?: 'Query',
  +hangingProtocols: {
    +__typename?: 'HangingProtocols',
    +items: $ReadOnlyArray<{
      +__typename?: 'HangingProtocol',
      +smid: string,
      +name: string,
      +numberOfPriors: number,
      +created: Date,
      +updated: Date,
      +studyDescriptions: $ReadOnlyArray<string>,
      +data: {
        +__typename?: 'HangingProtocolData',
        +layouts: $ReadOnlyArray<{
          +__typename?: 'HangingProtocolLayout',
          +id: string,
          +layout: $ReadOnlyArray<number>,
        }>,
      },
      +viewportsConfigurations?: ?$ReadOnlyArray<{
        +__typename?: 'ViewportConfiguration',
        +id: string,
        +configuration: {
          +__typename?: 'ViewportConfigurationData',
          +viewType?: ?ViewType,
          +comparisonIndex?: ?number,
          +seriesDescriptions: $ReadOnlyArray<string>,
          +study?: ?{
            +__typename?: 'Study',
            +smid: string,
            +created: Date,
            +updated: Date,
            +instanceUID: string,
            +description?: ?string,
            +hl7Order?: ?string,
            +accessionNumber?: ?string,
            +seriesCount: number,
            +patientSex?: ?string,
            +patientAge?: ?string,
            +patientName?: ?string,
            +patientDob?: ?Date,
            +modality: string,
            +bodyPart?: ?string,
            +mrn?: ?string,
            +studyDate?: ?Date,
            +laterality?: ?string,
            +seriesList: $ReadOnlyArray<{
              +__typename?: 'Series',
              +smid: string,
              +description?: ?string,
              +seriesInstanceUID: string,
              +seriesDateTimeLocal?: ?DateTimeLocal,
              +frameOfReferenceUID?: ?string,
              +seriesNumber?: ?number,
              +is3Dable: boolean,
              +study: { +__typename?: 'Study', +smid: string },
              +imageRegistrations: $ReadOnlyArray<{
                +__typename?: 'ImageRegistration',
                +smid: string,
                +created: Date,
                +codeVersion: string,
                +modelName: string,
                +modelVersion: string,
                +seriesFixed: string,
                +seriesMoving: string,
                +dataType: string,
                +fixedFrameOfReferenceUid: string,
                +movingFrameOfReferenceUid: string,
                +transformMatrix: [
                  [number, number, number, number],
                  [number, number, number, number],
                  [number, number, number, number],
                  [number, number, number, number],
                ],
              }>,
            }>,
            +stackedFrames: $ReadOnlyArray<
              | {
                  +__typename?: 'LayeredStack',
                  +smid: string,
                  +type: string,
                  +study: { +__typename?: 'Study', +smid: string },
                  +stackLayers: $ReadOnlyArray<{
                    +__typename?: 'Layer',
                    +stackSmid: string,
                    +index: number,
                  }>,
                }
              | {
                  +__typename?: 'SingleLayerStack',
                  +smid: string,
                  +type: string,
                  +image: string,
                  +is3Dable: boolean,
                  +frames: $ReadOnlyArray<FrameDataFragment>,
                  +series: { +__typename?: 'Series', +smid: string },
                  +study: { +__typename?: 'Study', +smid: string },
                  +supportedRenderers: {
                    +__typename?: 'SupportedRenderers',
                    +vtk: RendererSupport,
                  },
                  +imageParams: {
                    +__typename?: 'ImageParams',
                    +origin: [number, number, number],
                    +spacing: [number, number, number],
                    +dimensions: [number, number, number],
                    +extent: [number, number, number, number, number, number],
                    +direction: [
                      number,
                      number,
                      number,
                      number,
                      number,
                      number,
                      number,
                      number,
                      number,
                    ],
                    +isMultiAxes: boolean,
                    +colorWindow?: ?number,
                    +colorLevel?: ?number,
                  },
                },
            >,
          },
          +series?: ?{
            +__typename?: 'Series',
            +smid: string,
            +description?: ?string,
            +seriesInstanceUID: string,
            +seriesDateTimeLocal?: ?DateTimeLocal,
            +frameOfReferenceUID?: ?string,
            +seriesNumber?: ?number,
            +is3Dable: boolean,
            +study: { +__typename?: 'Study', +smid: string },
            +imageRegistrations: $ReadOnlyArray<{
              +__typename?: 'ImageRegistration',
              +smid: string,
              +created: Date,
              +codeVersion: string,
              +modelName: string,
              +modelVersion: string,
              +seriesFixed: string,
              +seriesMoving: string,
              +dataType: string,
              +fixedFrameOfReferenceUid: string,
              +movingFrameOfReferenceUid: string,
              +transformMatrix: [
                [number, number, number, number],
                [number, number, number, number],
                [number, number, number, number],
                [number, number, number, number],
              ],
            }>,
          },
          +stack?:
            | ?{ +__typename?: 'LayeredStack', +smid: string }
            | { +__typename?: 'SingleLayerStack', +smid: string },
        },
      }>,
    }>,
  },
};

export type GetHangingProtocolQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
  cases?:
    | ?$ReadOnlyArray<$ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>>
    | $ReadOnlyArray<$ElementType<Scalars, 'ID'>>
    | $ElementType<Scalars, 'ID'>,
};

export type GetHangingProtocolQuery = {
  +__typename?: 'Query',
  +hangingProtocol?: ?{
    +__typename?: 'HangingProtocol',
    +smid: string,
    +name: string,
    +numberOfPriors: number,
    +created: Date,
    +updated: Date,
    +studyDescriptions: $ReadOnlyArray<string>,
    +data: {
      +__typename?: 'HangingProtocolData',
      +layouts: $ReadOnlyArray<{
        +__typename?: 'HangingProtocolLayout',
        +id: string,
        +layout: $ReadOnlyArray<number>,
      }>,
    },
    +viewportsConfigurations?: ?$ReadOnlyArray<{
      +__typename?: 'ViewportConfiguration',
      +id: string,
      +configuration: {
        +__typename?: 'ViewportConfigurationData',
        +viewType?: ?ViewType,
        +comparisonIndex?: ?number,
        +seriesDescriptions: $ReadOnlyArray<string>,
        +study?: ?{
          +__typename?: 'Study',
          +smid: string,
          +created: Date,
          +updated: Date,
          +instanceUID: string,
          +description?: ?string,
          +hl7Order?: ?string,
          +accessionNumber?: ?string,
          +seriesCount: number,
          +patientSex?: ?string,
          +patientAge?: ?string,
          +patientName?: ?string,
          +patientDob?: ?Date,
          +modality: string,
          +bodyPart?: ?string,
          +mrn?: ?string,
          +studyDate?: ?Date,
          +laterality?: ?string,
          +seriesList: $ReadOnlyArray<{
            +__typename?: 'Series',
            +smid: string,
            +description?: ?string,
            +seriesInstanceUID: string,
            +seriesDateTimeLocal?: ?DateTimeLocal,
            +frameOfReferenceUID?: ?string,
            +seriesNumber?: ?number,
            +is3Dable: boolean,
            +study: { +__typename?: 'Study', +smid: string },
            +imageRegistrations: $ReadOnlyArray<{
              +__typename?: 'ImageRegistration',
              +smid: string,
              +created: Date,
              +codeVersion: string,
              +modelName: string,
              +modelVersion: string,
              +seriesFixed: string,
              +seriesMoving: string,
              +dataType: string,
              +fixedFrameOfReferenceUid: string,
              +movingFrameOfReferenceUid: string,
              +transformMatrix: [
                [number, number, number, number],
                [number, number, number, number],
                [number, number, number, number],
                [number, number, number, number],
              ],
            }>,
          }>,
          +stackedFrames: $ReadOnlyArray<
            | {
                +__typename?: 'LayeredStack',
                +smid: string,
                +type: string,
                +study: { +__typename?: 'Study', +smid: string },
                +stackLayers: $ReadOnlyArray<{
                  +__typename?: 'Layer',
                  +stackSmid: string,
                  +index: number,
                }>,
              }
            | {
                +__typename?: 'SingleLayerStack',
                +smid: string,
                +type: string,
                +image: string,
                +is3Dable: boolean,
                +frames: $ReadOnlyArray<FrameDataFragment>,
                +series: { +__typename?: 'Series', +smid: string },
                +study: { +__typename?: 'Study', +smid: string },
                +supportedRenderers: { +__typename?: 'SupportedRenderers', +vtk: RendererSupport },
                +imageParams: {
                  +__typename?: 'ImageParams',
                  +origin: [number, number, number],
                  +spacing: [number, number, number],
                  +dimensions: [number, number, number],
                  +extent: [number, number, number, number, number, number],
                  +direction: [
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                  ],
                  +isMultiAxes: boolean,
                  +colorWindow?: ?number,
                  +colorLevel?: ?number,
                },
              },
          >,
        },
        +series?: ?{
          +__typename?: 'Series',
          +smid: string,
          +description?: ?string,
          +seriesInstanceUID: string,
          +seriesDateTimeLocal?: ?DateTimeLocal,
          +frameOfReferenceUID?: ?string,
          +seriesNumber?: ?number,
          +is3Dable: boolean,
          +study: { +__typename?: 'Study', +smid: string },
          +imageRegistrations: $ReadOnlyArray<{
            +__typename?: 'ImageRegistration',
            +smid: string,
            +created: Date,
            +codeVersion: string,
            +modelName: string,
            +modelVersion: string,
            +seriesFixed: string,
            +seriesMoving: string,
            +dataType: string,
            +fixedFrameOfReferenceUid: string,
            +movingFrameOfReferenceUid: string,
            +transformMatrix: [
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
            ],
          }>,
        },
        +stack?:
          | ?{ +__typename?: 'LayeredStack', +smid: string }
          | { +__typename?: 'SingleLayerStack', +smid: string },
      },
    }>,
  },
};

export type GetAllHangingProtocolsQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'ID'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
};

export type GetAllHangingProtocolsQuery = {
  +__typename?: 'Query',
  +hangingProtocols: {
    +__typename?: 'HangingProtocols',
    +items: $ReadOnlyArray<{
      +__typename?: 'HangingProtocol',
      +smid: string,
      +name: string,
      +numberOfPriors: number,
      +created: Date,
      +updated: Date,
      +studyDescriptions: $ReadOnlyArray<string>,
      +data: {
        +__typename?: 'HangingProtocolData',
        +layouts: $ReadOnlyArray<{
          +__typename?: 'HangingProtocolLayout',
          +id: string,
          +layout: $ReadOnlyArray<number>,
        }>,
      },
    }>,
  },
};

export type UpdateHangingProtocolMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  payload: HangingProtocolPayload,
};

export type UpdateHangingProtocolMutation = {
  +__typename?: 'Mutation',
  +updateHangingProtocol?: ?{
    +__typename?: 'HangingProtocol',
    +smid: string,
    +name: string,
    +numberOfPriors: number,
    +created: Date,
    +updated: Date,
    +studyDescriptions: $ReadOnlyArray<string>,
    +data: {
      +__typename?: 'HangingProtocolData',
      +layouts: $ReadOnlyArray<{
        +__typename?: 'HangingProtocolLayout',
        +id: string,
        +layout: $ReadOnlyArray<number>,
      }>,
    },
    +viewportsConfigurations?: ?$ReadOnlyArray<{
      +__typename?: 'ViewportConfiguration',
      +id: string,
      +configuration: {
        +__typename?: 'ViewportConfigurationData',
        +viewType?: ?ViewType,
        +comparisonIndex?: ?number,
        +seriesDescriptions: $ReadOnlyArray<string>,
        +study?: ?{
          +__typename?: 'Study',
          +smid: string,
          +created: Date,
          +updated: Date,
          +instanceUID: string,
          +description?: ?string,
          +hl7Order?: ?string,
          +accessionNumber?: ?string,
          +seriesCount: number,
          +patientSex?: ?string,
          +patientAge?: ?string,
          +patientName?: ?string,
          +patientDob?: ?Date,
          +modality: string,
          +bodyPart?: ?string,
          +mrn?: ?string,
          +studyDate?: ?Date,
          +laterality?: ?string,
          +seriesList: $ReadOnlyArray<{
            +__typename?: 'Series',
            +smid: string,
            +description?: ?string,
            +seriesInstanceUID: string,
            +seriesDateTimeLocal?: ?DateTimeLocal,
            +frameOfReferenceUID?: ?string,
            +seriesNumber?: ?number,
            +is3Dable: boolean,
            +study: { +__typename?: 'Study', +smid: string },
            +imageRegistrations: $ReadOnlyArray<{
              +__typename?: 'ImageRegistration',
              +smid: string,
              +created: Date,
              +codeVersion: string,
              +modelName: string,
              +modelVersion: string,
              +seriesFixed: string,
              +seriesMoving: string,
              +dataType: string,
              +fixedFrameOfReferenceUid: string,
              +movingFrameOfReferenceUid: string,
              +transformMatrix: [
                [number, number, number, number],
                [number, number, number, number],
                [number, number, number, number],
                [number, number, number, number],
              ],
            }>,
          }>,
          +stackedFrames: $ReadOnlyArray<
            | {
                +__typename?: 'LayeredStack',
                +smid: string,
                +type: string,
                +study: { +__typename?: 'Study', +smid: string },
                +stackLayers: $ReadOnlyArray<{
                  +__typename?: 'Layer',
                  +stackSmid: string,
                  +index: number,
                }>,
              }
            | {
                +__typename?: 'SingleLayerStack',
                +smid: string,
                +type: string,
                +image: string,
                +is3Dable: boolean,
                +frames: $ReadOnlyArray<FrameDataFragment>,
                +series: { +__typename?: 'Series', +smid: string },
                +study: { +__typename?: 'Study', +smid: string },
                +supportedRenderers: { +__typename?: 'SupportedRenderers', +vtk: RendererSupport },
                +imageParams: {
                  +__typename?: 'ImageParams',
                  +origin: [number, number, number],
                  +spacing: [number, number, number],
                  +dimensions: [number, number, number],
                  +extent: [number, number, number, number, number, number],
                  +direction: [
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                  ],
                  +isMultiAxes: boolean,
                  +colorWindow?: ?number,
                  +colorLevel?: ?number,
                },
              },
          >,
        },
        +series?: ?{
          +__typename?: 'Series',
          +smid: string,
          +description?: ?string,
          +seriesInstanceUID: string,
          +seriesDateTimeLocal?: ?DateTimeLocal,
          +frameOfReferenceUID?: ?string,
          +seriesNumber?: ?number,
          +is3Dable: boolean,
          +study: { +__typename?: 'Study', +smid: string },
          +imageRegistrations: $ReadOnlyArray<{
            +__typename?: 'ImageRegistration',
            +smid: string,
            +created: Date,
            +codeVersion: string,
            +modelName: string,
            +modelVersion: string,
            +seriesFixed: string,
            +seriesMoving: string,
            +dataType: string,
            +fixedFrameOfReferenceUid: string,
            +movingFrameOfReferenceUid: string,
            +transformMatrix: [
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
            ],
          }>,
        },
        +stack?:
          | ?{ +__typename?: 'LayeredStack', +smid: string }
          | { +__typename?: 'SingleLayerStack', +smid: string },
      },
    }>,
  },
};

export type CreateHangingProtocolMutationVariables = {
  payload: HangingProtocolPayload,
};

export type CreateHangingProtocolMutation = {
  +__typename?: 'Mutation',
  +createHangingProtocol?: ?{
    +__typename?: 'HangingProtocol',
    +smid: string,
    +name: string,
    +numberOfPriors: number,
    +created: Date,
    +updated: Date,
    +studyDescriptions: $ReadOnlyArray<string>,
    +data: {
      +__typename?: 'HangingProtocolData',
      +layouts: $ReadOnlyArray<{
        +__typename?: 'HangingProtocolLayout',
        +id: string,
        +layout: $ReadOnlyArray<number>,
      }>,
    },
    +viewportsConfigurations?: ?$ReadOnlyArray<{
      +__typename?: 'ViewportConfiguration',
      +id: string,
      +configuration: {
        +__typename?: 'ViewportConfigurationData',
        +viewType?: ?ViewType,
        +comparisonIndex?: ?number,
        +seriesDescriptions: $ReadOnlyArray<string>,
        +study?: ?{
          +__typename?: 'Study',
          +smid: string,
          +created: Date,
          +updated: Date,
          +instanceUID: string,
          +description?: ?string,
          +hl7Order?: ?string,
          +accessionNumber?: ?string,
          +seriesCount: number,
          +patientSex?: ?string,
          +patientAge?: ?string,
          +patientName?: ?string,
          +patientDob?: ?Date,
          +modality: string,
          +bodyPart?: ?string,
          +mrn?: ?string,
          +studyDate?: ?Date,
          +laterality?: ?string,
          +seriesList: $ReadOnlyArray<{
            +__typename?: 'Series',
            +smid: string,
            +description?: ?string,
            +seriesInstanceUID: string,
            +seriesDateTimeLocal?: ?DateTimeLocal,
            +frameOfReferenceUID?: ?string,
            +seriesNumber?: ?number,
            +is3Dable: boolean,
            +study: { +__typename?: 'Study', +smid: string },
            +imageRegistrations: $ReadOnlyArray<{
              +__typename?: 'ImageRegistration',
              +smid: string,
              +created: Date,
              +codeVersion: string,
              +modelName: string,
              +modelVersion: string,
              +seriesFixed: string,
              +seriesMoving: string,
              +dataType: string,
              +fixedFrameOfReferenceUid: string,
              +movingFrameOfReferenceUid: string,
              +transformMatrix: [
                [number, number, number, number],
                [number, number, number, number],
                [number, number, number, number],
                [number, number, number, number],
              ],
            }>,
          }>,
          +stackedFrames: $ReadOnlyArray<
            | {
                +__typename?: 'LayeredStack',
                +smid: string,
                +type: string,
                +study: { +__typename?: 'Study', +smid: string },
                +stackLayers: $ReadOnlyArray<{
                  +__typename?: 'Layer',
                  +stackSmid: string,
                  +index: number,
                }>,
              }
            | {
                +__typename?: 'SingleLayerStack',
                +smid: string,
                +type: string,
                +image: string,
                +is3Dable: boolean,
                +frames: $ReadOnlyArray<FrameDataFragment>,
                +series: { +__typename?: 'Series', +smid: string },
                +study: { +__typename?: 'Study', +smid: string },
                +supportedRenderers: { +__typename?: 'SupportedRenderers', +vtk: RendererSupport },
                +imageParams: {
                  +__typename?: 'ImageParams',
                  +origin: [number, number, number],
                  +spacing: [number, number, number],
                  +dimensions: [number, number, number],
                  +extent: [number, number, number, number, number, number],
                  +direction: [
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                  ],
                  +isMultiAxes: boolean,
                  +colorWindow?: ?number,
                  +colorLevel?: ?number,
                },
              },
          >,
        },
        +series?: ?{
          +__typename?: 'Series',
          +smid: string,
          +description?: ?string,
          +seriesInstanceUID: string,
          +seriesDateTimeLocal?: ?DateTimeLocal,
          +frameOfReferenceUID?: ?string,
          +seriesNumber?: ?number,
          +is3Dable: boolean,
          +study: { +__typename?: 'Study', +smid: string },
          +imageRegistrations: $ReadOnlyArray<{
            +__typename?: 'ImageRegistration',
            +smid: string,
            +created: Date,
            +codeVersion: string,
            +modelName: string,
            +modelVersion: string,
            +seriesFixed: string,
            +seriesMoving: string,
            +dataType: string,
            +fixedFrameOfReferenceUid: string,
            +movingFrameOfReferenceUid: string,
            +transformMatrix: [
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
            ],
          }>,
        },
        +stack?:
          | ?{ +__typename?: 'LayeredStack', +smid: string }
          | { +__typename?: 'SingleLayerStack', +smid: string },
      },
    }>,
  },
};

export type DeleteHangingProtocolMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};

export type DeleteHangingProtocolMutation = {
  +__typename?: 'Mutation',
  +deleteHangingProtocol?: ?{ +__typename?: 'HangingProtocolDeleted', +smid: string },
};

export type DeleteAnnotationsMutationVariables = {
  smids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
};

export type DeleteAnnotationsMutation = {
  +__typename?: 'Mutation',
  +deleteAnnotations: {
    +__typename?: 'DeleteAnnotationsResponse',
    +results: $ReadOnlyArray<{
      +__typename?: 'DeleteAnnotationsItem',
      +status: DeleteAnnotationsStatus,
      +smid: string,
    }>,
  },
};

export type UpdateReportMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
  worklistItemSmid: $ElementType<Scalars, 'ID'>,
  payload: ReportPayload,
};

export type UpdateReportMutation = {
  +__typename?: 'Mutation',
  +updateReport: {
    +__typename?: 'Report',
    +smid: string,
    +created: Date,
    +updated: Date,
    +sectionsLastUpdated?: ?Date,
    +content?: ?Json,
    +sections: Json,
    +template?: ?{
      +__typename?: 'ReportTemplate',
      +id: string,
      +picklists: $ReadOnlyArray<{
        +__typename?: 'ReportPicklist',
        +id: string,
        +options: $ReadOnlyArray<{
          +__typename?: 'ReportPicklistOption',
          +id: string,
          +name?: ?string,
          +text: $ReadOnlyArray<Json>,
          +default: boolean,
        }>,
      }>,
      +macros: $ReadOnlyArray<{
        +__typename?: 'Macro',
        +smid: string,
        +name: string,
        +triggerPhrase: string,
        +text: $ReadOnlyArray<Json>,
        +created: Date,
        +updated?: ?Date,
        +procedures?: ?$ReadOnlyArray<{
          +__typename?: 'Procedure',
          +smid: string,
          +description: string,
          +code: string,
        }>,
        +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
      }>,
    },
    +addendums: $ReadOnlyArray<{
      +__typename?: 'Addendum',
      +created: Date,
      +updated: Date,
      +submittedAt?: ?Date,
      +sentAt?: ?Date,
      +smid: string,
      +content:
        | { +__typename?: 'AddendumSironaSlate', +sironaSlate: $ReadOnlyArray<Json> }
        | { +__typename?: 'AddendumTextBlob', +textBlob: string },
      +owner?: ?{ +__typename?: 'Owner', +id: string, +lastName: string },
    }>,
    +macros: $ReadOnlyArray<{
      +__typename?: 'Macro',
      +smid: string,
      +name: string,
      +triggerPhrase: string,
      +text: $ReadOnlyArray<Json>,
      +created: Date,
      +updated?: ?Date,
      +procedures?: ?$ReadOnlyArray<{
        +__typename?: 'Procedure',
        +smid: string,
        +description: string,
        +code: string,
      }>,
      +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
    }>,
    +picklists?: ?$ReadOnlyArray<{
      +__typename?: 'ReportPicklist',
      +id: string,
      +options: $ReadOnlyArray<{
        +__typename?: 'ReportPicklistOption',
        +id: string,
        +name?: ?string,
        +text: $ReadOnlyArray<Json>,
        +default: boolean,
      }>,
    }>,
  },
};

export type CreateAddendumMutationVariables = {
  reportSmid: $ElementType<Scalars, 'ID'>,
};

export type CreateAddendumMutation = {
  +__typename?: 'Mutation',
  +createAddendum: {
    +__typename?: 'Addendum',
    +created: Date,
    +updated: Date,
    +submittedAt?: ?Date,
    +sentAt?: ?Date,
    +smid: string,
    +content:
      | { +__typename?: 'AddendumSironaSlate', +sironaSlate: $ReadOnlyArray<Json> }
      | { +__typename?: 'AddendumTextBlob', +textBlob: string },
    +owner?: ?{ +__typename?: 'Owner', +id: string, +lastName: string },
  },
};

export type CancelAddendumMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type CancelAddendumMutation = {
  +__typename?: 'Mutation',
  +cancelAddendum: {
    +__typename?: 'Addendum',
    +created: Date,
    +updated: Date,
    +submittedAt?: ?Date,
    +sentAt?: ?Date,
    +smid: string,
    +content:
      | { +__typename?: 'AddendumSironaSlate', +sironaSlate: $ReadOnlyArray<Json> }
      | { +__typename?: 'AddendumTextBlob', +textBlob: string },
    +owner?: ?{ +__typename?: 'Owner', +id: string, +lastName: string },
  },
};

export type UpdateAddendumMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
  payload: AddendumPayload,
};

export type UpdateAddendumMutation = {
  +__typename?: 'Mutation',
  +updateAddendum: {
    +__typename?: 'Addendum',
    +created: Date,
    +updated: Date,
    +submittedAt?: ?Date,
    +sentAt?: ?Date,
    +smid: string,
    +content:
      | { +__typename?: 'AddendumSironaSlate', +sironaSlate: $ReadOnlyArray<Json> }
      | { +__typename?: 'AddendumTextBlob', +textBlob: string },
    +owner?: ?{ +__typename?: 'Owner', +id: string, +lastName: string },
  },
};

export type SubmitAddendumMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type SubmitAddendumMutation = {
  +__typename?: 'Mutation',
  +submitAddendum: {
    +__typename?: 'Addendum',
    +created: Date,
    +updated: Date,
    +submittedAt?: ?Date,
    +sentAt?: ?Date,
    +smid: string,
    +content:
      | { +__typename?: 'AddendumSironaSlate', +sironaSlate: $ReadOnlyArray<Json> }
      | { +__typename?: 'AddendumTextBlob', +textBlob: string },
    +owner?: ?{ +__typename?: 'Owner', +id: string, +lastName: string },
  },
};

export type DeleteAddendumMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type DeleteAddendumMutation = {
  +__typename?: 'Mutation',
  +deleteAddendum: { +__typename?: 'ConfirmResponse', +confirmed: boolean },
};

export type UpdateMousePreferencesMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  button: $ElementType<Scalars, 'String'>,
};

export type UpdateMousePreferencesMutation = {
  +__typename?: 'Mutation',
  +updateMousePreferences: {
    +__typename?: 'MousePreferences',
    +left: string,
    +middle: string,
    +right: string,
  },
};

export type UpdateMouseScrollDirectionMutationVariables = {
  invertScroll: $ElementType<Scalars, 'Boolean'>,
};

export type UpdateMouseScrollDirectionMutation = {
  +__typename?: 'Mutation',
  +updateMouseScrollDirection: { +__typename?: 'ToolPreferences', +invertScroll: boolean },
};

export type UpdateMouseSensitivityPreferencesMutationVariables = {
  regularScroll?: ?$ElementType<Scalars, 'Float'>,
  fastScroll?: ?$ElementType<Scalars, 'Float'>,
  fineScroll?: ?$ElementType<Scalars, 'Boolean'>,
};

export type UpdateMouseSensitivityPreferencesMutation = {
  +__typename?: 'Mutation',
  +updateMouseSensitivityPreferences: {
    +__typename?: 'ToolPreferences',
    +regularScroll: { +__typename?: 'RegularScrollPreferences', +sensitivity: number },
    +fastScroll: { +__typename?: 'FastScrollPreferences', +sensitivity: number },
    +fineScroll: { +__typename?: 'FineScrollPreferences', +enabled: boolean },
  },
};

export type UpdateWheelPreferencesMutationVariables = {
  ids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
};

export type UpdateWheelPreferencesMutation = {
  +__typename?: 'Mutation',
  +updateWheelPreferences: { +__typename?: 'WheelPreferences', +toolIds: $ReadOnlyArray<string> },
};

export type UpdateKeyboardPreferencesMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  key: $ElementType<Scalars, 'String'>,
  interactionId?: ?$ElementType<Scalars, 'String'>,
};

export type UpdateKeyboardPreferencesMutation = {
  +__typename?: 'Mutation',
  +updateKeyboardPreferences: {
    +__typename?: 'KeyboardPreferences',
    +shortcuts: $ReadOnlyArray<{
      +__typename?: 'Shortcut',
      +id: string,
      +key?: ?string,
      +interactionId?: ?string,
    }>,
  },
};

export type UpdateDictaphonePreferencesMutationVariables = {
  keyMapping?: ?$ReadOnlyArray<IDictaphoneKey> | IDictaphoneKey,
  deviceLabel?: ?$ElementType<Scalars, 'String'>,
  recordWhilePressed?: ?$ElementType<Scalars, 'Boolean'>,
  showPicklistOptionsInEditor?: ?$ElementType<Scalars, 'Boolean'>,
};

export type UpdateDictaphonePreferencesMutation = {
  +__typename?: 'Mutation',
  +updateDictaphonePreferences: {
    +__typename?: 'DictaphonePreferences',
    +deviceLabel: string,
    +recordWhilePressed: boolean,
    +showPicklistOptionsInEditor: boolean,
    +keyMapping: $ReadOnlyArray<{
      +__typename?: 'DictaphoneKey',
      +id: GamepadActionId,
      +key: string,
    }>,
  },
};

export type UpdateBarPreferencesMutationVariables = {
  ids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
};

export type UpdateBarPreferencesMutation = {
  +__typename?: 'Mutation',
  +updateBarPreferences: { +__typename?: 'BarPreferences', +toolIds: $ReadOnlyArray<string> },
};

export type UpdateResetToolOnScrollPreferencesMutationVariables = {
  enabled: $ElementType<Scalars, 'Boolean'>,
};

export type UpdateResetToolOnScrollPreferencesMutation = {
  +__typename?: 'Mutation',
  +updateResetToolOnScrollPreferences: {
    +__typename?: 'ResetToolOnScrollPreferences',
    +enabled: boolean,
  },
};

export type UpdateMirrorViewerLayoutsPreferencesMutationVariables = {
  enabled: $ElementType<Scalars, 'Boolean'>,
};

export type UpdateMirrorViewerLayoutsPreferencesMutation = {
  +__typename?: 'Mutation',
  +updateMirrorViewerLayoutsPreferences: {
    +__typename?: 'MirrorViewerLayoutsPreferences',
    +enabled: boolean,
  },
};

export type UpdateShowIconLabelPreferencesMutationVariables = {
  enabled: $ElementType<Scalars, 'Boolean'>,
};

export type UpdateShowIconLabelPreferencesMutation = {
  +__typename?: 'Mutation',
  +updateShowIconLabelPreferences: { +__typename?: 'ShowIconLabelPreferences', +enabled: boolean },
};

export type UpdateMeMutationVariables = {
  firstName?: ?$ElementType<Scalars, 'String'>,
  lastName?: ?$ElementType<Scalars, 'String'>,
  physicianId?: ?$ElementType<Scalars, 'String'>,
  preferredWorkLocation?: ?PreferredWorkLocationPayload,
};

export type UpdateMeMutation = {
  +__typename?: 'Mutation',
  +updateMe: {
    +__typename?: 'User',
    +firstName: string,
    +lastName: string,
    +physicianId?: ?string,
    +preferredWorkLocation: {
      +__typename?: 'PreferredWorkLocation',
      +hospital: boolean,
      +home: boolean,
    },
  },
};

export type UpdatePasswordMutationVariables = {
  oldPassword: $ElementType<Scalars, 'String'>,
  newPassword: $ElementType<Scalars, 'String'>,
  totp?: ?$ElementType<Scalars, 'String'>,
};

export type UpdatePasswordMutation = {
  +__typename?: 'Mutation',
  +updatePassword: { +__typename?: 'ConfirmResponse', +confirmed: boolean },
};

export type ChangeWorkListItemStatusMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
  claim?: ?$ElementType<Scalars, 'Boolean'>,
  resetReport?: ?$ElementType<Scalars, 'Boolean'>,
};

export type ChangeWorkListItemStatusMutation = {
  +__typename?: 'Mutation',
  +changeWorkListItemStatus?: ?{
    +__typename?: 'WorkListItem',
    +smid: string,
    +claimedBy?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
  },
};

export type ChangeWorkListItemCustomStatusMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
  customStatusID: $ElementType<Scalars, 'ID'>,
};

export type ChangeWorkListItemCustomStatusMutation = {
  +__typename?: 'Mutation',
  +changeWorkListItemCustomStatus?: ?{
    +__typename?: 'WorkListItem',
    +smid: string,
    +customStatus?: ?{ +__typename?: 'WorklistCustomStatus', +smid: string, +name: string },
  },
};

export type UndoChangeWorkListItemCustomStatusMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
  customStatusID: $ElementType<Scalars, 'ID'>,
};

export type UndoChangeWorkListItemCustomStatusMutation = {
  +__typename?: 'Mutation',
  +undoChangeWorkListItemCustomStatus?: ?{
    +__typename?: 'WorkListItem',
    +smid: string,
    +customStatus?: ?{ +__typename?: 'WorklistCustomStatus', +smid: string, +name: string },
  },
};

export type ResetAndReleaseWorklistItemMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type ResetAndReleaseWorklistItemMutation = {
  +__typename?: 'Mutation',
  +resetAndReleaseWorklistItem?: ?{ +__typename?: 'WorkListItem', +smid: string },
};

export type GetStudiesBySmidQueryVariables = {
  smids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
};

export type GetStudiesBySmidQuery = {
  +__typename?: 'Query',
  +studiesBySmid: $ReadOnlyArray<{
    +__typename?: 'Study',
    +smid: string,
    +created: Date,
    +updated: Date,
    +instanceUID: string,
    +description?: ?string,
    +hl7Order?: ?string,
    +accessionNumber?: ?string,
    +seriesCount: number,
    +patientSex?: ?string,
    +patientAge?: ?string,
    +patientName?: ?string,
    +patientDob?: ?Date,
    +modality: string,
    +bodyPart?: ?string,
    +mrn?: ?string,
    +studyDate?: ?Date,
    +laterality?: ?string,
    +seriesList: $ReadOnlyArray<{
      +__typename?: 'Series',
      +smid: string,
      +description?: ?string,
      +seriesInstanceUID: string,
      +seriesDateTimeLocal?: ?DateTimeLocal,
      +frameOfReferenceUID?: ?string,
      +seriesNumber?: ?number,
      +is3Dable: boolean,
      +study: { +__typename?: 'Study', +smid: string },
      +imageRegistrations: $ReadOnlyArray<{
        +__typename?: 'ImageRegistration',
        +smid: string,
        +created: Date,
        +codeVersion: string,
        +modelName: string,
        +modelVersion: string,
        +seriesFixed: string,
        +seriesMoving: string,
        +dataType: string,
        +fixedFrameOfReferenceUid: string,
        +movingFrameOfReferenceUid: string,
        +transformMatrix: [
          [number, number, number, number],
          [number, number, number, number],
          [number, number, number, number],
          [number, number, number, number],
        ],
      }>,
    }>,
    +stackedFrames: $ReadOnlyArray<
      | {
          +__typename?: 'LayeredStack',
          +smid: string,
          +type: string,
          +study: { +__typename?: 'Study', +smid: string },
          +stackLayers: $ReadOnlyArray<{
            +__typename?: 'Layer',
            +stackSmid: string,
            +index: number,
          }>,
        }
      | {
          +__typename?: 'SingleLayerStack',
          +smid: string,
          +type: string,
          +image: string,
          +is3Dable: boolean,
          +frames: $ReadOnlyArray<FrameDataFragment>,
          +series: { +__typename?: 'Series', +smid: string },
          +study: { +__typename?: 'Study', +smid: string },
          +supportedRenderers: { +__typename?: 'SupportedRenderers', +vtk: RendererSupport },
          +imageParams: {
            +__typename?: 'ImageParams',
            +origin: [number, number, number],
            +spacing: [number, number, number],
            +dimensions: [number, number, number],
            +extent: [number, number, number, number, number, number],
            +direction: [number, number, number, number, number, number, number, number, number],
            +isMultiAxes: boolean,
            +colorWindow?: ?number,
            +colorLevel?: ?number,
          },
        },
    >,
  }>,
};

export type CreateTemplateMutationVariables = {
  payload: IReportTemplatePayload,
};

export type CreateTemplateMutation = {
  +__typename?: 'Mutation',
  +createTemplate: { +__typename?: 'ReportTemplate', +id: string },
};

export type UpdateTemplateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  payload: IReportTemplatePayload,
};

export type UpdateTemplateMutation = {
  +__typename?: 'Mutation',
  +updateTemplate: { +__typename?: 'ReportTemplate', +id: string },
};

export type GetTemplatesQueryVariables = {
  cursor?: ?$ElementType<Scalars, 'ID'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  owners?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
  search?: ?$ElementType<Scalars, 'String'>,
  procedures?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
  studyDescriptions?:
    | ?$ReadOnlyArray<$ElementType<Scalars, 'String'>>
    | $ElementType<Scalars, 'String'>,
  autoload?: ?$ElementType<Scalars, 'Boolean'>,
};

export type GetTemplatesQuery = {
  +__typename?: 'Query',
  +templates: {
    +__typename?: 'ReportTemplates',
    +totalCount: number,
    +cursor?: ?string,
    +next?: ?string,
    +items: $ReadOnlyArray<{
      +__typename?: 'ReportTemplate',
      +id: string,
      +name: string,
      +triggerPhrase: string,
      +created: Date,
      +content: Json,
      +owner?: ?{ +__typename?: 'Owner', +id: string, +firstName: string, +lastName: string },
    }>,
  },
};

export type GetTemplateQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};

export type GetTemplateQuery = {
  +__typename?: 'Query',
  +template?: ?{
    +__typename?: 'ReportTemplate',
    +id: string,
    +name: string,
    +triggerPhrase: string,
    +created: Date,
    +content: Json,
    +sections: $ReadOnlyArray<Json>,
    +picklists: $ReadOnlyArray<{
      +__typename?: 'ReportPicklist',
      +id: string,
      +options: $ReadOnlyArray<{
        +__typename?: 'ReportPicklistOption',
        +id: string,
        +name?: ?string,
        +text: $ReadOnlyArray<Json>,
        +default: boolean,
      }>,
    }>,
    +macros: $ReadOnlyArray<{
      +__typename?: 'Macro',
      +smid: string,
      +name: string,
      +triggerPhrase: string,
      +text: $ReadOnlyArray<Json>,
      +created: Date,
      +updated?: ?Date,
      +procedures?: ?$ReadOnlyArray<{
        +__typename?: 'Procedure',
        +smid: string,
        +description: string,
        +code: string,
      }>,
      +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
      +picklists: $ReadOnlyArray<{
        +__typename?: 'ReportPicklist',
        +id: string,
        +options: $ReadOnlyArray<{
          +__typename?: 'ReportPicklistOption',
          +id: string,
          +name?: ?string,
          +text: $ReadOnlyArray<Json>,
          +default: boolean,
        }>,
      }>,
    }>,
    +procedureReferences?: ?$ReadOnlyArray<{
      +__typename?: 'ProcedureReference',
      +smid: string,
      +description?: ?string,
      +code?: ?string,
      +autoload: boolean,
    }>,
  },
};

export type DeleteTemplateMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};

export type DeleteTemplateMutation = {
  +__typename?: 'Mutation',
  +deleteTemplate: { +__typename?: 'DeleteTemplateResponse', +smid: string },
};

export type GetMacrosQueryVariables = {
  owners?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
  cursor?: ?$ElementType<Scalars, 'ID'>,
  limit?: ?$ElementType<Scalars, 'Int'>,
  procedures?: ?$ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
  search?: ?$ElementType<Scalars, 'String'>,
};

export type GetMacrosQuery = {
  +__typename?: 'Query',
  +macros: {
    +__typename?: 'Macros',
    +cursor?: ?string,
    +next?: ?string,
    +items: $ReadOnlyArray<{
      +__typename?: 'Macro',
      +smid: string,
      +name: string,
      +triggerPhrase: string,
      +text: $ReadOnlyArray<Json>,
      +created: Date,
      +updated?: ?Date,
      +procedures?: ?$ReadOnlyArray<{
        +__typename?: 'Procedure',
        +smid: string,
        +description: string,
        +code: string,
      }>,
      +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
    }>,
  },
};

export type GetMacroQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};

export type GetMacroQuery = {
  +__typename?: 'Query',
  +macro: {
    +__typename?: 'Macro',
    +smid: string,
    +name: string,
    +triggerPhrase: string,
    +text: $ReadOnlyArray<Json>,
    +created: Date,
    +updated?: ?Date,
    +procedures?: ?$ReadOnlyArray<{
      +__typename?: 'Procedure',
      +smid: string,
      +description: string,
      +code: string,
    }>,
    +nestedMacros: $ReadOnlyArray<{
      +__typename?: 'Macro',
      +smid: string,
      +name: string,
      +text: $ReadOnlyArray<Json>,
      +picklists: $ReadOnlyArray<{
        +__typename?: 'ReportPicklist',
        +id: string,
        +options: $ReadOnlyArray<{
          +__typename?: 'ReportPicklistOption',
          +id: string,
          +name?: ?string,
          +text: $ReadOnlyArray<Json>,
          +default: boolean,
        }>,
      }>,
    }>,
    +picklists: $ReadOnlyArray<{
      +__typename?: 'ReportPicklist',
      +id: string,
      +options: $ReadOnlyArray<{
        +__typename?: 'ReportPicklistOption',
        +id: string,
        +name?: ?string,
        +text: $ReadOnlyArray<Json>,
        +default: boolean,
      }>,
    }>,
    +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
  },
};

export type CreateMacroMutationVariables = {
  payload: MacroPayload,
};

export type CreateMacroMutation = {
  +__typename?: 'Mutation',
  +createMacro: { +__typename?: 'Macro', +smid: string },
};

export type UpdateMacroMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  payload: MacroPayload,
};

export type UpdateMacroMutation = {
  +__typename?: 'Mutation',
  +updateMacro: { +__typename?: 'Macro', +smid: string },
};

export type DeleteMacroMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};

export type DeleteMacroMutation = {
  +__typename?: 'Mutation',
  +deleteMacro: { +__typename?: 'DeleteMacroResponse', +smid: string },
};

export type CreateWwwcToolPresetMutationVariables = {
  payload: WwwcPresetPayload,
};

export type CreateWwwcToolPresetMutation = {
  +__typename?: 'Mutation',
  +createWwwcToolPreset?: ?{ +__typename?: 'WwwcPreset', +id: string },
};

export type UpdateWwwcToolPresetMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
  payload: WwwcPresetPayload,
};

export type UpdateWwwcToolPresetMutation = {
  +__typename?: 'Mutation',
  +updateWwwcToolPreset?: ?{ +__typename?: 'WwwcPreset', +id: string },
};

export type DeleteWwwcToolPresetMutationVariables = {
  id: $ElementType<Scalars, 'ID'>,
};

export type DeleteWwwcToolPresetMutation = {
  +__typename?: 'Mutation',
  +deleteWwwcToolPreset: { +__typename?: 'WwcPresetDeleteResponse', +id: string },
};

export type SetWwwcDragDirectionMutationVariables = {
  value: $ElementType<Scalars, 'Boolean'>,
};

export type SetWwwcDragDirectionMutation = {
  +__typename?: 'Mutation',
  +setWwwcDragDirection: {
    +__typename?: 'SetWwwcDragDirectionResponse',
    +invertDragDirection: boolean,
  },
};

export type ConfirmAccountMutationVariables = {
  userId: $ElementType<Scalars, 'String'>,
  token: $ElementType<Scalars, 'ID'>,
  password: $ElementType<Scalars, 'String'>,
};

export type ConfirmAccountMutation = {
  +__typename?: 'Mutation',
  +confirmAccount: { +__typename?: 'ConfirmResponse', +confirmed: boolean },
};

export type RequestPasswordResetMutationVariables = {
  email: $ElementType<Scalars, 'String'>,
};

export type RequestPasswordResetMutation = {
  +__typename?: 'Mutation',
  +requestPasswordReset: { +__typename?: 'ConfirmResponse', +confirmed: boolean },
};

export type ResetPasswordMutationVariables = {
  token: $ElementType<Scalars, 'ID'>,
  userId: $ElementType<Scalars, 'ID'>,
  password: $ElementType<Scalars, 'String'>,
};

export type ResetPasswordMutation = {
  +__typename?: 'Mutation',
  +resetPassword: { +__typename?: 'ConfirmResponse', +confirmed: boolean },
};

export type GetReportQueryVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type GetReportQuery = {
  +__typename?: 'Query',
  +report: {
    +__typename?: 'Report',
    +smid: string,
    +submittedAt?: ?Date,
    +sections: Json,
    +content?: ?Json,
    +sectionsLastUpdated?: ?Date,
    +macros: $ReadOnlyArray<{
      +__typename?: 'Macro',
      +smid: string,
      +name: string,
      +triggerPhrase: string,
      +text: $ReadOnlyArray<Json>,
      +created: Date,
      +updated?: ?Date,
      +procedures?: ?$ReadOnlyArray<{
        +__typename?: 'Procedure',
        +smid: string,
        +description: string,
        +code: string,
      }>,
      +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
      +picklists: $ReadOnlyArray<{
        +__typename?: 'ReportPicklist',
        +id: string,
        +options: $ReadOnlyArray<{
          +__typename?: 'ReportPicklistOption',
          +id: string,
          +name?: ?string,
          +text: $ReadOnlyArray<Json>,
          +default: boolean,
        }>,
      }>,
    }>,
  },
};

export type GetReportsQueryVariables = {
  accessionNumbers: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
};

export type GetReportsQuery = {
  +__typename?: 'Query',
  +reports: $ReadOnlyArray<{
    +__typename?: 'Report',
    +sections: Json,
    +content?: ?Json,
    +format: ReportFormat,
    +smid: string,
    +submittedAt?: ?Date,
    +sectionsLastUpdated?: ?Date,
    +addendums: $ReadOnlyArray<{
      +__typename?: 'Addendum',
      +created: Date,
      +updated: Date,
      +submittedAt?: ?Date,
      +sentAt?: ?Date,
      +smid: string,
      +content:
        | { +__typename?: 'AddendumSironaSlate', +sironaSlate: $ReadOnlyArray<Json> }
        | { +__typename?: 'AddendumTextBlob', +textBlob: string },
      +owner?: ?{ +__typename?: 'Owner', +id: string, +lastName: string },
    }>,
    +macros: $ReadOnlyArray<{
      +__typename?: 'Macro',
      +smid: string,
      +name: string,
      +triggerPhrase: string,
      +text: $ReadOnlyArray<Json>,
      +created: Date,
      +updated?: ?Date,
      +procedures?: ?$ReadOnlyArray<{
        +__typename?: 'Procedure',
        +smid: string,
        +description: string,
        +code: string,
      }>,
      +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
      +picklists: $ReadOnlyArray<{
        +__typename?: 'ReportPicklist',
        +id: string,
        +options: $ReadOnlyArray<{
          +__typename?: 'ReportPicklistOption',
          +id: string,
          +name?: ?string,
          +text: $ReadOnlyArray<Json>,
          +default: boolean,
        }>,
      }>,
    }>,
  }>,
};

export type GetPatientHistoryListQueryVariables = {
  mrn: $ReadOnlyArray<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
  sortColumn?: ?SortColumn,
  sortOrder?: ?SortOrder,
};

export type GetPatientHistoryListQuery = {
  +__typename?: 'Query',
  +workListItems: {
    +__typename?: 'WorkListItems',
    +items: $ReadOnlyArray<{
      +__typename?: 'WorkListItem',
      +smid: string,
      +patientName?: ?string,
      +studyDate?: ?Date,
      +created?: ?Date,
      +bodyPart?: ?string,
      +modality?: ?string,
      +status: WorkListItemStatus,
      +mrn?: ?string,
      +studyDescription?: ?string,
      +dosage?: ?string,
      +studyReason?: ?string,
      +techNotes?: ?string,
      +patientSex?: ?string,
      +patientDob?: ?Date,
      +patientAge?: ?string,
      +accessionNumber?: ?string,
      +studies: $ReadOnlyArray<{ +__typename?: 'Study', +smid: string }>,
      +claimedBy?: ?{ +__typename?: 'User', +id: string },
      +report?: ?{
        +__typename?: 'Report',
        +smid: string,
        +worklistitem?: ?string,
        +content?: ?Json,
      },
    }>,
  },
};

export type GetBaseViewerDataQueryVariables = {
  worklistIds: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
  studyIds: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
  hasWorklistIds: $ElementType<Scalars, 'Boolean'>,
  hasStudyIds: $ElementType<Scalars, 'Boolean'>,
};

export type GetBaseViewerDataQuery = {
  +__typename?: 'Query',
  +workListItems?: {
    +__typename?: 'WorkListItems',
    +items: $ReadOnlyArray<{
      +__typename?: 'WorkListItem',
      +smid: string,
      +studies: $ReadOnlyArray<{
        +__typename?: 'Study',
        +smid: string,
        +created: Date,
        +updated: Date,
        +instanceUID: string,
        +description?: ?string,
        +hl7Order?: ?string,
        +accessionNumber?: ?string,
        +seriesCount: number,
        +patientSex?: ?string,
        +patientAge?: ?string,
        +patientName?: ?string,
        +patientDob?: ?Date,
        +modality: string,
        +bodyPart?: ?string,
        +mrn?: ?string,
        +studyDate?: ?Date,
        +laterality?: ?string,
        +seriesList: $ReadOnlyArray<{
          +__typename?: 'Series',
          +smid: string,
          +description?: ?string,
          +seriesInstanceUID: string,
          +seriesDateTimeLocal?: ?DateTimeLocal,
          +frameOfReferenceUID?: ?string,
          +seriesNumber?: ?number,
          +is3Dable: boolean,
          +study: { +__typename?: 'Study', +smid: string },
          +imageRegistrations: $ReadOnlyArray<{
            +__typename?: 'ImageRegistration',
            +smid: string,
            +created: Date,
            +codeVersion: string,
            +modelName: string,
            +modelVersion: string,
            +seriesFixed: string,
            +seriesMoving: string,
            +dataType: string,
            +fixedFrameOfReferenceUid: string,
            +movingFrameOfReferenceUid: string,
            +transformMatrix: [
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
            ],
          }>,
        }>,
        +stackedFrames: $ReadOnlyArray<
          | {
              +__typename?: 'LayeredStack',
              +smid: string,
              +type: string,
              +study: { +__typename?: 'Study', +smid: string },
              +stackLayers: $ReadOnlyArray<{
                +__typename?: 'Layer',
                +stackSmid: string,
                +index: number,
              }>,
            }
          | {
              +__typename?: 'SingleLayerStack',
              +smid: string,
              +type: string,
              +image: string,
              +is3Dable: boolean,
              +frames: $ReadOnlyArray<FrameDataFragment>,
              +series: { +__typename?: 'Series', +smid: string },
              +study: { +__typename?: 'Study', +smid: string },
              +supportedRenderers: { +__typename?: 'SupportedRenderers', +vtk: RendererSupport },
              +imageParams: {
                +__typename?: 'ImageParams',
                +origin: [number, number, number],
                +spacing: [number, number, number],
                +dimensions: [number, number, number],
                +extent: [number, number, number, number, number, number],
                +direction: [
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                ],
                +isMultiAxes: boolean,
                +colorWindow?: ?number,
                +colorLevel?: ?number,
              },
            },
        >,
      }>,
    }>,
  },
  +studiesBySmid?: $ReadOnlyArray<{
    +__typename?: 'Study',
    +smid: string,
    +created: Date,
    +updated: Date,
    +instanceUID: string,
    +description?: ?string,
    +hl7Order?: ?string,
    +accessionNumber?: ?string,
    +seriesCount: number,
    +patientSex?: ?string,
    +patientAge?: ?string,
    +patientName?: ?string,
    +patientDob?: ?Date,
    +modality: string,
    +bodyPart?: ?string,
    +mrn?: ?string,
    +studyDate?: ?Date,
    +laterality?: ?string,
    +seriesList: $ReadOnlyArray<{
      +__typename?: 'Series',
      +smid: string,
      +description?: ?string,
      +seriesInstanceUID: string,
      +seriesDateTimeLocal?: ?DateTimeLocal,
      +frameOfReferenceUID?: ?string,
      +seriesNumber?: ?number,
      +is3Dable: boolean,
      +study: { +__typename?: 'Study', +smid: string },
      +imageRegistrations: $ReadOnlyArray<{
        +__typename?: 'ImageRegistration',
        +smid: string,
        +created: Date,
        +codeVersion: string,
        +modelName: string,
        +modelVersion: string,
        +seriesFixed: string,
        +seriesMoving: string,
        +dataType: string,
        +fixedFrameOfReferenceUid: string,
        +movingFrameOfReferenceUid: string,
        +transformMatrix: [
          [number, number, number, number],
          [number, number, number, number],
          [number, number, number, number],
          [number, number, number, number],
        ],
      }>,
    }>,
    +stackedFrames: $ReadOnlyArray<
      | {
          +__typename?: 'LayeredStack',
          +smid: string,
          +type: string,
          +study: { +__typename?: 'Study', +smid: string },
          +stackLayers: $ReadOnlyArray<{
            +__typename?: 'Layer',
            +stackSmid: string,
            +index: number,
          }>,
        }
      | {
          +__typename?: 'SingleLayerStack',
          +smid: string,
          +type: string,
          +image: string,
          +is3Dable: boolean,
          +frames: $ReadOnlyArray<FrameDataFragment>,
          +series: { +__typename?: 'Series', +smid: string },
          +study: { +__typename?: 'Study', +smid: string },
          +supportedRenderers: { +__typename?: 'SupportedRenderers', +vtk: RendererSupport },
          +imageParams: {
            +__typename?: 'ImageParams',
            +origin: [number, number, number],
            +spacing: [number, number, number],
            +dimensions: [number, number, number],
            +extent: [number, number, number, number, number, number],
            +direction: [number, number, number, number, number, number, number, number, number],
            +isMultiAxes: boolean,
            +colorWindow?: ?number,
            +colorLevel?: ?number,
          },
        },
    >,
  }>,
  +matchHangingProtocolByWorkListsAndStudies?: ?{
    +__typename?: 'HangingProtocol',
    +smid: string,
    +name: string,
    +numberOfPriors: number,
    +created: Date,
    +updated: Date,
    +studyDescriptions: $ReadOnlyArray<string>,
    +data: {
      +__typename?: 'HangingProtocolData',
      +layouts: $ReadOnlyArray<{
        +__typename?: 'HangingProtocolLayout',
        +id: string,
        +layout: $ReadOnlyArray<number>,
      }>,
    },
    +viewportsConfigurations?: ?$ReadOnlyArray<{
      +__typename?: 'ViewportConfiguration',
      +id: string,
      +configuration: {
        +__typename?: 'ViewportConfigurationData',
        +viewType?: ?ViewType,
        +comparisonIndex?: ?number,
        +seriesDescriptions: $ReadOnlyArray<string>,
        +study?: ?{
          +__typename?: 'Study',
          +smid: string,
          +created: Date,
          +updated: Date,
          +instanceUID: string,
          +description?: ?string,
          +hl7Order?: ?string,
          +accessionNumber?: ?string,
          +seriesCount: number,
          +patientSex?: ?string,
          +patientAge?: ?string,
          +patientName?: ?string,
          +patientDob?: ?Date,
          +modality: string,
          +bodyPart?: ?string,
          +mrn?: ?string,
          +studyDate?: ?Date,
          +laterality?: ?string,
          +seriesList: $ReadOnlyArray<{
            +__typename?: 'Series',
            +smid: string,
            +description?: ?string,
            +seriesInstanceUID: string,
            +seriesDateTimeLocal?: ?DateTimeLocal,
            +frameOfReferenceUID?: ?string,
            +seriesNumber?: ?number,
            +is3Dable: boolean,
            +study: { +__typename?: 'Study', +smid: string },
            +imageRegistrations: $ReadOnlyArray<{
              +__typename?: 'ImageRegistration',
              +smid: string,
              +created: Date,
              +codeVersion: string,
              +modelName: string,
              +modelVersion: string,
              +seriesFixed: string,
              +seriesMoving: string,
              +dataType: string,
              +fixedFrameOfReferenceUid: string,
              +movingFrameOfReferenceUid: string,
              +transformMatrix: [
                [number, number, number, number],
                [number, number, number, number],
                [number, number, number, number],
                [number, number, number, number],
              ],
            }>,
          }>,
          +stackedFrames: $ReadOnlyArray<
            | {
                +__typename?: 'LayeredStack',
                +smid: string,
                +type: string,
                +study: { +__typename?: 'Study', +smid: string },
                +stackLayers: $ReadOnlyArray<{
                  +__typename?: 'Layer',
                  +stackSmid: string,
                  +index: number,
                }>,
              }
            | {
                +__typename?: 'SingleLayerStack',
                +smid: string,
                +type: string,
                +image: string,
                +is3Dable: boolean,
                +frames: $ReadOnlyArray<FrameDataFragment>,
                +series: { +__typename?: 'Series', +smid: string },
                +study: { +__typename?: 'Study', +smid: string },
                +supportedRenderers: { +__typename?: 'SupportedRenderers', +vtk: RendererSupport },
                +imageParams: {
                  +__typename?: 'ImageParams',
                  +origin: [number, number, number],
                  +spacing: [number, number, number],
                  +dimensions: [number, number, number],
                  +extent: [number, number, number, number, number, number],
                  +direction: [
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                    number,
                  ],
                  +isMultiAxes: boolean,
                  +colorWindow?: ?number,
                  +colorLevel?: ?number,
                },
              },
          >,
        },
        +series?: ?{
          +__typename?: 'Series',
          +smid: string,
          +description?: ?string,
          +seriesInstanceUID: string,
          +seriesDateTimeLocal?: ?DateTimeLocal,
          +frameOfReferenceUID?: ?string,
          +seriesNumber?: ?number,
          +is3Dable: boolean,
          +study: { +__typename?: 'Study', +smid: string },
          +imageRegistrations: $ReadOnlyArray<{
            +__typename?: 'ImageRegistration',
            +smid: string,
            +created: Date,
            +codeVersion: string,
            +modelName: string,
            +modelVersion: string,
            +seriesFixed: string,
            +seriesMoving: string,
            +dataType: string,
            +fixedFrameOfReferenceUid: string,
            +movingFrameOfReferenceUid: string,
            +transformMatrix: [
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
            ],
          }>,
        },
        +stack?:
          | ?{ +__typename?: 'LayeredStack', +smid: string }
          | { +__typename?: 'SingleLayerStack', +smid: string },
      },
    }>,
  },
  +presentationStates: $ReadOnlyArray<{
    +__typename?: 'GspsPresentationState',
    +smid: string,
    +created?: ?DateTimeLocal,
    +name?: ?string,
    +owner?: ?{ +__typename?: 'GspsOwner', +firstName?: ?string, +lastName?: ?string },
    +study: { +__typename?: 'Study', +smid: string, +description?: ?string },
    +annotations: $ReadOnlyArray<{ +__typename?: 'GspsAnnotation', +smid: string }>,
  }>,
};

export type GetStackSignedUrlsQueryVariables = {
  stackSmid: $ElementType<Scalars, 'ID'>,
  isMultiFrame: $ElementType<Scalars, 'Boolean'>,
  frames: $ReadOnlyArray<FrameToLoad> | FrameToLoad,
};

export type GetStackSignedUrlsQuery = {
  +__typename?: 'Query',
  +pixelURLs: $ReadOnlyArray<{
    +__typename?: 'PixelURL',
    +frameSmid: string,
    +fallbackUrl: string,
    +signedUrl: { +__typename?: 'SignedUrl', +url: string, +expirationTime: number },
  }>,
};

export type GetWorkspacesPreferencesQueryVariables = {};

export type GetWorkspacesPreferencesQuery = {
  +__typename?: 'Query',
  +toolPreferences: {
    +__typename?: 'ToolPreferences',
    +workspaces: {
      +__typename?: 'WorkspacePreferences',
      +autoApply: boolean,
      +presets: $ReadOnlyArray<{
        +__typename?: 'WorkspacePreset',
        +smid: string,
        +instanceId: string,
        +name: string,
        +createdAt: Date,
        +updatedAt: Date,
        +windows: $ReadOnlyArray<{
          +__typename?: 'Window',
          +id: string,
          +width: number,
          +height: number,
          +left: number,
          +top: number,
          +screen: ScreenName,
          +type: WindowType,
          +state: WindowState,
        }>,
      }>,
    },
  },
};

export type CancelReportMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type CancelReportMutation = {
  +__typename?: 'Mutation',
  +cancelReport: { +__typename?: 'Report', +smid: string, +submittedAt?: ?Date },
};

export type SubmitReportMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type SubmitReportMutation = {
  +__typename?: 'Mutation',
  +submitReport:
    | {
        +__typename: 'SubmitReportPayload',
        +report: { +__typename?: 'Report', +smid: string, +submittedAt?: ?Date },
      }
    | {
        +__typename: 'SubmitReportProblem',
        +unknownProblem?: ?string,
        +invalidInputs: $ReadOnlyArray<?{
          +__typename?: 'SubmitReportInvalidInput',
          +field: SubmitReportInvalidInputField,
          +message: string,
        }>,
      },
};

export type CreateWorkspacePresetMutationVariables = {
  payload: WorkspacePresetPayload,
};

export type CreateWorkspacePresetMutation = {
  +__typename?: 'Mutation',
  +createWorkspacePreset: {
    +__typename?: 'WorkspacePreset',
    +smid: string,
    +instanceId: string,
    +name: string,
    +createdAt: Date,
    +updatedAt: Date,
    +windows: $ReadOnlyArray<{
      +__typename?: 'Window',
      +id: string,
      +width: number,
      +height: number,
      +left: number,
      +top: number,
      +screen: ScreenName,
      +type: WindowType,
      +state: WindowState,
    }>,
  },
};

export type UpdateWorkspacePresetMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
  payload: WorkspacePresetPayload,
};

export type UpdateWorkspacePresetMutation = {
  +__typename?: 'Mutation',
  +updateWorkspacePreset: {
    +__typename?: 'WorkspacePreset',
    +smid: string,
    +instanceId: string,
    +name: string,
    +createdAt: Date,
    +updatedAt: Date,
    +windows: $ReadOnlyArray<{
      +__typename?: 'Window',
      +id: string,
      +width: number,
      +height: number,
      +left: number,
      +top: number,
      +screen: ScreenName,
      +type: WindowType,
      +state: WindowState,
    }>,
  },
};

export type SetWorkspaceAutoApplyMutationVariables = {
  value: $ElementType<Scalars, 'Boolean'>,
};

export type SetWorkspaceAutoApplyMutation = {
  +__typename?: 'Mutation',
  +setWorkspaceAutoApply: { +__typename?: 'WorkspacePreferences', +autoApply: boolean },
};

export type DeleteWorkspacePresetMutationVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type DeleteWorkspacePresetMutation = {
  +__typename?: 'Mutation',
  +deleteWorkspacePreset: { +__typename?: 'ConfirmDeleteWorkspacePreset', +smid: string },
};

export type UpdateReporterSettingsMutationVariables = {
  triggerWord?: ?$ElementType<Scalars, 'String'>,
  styles?: ?StylesInput,
  cursorStyle?: ?CursorStyleInput,
  lineIndicator?: ?LineIndicatorInput,
  mergeFieldsSettings?: ?MergeFieldsSettingsInput,
  aiMode?: ?AiModeInput,
  impressionGenerator?: ?ImpressionGeneratorInput,
  autoFillComparison?: ?AutoFillComparisonInput,
};

export type UpdateReporterSettingsMutation = {
  +__typename?: 'Mutation',
  +updateReporterSettings: {
    +__typename?: 'ReporterSettings',
    +triggerWord: string,
    +aiMode: {
      +__typename?: 'AIModeSettings',
      +findingsMapperEnabled: boolean,
      +formatImpressionGenerationAsList: boolean,
      +onDemandImpressionGeneration: boolean,
    },
    +impressionGenerator: {
      +__typename?: 'ImpressionGenerator',
      +automatic: boolean,
      +enabled: boolean,
      +modalities: $ReadOnlyArray<string>,
    },
    +mergeFieldsSettings: {
      +__typename?: 'MergeFieldsSettings',
      +ignoreNavigation: boolean,
      +ignoreDefaultSelection: boolean,
    },
    +styles: {
      +__typename?: 'Styles',
      +bodyStyle: {
        +__typename?: 'TextStyle',
        +fontSize?: ?number,
        +fontFamily?: ?string,
        +textTransform?: ?TextTransform,
        +textDecoration?: ?{
          +__typename?: 'TextDecoration',
          +isBold?: ?boolean,
          +isItalic?: ?boolean,
          +isUnderline?: ?boolean,
        },
      },
      +headingStyles: $ReadOnlyArray<{
        +__typename?: 'HeadingStyle',
        +level: HeadingLevel,
        +textStyle: {
          +__typename?: 'TextStyle',
          +fontSize?: ?number,
          +textTransform?: ?TextTransform,
          +textDecoration?: ?{
            +__typename?: 'TextDecoration',
            +isBold?: ?boolean,
            +isItalic?: ?boolean,
            +isUnderline?: ?boolean,
          },
        },
      }>,
    },
    +cursorStyle: { +__typename?: 'CursorStyle', +color: string },
    +lineIndicator: {
      +__typename?: 'LineIndicator',
      +enabled: boolean,
      +placement: LineIndicatorPlacement,
      +variant: LineIndicatorVariant,
    },
    +autoFillComparison: { +__typename?: 'AutoFillComparison', +enabled: boolean },
  },
};

export type DeleteUserLinkedAiAnnotationsMutationVariables = {
  payload: $ReadOnlyArray<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
};

export type DeleteUserLinkedAiAnnotationsMutation = {
  +__typename?: 'Mutation',
  +deleteUserLinkedAiAnnotations?: ?{ +__typename?: 'Response', +status?: ?number },
};

export type RejectAiAnnotationsMutationVariables = {
  ids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
};

export type RejectAiAnnotationsMutation = {
  +__typename?: 'Mutation',
  +rejectAiAnnotations: { +__typename?: 'RejectAiAnnotationLog', +logIds: $ReadOnlyArray<string> },
};

export type AcceptAiAnnotationsMutationVariables = {
  annotationsByGroup: $ReadOnlyArray<AcceptAiAnnotation> | AcceptAiAnnotation,
};

export type AcceptAiAnnotationsMutation = {
  +__typename?: 'Mutation',
  +acceptAiAnnotations: {
    +__typename?: 'AcceptAiAnnotationResponse',
    +logIds: $ReadOnlyArray<string>,
    +annotations: $ReadOnlyArray<{
      +__typename?: 'AnnotationLogResponse',
      +annotationSmid: string,
    }>,
  },
};

export type GetBodyPartsQueryVariables = {};

export type GetBodyPartsQuery = {
  +__typename?: 'Query',
  +bodyParts: $ReadOnlyArray<{ +__typename?: 'BodyPart', +name: string }>,
};

export type GetModalitiesQueryVariables = {};

export type GetModalitiesQuery = {
  +__typename?: 'Query',
  +modalities: $ReadOnlyArray<{ +__typename?: 'Modality', +name: string }>,
};

export type GetSeriesTagsQueryVariables = {
  smids: $ReadOnlyArray<$ElementType<Scalars, 'ID'>> | $ElementType<Scalars, 'ID'>,
  tagIds?: ?$ReadOnlyArray<$ElementType<Scalars, 'String'>> | $ElementType<Scalars, 'String'>,
};

export type GetSeriesTagsQuery = {
  +__typename?: 'Query',
  +seriesBySmid: $ReadOnlyArray<{
    +__typename?: 'Series',
    +smid: string,
    +dicomTags: $ReadOnlyArray<{
      +__typename?: 'DicomTag',
      +id: string,
      +vr: string,
      +name: string,
      +values: $ReadOnlyArray<{ +__typename?: 'DicomTagValue', +key?: ?string, +value?: ?Json }>,
    }>,
  }>,
};

export type GetInstanceTagsQueryVariables = {
  seriesSmid: $ElementType<Scalars, 'ID'>,
  instanceSmid: $ElementType<Scalars, 'ID'>,
};

export type GetInstanceTagsQuery = {
  +__typename?: 'Query',
  +dicomInstance?: ?{
    +__typename?: 'DicomInstance',
    +smid: string,
    +dicomTags: $ReadOnlyArray<{
      +__typename?: 'DicomTag',
      +id: string,
      +vr: string,
      +name: string,
      +values: $ReadOnlyArray<{ +__typename?: 'DicomTagValue', +key?: ?string, +value?: ?Json }>,
    }>,
  },
};

export type CreateAutoCorrectEntryMutationVariables = {
  input: CreateAutoCorrectEntryInput,
};

export type CreateAutoCorrectEntryMutation = {
  +__typename?: 'Mutation',
  +createAutoCorrectEntry: {
    +__typename?: 'CreateAutoCorrectEntryResponse',
    +entry?: ?{ +__typename?: 'AutoCorrectEntry', +id: string, +key: string, +value: string },
  },
};

export type DeleteAutoCorrectEntryMutationVariables = {
  input: DeleteAutoCorrectEntryInput,
};

export type DeleteAutoCorrectEntryMutation = {
  +__typename?: 'Mutation',
  +deleteAutoCorrectEntry: { +__typename?: 'DeleteAutoCorrectEntryResponse', +id: string },
};

export type UpdateAutoCorrectEntryMutationVariables = {
  input: UpdateAutoCorrectEntryInput,
};

export type UpdateAutoCorrectEntryMutation = {
  +__typename?: 'Mutation',
  +updateAutoCorrectEntry: {
    +__typename?: 'UpdateAutoCorrectEntryResponse',
    +entry?: ?{ +__typename?: 'AutoCorrectEntry', +id: string, +key: string, +value: string },
  },
};

export type GetVocabEntriesQueryVariables = {};

export type GetVocabEntriesQuery = {
  +__typename?: 'Query',
  +vocabEntries: $ReadOnlyArray<{ +__typename?: 'VocabEntry', +id: string, +phrase: string }>,
};

export type CreateVocabEntryMutationVariables = {
  input: CreateVocabEntryInput,
};

export type CreateVocabEntryMutation = {
  +__typename?: 'Mutation',
  +createVocabEntry: {
    +__typename?: 'CreateVocabEntryResponse',
    +entry?: ?{ +__typename?: 'VocabEntry', +id: string, +phrase: string },
  },
};

export type DeleteVocabEntryMutationVariables = {
  input: DeleteVocabEntryInput,
};

export type DeleteVocabEntryMutation = {
  +__typename?: 'Mutation',
  +deleteVocabEntry: { +__typename?: 'DeleteVocabEntryResponse', +id: string },
};

export type GetToolsQueryVariables = {};

export type GetToolsQuery = {
  +__typename?: 'Query',
  +tools: $ReadOnlyArray<{
    +__typename?: 'Tool',
    +id: string,
    +name: string,
    +category: ToolCategory,
    +description: string,
    +icon: string,
    +payloadCapabilities: $ReadOnlyArray<ToolPayloadCapability>,
    +interactions: $ReadOnlyArray<
      | {
          +__typename?: 'ToolAnnotationInteraction',
          +type: ToolInteractionType,
          +id: string,
          +maxConcurrentRequests?: ?number,
          +targetAnnotationId?: ?string,
          +targetAnnotationGroupId?: ?string,
          +button: MouseButton,
          +action: MouseAction,
          +request: {
            +__typename?: 'ToolInteractionRequest',
            +url: string,
            +method: ToolInteractionRequestMethod,
          },
        }
      | {
          +__typename?: 'ToolContextMenuOptionInteraction',
          +type: ToolInteractionType,
          +id: string,
          +maxConcurrentRequests?: ?number,
          +label: string,
          +request: {
            +__typename?: 'ToolInteractionRequest',
            +url: string,
            +method: ToolInteractionRequestMethod,
          },
        }
      | {
          +__typename?: 'ToolIconButtonClickInteraction',
          +type: ToolInteractionType,
          +id: string,
          +maxConcurrentRequests?: ?number,
          +request: {
            +__typename?: 'ToolInteractionRequest',
            +url: string,
            +method: ToolInteractionRequestMethod,
          },
        }
      | {
          +__typename?: 'ToolKeyboardShortcutInteraction',
          +type: ToolInteractionType,
          +id: string,
          +maxConcurrentRequests?: ?number,
          +keyboardShortcut: string,
          +request: {
            +__typename?: 'ToolInteractionRequest',
            +url: string,
            +method: ToolInteractionRequestMethod,
          },
        }
      | {
          +__typename?: 'ToolMergeFieldInteraction',
          +type: ToolInteractionType,
          +id: string,
          +maxConcurrentRequests?: ?number,
          +label: string,
          +request: {
            +__typename?: 'ToolInteractionRequest',
            +url: string,
            +method: ToolInteractionRequestMethod,
          },
        }
      | {
          +__typename?: 'ToolViewportClickInteraction',
          +type: ToolInteractionType,
          +id: string,
          +maxConcurrentRequests?: ?number,
          +button: MouseButton,
          +cursorIcon: string,
          +request: {
            +__typename?: 'ToolInteractionRequest',
            +url: string,
            +method: ToolInteractionRequestMethod,
          },
        }
      | {
          +__typename?: 'ToolVoiceCommandInteraction',
          +type: ToolInteractionType,
          +id: string,
          +maxConcurrentRequests?: ?number,
          +triggerPhrase: string,
          +request: {
            +__typename?: 'ToolInteractionRequest',
            +url: string,
            +method: ToolInteractionRequestMethod,
          },
        },
    >,
    +customAnnotationsLogic: $ReadOnlyArray<{
      +__typename?: 'CustomAnnotationLogic',
      +id: string,
      +logic: string,
    }>,
  }>,
};

export type TriggerToolInteractionMutationVariables = {
  payload: ToolInteractionPayload,
  toolId: $ElementType<Scalars, 'ID'>,
  toolInteractionId: $ElementType<Scalars, 'ID'>,
  toolInteractionType: ToolInteractionType,
};

export type TriggerToolInteractionMutation = {
  +__typename?: 'Mutation',
  +triggerToolInteraction: $ReadOnlyArray<
    | { +__typename?: 'ToolInteractionAddInteractionResponse' }
    | {
        +__typename?: 'ToolInteractionAnnotationResponse',
        +type: ToolInteractionResponseType,
        +annotation: {
          +__typename?: 'ToolInteractionAnnotationData',
          +type: AnnotationTypes,
          +groupId?: ?string,
          +label?: ?string,
          +style?: ?{ +__typename?: 'AnnotationStyle', +color?: ?string, +opacity?: ?number },
          +allowedTransformations?: ?{
            +__typename?: 'AnnotationEditableOptions',
            +move?: ?boolean,
            +transform?: ?boolean,
          },
          +segments: $ReadOnlyArray<{
            +__typename?: 'AnnotationSegment',
            +start:
              | { +__typename?: 'IndexCoordinates', +indexSpace: [number, number, number] }
              | { +__typename?: 'WorldCoordinates', +worldSpace: [number, number, number] },
            +end:
              | { +__typename?: 'IndexCoordinates', +indexSpace: [number, number, number] }
              | { +__typename?: 'WorldCoordinates', +worldSpace: [number, number, number] },
          }>,
          +customLogic?: ?{
            +__typename?: 'CustomAnnotationLogic',
            +id: string,
            +toolId: string,
            +logic: string,
          },
        },
      }
    | { +__typename?: 'ToolInteractionInsertTextResponse' }
    | { +__typename?: 'ToolInteractionKeyboardShortcutResponse' }
    | {
        +__typename?: 'ToolInteractionMessageResponse',
        +type: ToolInteractionResponseType,
        +message: string,
        +display: MessageDisplay,
        +severity: MessageSeverity,
      }
    | {
        +__typename?: 'ToolInteractionStartAnnotationResponse',
        +type: ToolInteractionResponseType,
        +annotationType: string,
      }
    | {
        +__typename?: 'ToolInteractionUpdateToolActiveStateResponse',
        +type: ToolInteractionResponseType,
        +active: boolean,
      }
    | { +__typename?: 'ToolInteractionUpdateToolbarButtonIconResponse' }
    | {
        +__typename?: 'ToolInteractionUpdateViewportPresentationStateResponse',
        +viewportId?: ?string,
        +type: ToolInteractionResponseType,
        +window?: ?number,
        +level?: ?number,
        +zoom?: ?number,
        +rotate?: ?number,
      }
    | {
        +__typename?: 'ToolInteractionUpdateViewportSliceResponse',
        +type: ToolInteractionResponseType,
        +viewportId?: ?string,
        +slice: number,
      }
    | {
        +__typename?: 'ToolInteractionWidgetResponse',
        +type: ToolInteractionResponseType,
        +widget: {
          +__typename?: 'WidgetConfiguration',
          +id: string,
          +title: string,
          +type: WidgetType,
          +placement?: ?WidgetPlacement,
          +urlPath?: ?string,
          +data?: ?Json,
          +dismissActions?: ?$ReadOnlyArray<WidgetDismissAction>,
          +position?:
            | ?{ +__typename?: 'IndexCoordinates', +indexSpace: [number, number, number] }
            | { +__typename?: 'WorldCoordinates', +worldSpace: [number, number, number] },
        },
      },
  >,
};

export type GetWorkListViewsQueryVariables = {};

export type GetWorkListViewsQuery = {
  +__typename?: 'Query',
  +workListViews: $ReadOnlyArray<{ +__typename?: 'WorkListView', +smid: string, +name: string }>,
};

export type GetCustomAnnotationLogicQueryVariables = {
  toolId: $ElementType<Scalars, 'ID'>,
  type: $ElementType<Scalars, 'String'>,
};

export type GetCustomAnnotationLogicQuery = {
  +__typename?: 'Query',
  +customAnnotationLogic?: ?{ +__typename?: 'CustomAnnotationLogic', +id: string, +logic: string },
};

export type GetCurrentWorkListReportQueryVariables = {
  smid: $ElementType<Scalars, 'ID'>,
};

export type GetCurrentWorkListReportQuery = {
  +__typename?: 'Query',
  +workListItem?: ?{
    +__typename?: 'WorkListItem',
    +smid: string,
    +mrn?: ?string,
    +status: WorkListItemStatus,
    +dosage?: ?string,
    +studyReason?: ?string,
    +studyDescription?: ?string,
    +studyDate?: ?Date,
    +referringPhysician?: ?string,
    +accessionNumber?: ?string,
    +techNotes?: ?string,
    +patientName?: ?string,
    +patientDob?: ?Date,
    +patientAge?: ?string,
    +patientSex?: ?string,
    +date?: ?Date,
    +groupId?: ?string,
    +modality?: ?string,
    +hasDraftReport: boolean,
    +report?: ?{
      +__typename?: 'Report',
      +smid: string,
      +created: Date,
      +updated: Date,
      +sectionsLastUpdated?: ?Date,
      +submittedAt?: ?Date,
      +sections: Json,
      +content?: ?Json,
      +template?: ?{
        +__typename?: 'ReportTemplate',
        +id: string,
        +name: string,
        +triggerPhrase: string,
        +created: Date,
        +content: Json,
        +sections: $ReadOnlyArray<Json>,
        +picklists: $ReadOnlyArray<{
          +__typename?: 'ReportPicklist',
          +id: string,
          +options: $ReadOnlyArray<{
            +__typename?: 'ReportPicklistOption',
            +id: string,
            +name?: ?string,
            +text: $ReadOnlyArray<Json>,
            +default: boolean,
          }>,
        }>,
        +macros: $ReadOnlyArray<{
          +__typename?: 'Macro',
          +smid: string,
          +name: string,
          +triggerPhrase: string,
          +text: $ReadOnlyArray<Json>,
          +created: Date,
          +updated?: ?Date,
          +procedures?: ?$ReadOnlyArray<{
            +__typename?: 'Procedure',
            +smid: string,
            +description: string,
            +code: string,
          }>,
          +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
          +picklists: $ReadOnlyArray<{
            +__typename?: 'ReportPicklist',
            +id: string,
            +options: $ReadOnlyArray<{
              +__typename?: 'ReportPicklistOption',
              +id: string,
              +name?: ?string,
              +text: $ReadOnlyArray<Json>,
              +default: boolean,
            }>,
          }>,
        }>,
        +procedureReferences?: ?$ReadOnlyArray<{
          +__typename?: 'ProcedureReference',
          +smid: string,
          +description?: ?string,
          +code?: ?string,
          +autoload: boolean,
        }>,
      },
      +macros: $ReadOnlyArray<{
        +__typename?: 'Macro',
        +smid: string,
        +name: string,
        +triggerPhrase: string,
        +text: $ReadOnlyArray<Json>,
        +created: Date,
        +updated?: ?Date,
        +procedures?: ?$ReadOnlyArray<{
          +__typename?: 'Procedure',
          +smid: string,
          +description: string,
          +code: string,
        }>,
        +owner?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
        +picklists: $ReadOnlyArray<{
          +__typename?: 'ReportPicklist',
          +id: string,
          +options: $ReadOnlyArray<{
            +__typename?: 'ReportPicklistOption',
            +id: string,
            +name?: ?string,
            +text: $ReadOnlyArray<Json>,
            +default: boolean,
          }>,
        }>,
      }>,
      +addendums: $ReadOnlyArray<{
        +__typename?: 'Addendum',
        +created: Date,
        +updated: Date,
        +submittedAt?: ?Date,
        +sentAt?: ?Date,
        +smid: string,
        +content:
          | { +__typename?: 'AddendumSironaSlate', +sironaSlate: $ReadOnlyArray<Json> }
          | { +__typename?: 'AddendumTextBlob', +textBlob: string },
        +owner?: ?{ +__typename?: 'Owner', +id: string, +lastName: string },
      }>,
      +picklists?: ?$ReadOnlyArray<{
        +__typename?: 'ReportPicklist',
        +id: string,
        +options: $ReadOnlyArray<{
          +__typename?: 'ReportPicklistOption',
          +id: string,
          +name?: ?string,
          +text: $ReadOnlyArray<Json>,
        }>,
      }>,
      +owner?: ?{ +__typename?: 'Owner', +firstName: string, +lastName: string },
    },
    +procedure?: ?{ +__typename?: 'Procedure', +smid: string, +description: string, +code: string },
    +customMergeFields: $ReadOnlyArray<{
      +__typename?: 'CustomMergeField',
      +key: string,
      +value: string,
    }>,
    +claimedBy?: ?{ +__typename?: 'User', +id: string, +firstName: string, +lastName: string },
    +studies: $ReadOnlyArray<{
      +__typename?: 'Study',
      +smid: string,
      +bodyPart?: ?string,
      +modality: string,
      +description?: ?string,
      +laterality?: ?string,
      +studyDate?: ?Date,
    }>,
    +customStatus?: ?{ +__typename?: 'WorklistCustomStatus', +smid: string, +name: string },
  },
};

export type ToggleScreenShareMutationVariables = {
  screenShareEnabled: $ElementType<Scalars, 'Boolean'>,
};

export type ToggleScreenShareMutation = {
  +__typename?: 'Mutation',
  +toggleScreenShare: { +__typename?: 'ScreenShareSettingResponse', +screenShareEnabled: boolean },
};

export type GetStructuredReportQueryVariables = {
  seriesSmid: $ElementType<Scalars, 'ID'>,
  frameSmid: $ElementType<Scalars, 'ID'>,
};

export type GetStructuredReportQuery = {
  +__typename?: 'Query',
  +structuredReport: {
    +__typename?: 'StructuredReport',
    +id: string,
    +modalities: string,
    +content: Json,
    +patient: {
      +__typename?: 'SRPatient',
      +name?: ?string,
      +age?: ?string,
      +id?: ?string,
      +sex?: ?string,
    },
    +study: {
      +__typename?: 'SRStudy',
      +date?: ?Date,
      +description?: ?string,
      +id?: ?string,
      +time?: ?string,
      +uid?: ?string,
    },
    +series: {
      +__typename?: 'SRSeries',
      +description?: ?string,
      +number?: ?number,
      +uid?: ?string,
    },
    +instance: { +__typename?: 'SRInstance', +number?: ?number, +uid?: ?string },
    +general: {
      +__typename?: 'SRGeneralInfo',
      +completion_flag?: ?CompletionFlag,
      +completion_flag_description?: ?string,
      +date?: ?Date,
      +instance_number?: ?number,
      +preliminary_flag?: ?PreliminaryFlag,
      +time?: ?string,
      +verification_status?: ?VerificationStatus,
    },
    +observation: { +__typename?: 'SRObservation', +uid?: ?string, +datetime?: ?string },
    +sop_class: { +__typename?: 'SRSOPClass', +uid?: ?string },
  },
};

export type GetRhinoPromptByIdQueryVariables = {
  id: $ElementType<Scalars, 'ID'>,
};

export type GetRhinoPromptByIdQuery = {
  +__typename?: 'Query',
  +rhinoPrompt?: ?{
    +__typename?: 'RhinoPrompt',
    +id: string,
    +prompt: string,
    +label: string,
    +featureName: string,
    +modelIdentifier: string,
    +role: string,
  },
};

export type GetRhinoPromptsQueryVariables = {
  role?: ?$ElementType<Scalars, 'String'>,
  featureName?: ?$ElementType<Scalars, 'String'>,
  modelIdentifier?: ?$ElementType<Scalars, 'String'>,
};

export type GetRhinoPromptsQuery = {
  +__typename?: 'Query',
  +rhinoPrompts: $ReadOnlyArray<{
    +__typename?: 'RhinoPrompt',
    +id: string,
    +prompt: string,
    +label: string,
    +featureName: string,
    +modelIdentifier: string,
    +role: string,
  }>,
};

export type GetRhinoModelsQueryVariables = {
  modelIdentifier?: ?$ElementType<Scalars, 'String'>,
  vendor?: ?$ElementType<Scalars, 'String'>,
};

export type GetRhinoModelsQuery = {
  +__typename?: 'Query',
  +rhinoModels: $ReadOnlyArray<{
    +__typename?: 'RhinoModel',
    +id: string,
    +vendor: string,
    +label: string,
    +config: { +__typename?: 'RhinoModelConfig', +modelName: string, +temperature: number },
  }>,
};

export type SendRhinoQueryMutationVariables = {
  input: RhinoQueryInput,
};

export type SendRhinoQueryMutation = {
  +__typename?: 'Mutation',
  +rhinoQuery?: ?{
    +__typename?: 'RhinoQueryResponse',
    +id: string,
    +modelIdentifier: string,
    +queryMessages: $ReadOnlyArray<{
      +__typename?: 'RhinoQueryMessage',
      +role: string,
      +content: string,
    }>,
    +response: {
      +__typename?: 'SironaCustomLLMResponseModel',
      +message: { +__typename?: 'SironaCustomLLMResponseMessage', +role: string, +content: string },
      +usage: {
        +__typename?: 'SironaCustomLLMResponseUsage',
        +promptTokens: number,
        +completionTokens: number,
        +totalTokens: number,
      },
    },
    +rawApiResponse: {
      +__typename?: 'RhinoRawAPIResponse',
      +id: string,
      +object: string,
      +created: number,
      +model: string,
      +choices: $ReadOnlyArray<{
        +__typename?: 'RhinoRawAPIResponseChoice',
        +index: number,
        +finishReason: string,
        +message: {
          +__typename?: 'SironaCustomLLMResponseMessage',
          +role: string,
          +content: string,
        },
      }>,
      +usage: {
        +__typename?: 'RhinoRawAPIResponseUsage',
        +promptTokens: number,
        +completionTokens: number,
        +totalTokens: number,
      },
    },
  },
};

export type SendRhinoFeedbackMutationVariables = {
  input: RhinoFeedbackInput,
};

export type SendRhinoFeedbackMutation = {
  +__typename?: 'Mutation',
  +rhinoFeedback?: ?{ +__typename?: 'RhinoFeedbackResponse', +success: boolean },
};

export type SendRhinoMedCheckQueryMutationVariables = {
  input: RhinoMedCheckInput,
};

export type SendRhinoMedCheckQueryMutation = {
  +__typename?: 'Mutation',
  +rhinoMedCheck?: ?{ +__typename?: 'RhinoMedCheckResponse', +content?: ?string },
};

export type GetAuthenticatorTokenQueryVariables = {
  userId?: ?$ElementType<Scalars, 'String'>,
  password: $ElementType<Scalars, 'String'>,
};

export type GetAuthenticatorTokenQuery = { +__typename?: 'Query', +authenticatorToken: string };

export type LinkAuthenticatorMutationVariables = {
  totp: $ElementType<Scalars, 'String'>,
  userId?: ?$ElementType<Scalars, 'String'>,
  password: $ElementType<Scalars, 'String'>,
};

export type LinkAuthenticatorMutation = {
  +__typename?: 'Mutation',
  +linkAuthenticator: { +__typename?: 'LinkAuthenticatorResponse', +linked: boolean },
};

export type ToggleMultiFactorAuthMutationVariables = {
  multiFactorAuthEnabled: $ElementType<Scalars, 'Boolean'>,
  password: $ElementType<Scalars, 'String'>,
  totp?: ?$ElementType<Scalars, 'String'>,
};

export type ToggleMultiFactorAuthMutation = {
  +__typename?: 'Mutation',
  +toggleMultiFactorAuth: {
    +__typename?: 'MultiFactorAuthResponse',
    +multiFactorAuthEnabled: boolean,
    +authenticatorToken?: ?string,
  },
};

export type ToggleMultiFactorLinkMutationVariables = {
  multiFactorAuthLinked: $ElementType<Scalars, 'Boolean'>,
};

export type ToggleMultiFactorLinkMutation = {
  +__typename?: 'Mutation',
  +toggleMultiFactorLink: {
    +__typename?: 'MultiFactorLinkResponse',
    +multiFactorAuthLinked: boolean,
  },
};

export type GenerateImpressionMutationVariables = {
  input: GenerateImpressionInput,
};

export type GenerateImpressionMutation = {
  +__typename?: 'Mutation',
  +generateImpression?: ?{ +__typename?: 'GenerateImpressionResponse', +impression: Json },
};
